.production-admin-dashboard {
  padding: 1.5rem;
}

.production-admin-dashboard h1 {
  color: #2d3748;
  margin-bottom: 2rem;
}

.production-admin-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
  margin-top: 2rem;
}

.stats-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.stat-card {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.stat-card h3 {
  color: #4a5568;
  font-size: 1rem;
  margin-bottom: 0.75rem;
}

.stat-card p {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3748;
}

.dashboard-section {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.dashboard-section h2 {
  color: #4a5568;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.management-content {
  padding: 1rem 0;
}
