.departments-container {
  padding: 20px;
  height: 100%;
  overflow-y: auto;
}

.departments-content {
  max-width: 1400px;
  margin: 0 auto;
}

.departments-header {
  background: white;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.departments-header h1 {
  margin: 0;
  color: #2c3e50;
  font-size: 1.75em;
  font-weight: 600;
}

.departments-subtitle {
  margin: 8px 0 0 0;
  color: #6c757d;
  font-size: 0.95em;
}

.departments-list {
  background: white;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.departments-controls {
  margin-bottom: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.search-bar {
  flex: 1;
  min-width: 300px;
}

.search-input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  background-color: white;
}

.add-department-form {
  background-color: white;
  padding: 1.5rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.form-input {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.375rem;
  width: 100%;
}

.add-button {
  padding: 0.75rem 1.5rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-button:hover {
  background-color: #45a049;
}

.department-item {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 16px;
  transition: all 0.3s ease;
  cursor: pointer;
  min-width: 0; /* Prevents overflow in grid */
}

.department-item.expanded {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}

.department-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.department-details {
  opacity: 1;
  max-height: 1000px;
  transition: all 0.3s ease;
}

.department-item:not(.expanded) .department-details {
  max-height: 50px;
  overflow: hidden;
}

.department-item.expanded .department-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.department-header h3 {
  font-size: 1.125rem;
  font-weight: 600;
  color: #1a202c;
  margin: 0;
}

.edit-button {
  padding: 0.5rem 1rem;
  background-color: #3182ce;
  color: white;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 0.875rem;
  transition: background-color 0.2s;
}

.edit-button:hover {
  background-color: #2c5282;
}

.edit-button.expanded {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.85em;
  margin-bottom: 16px;
  transition: background-color 0.2s;
  display: inline-block;
  line-height: 1.2;
  white-space: nowrap;
  width: 200px;
  text-align: center;
}

.edit-button.expanded:hover {
  background-color: #2980b9;
}

.department-details {
  color: #4a5568;
  font-size: 0.875rem;
}

.department-details p {
  margin: 0.5rem 0;
}

.department-details strong {
  color: #718096;
  font-weight: 500;
}

.department-item.expanded .department-leaders {
  width: 100%;
  margin-top: 20px;
}

.department-item.expanded .leaders-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 15px;
}

.department-item.expanded .leader-card {
  flex: 1;
  min-width: 200px;
  max-width: calc(16.666% - 14px); /* 6 cards across with gap consideration */
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.department-item.expanded .leader-card h5 {
  margin: 0 0 8px 0;
  color: #2c3e50;
  font-size: 1em;
  font-weight: 600;
}

.department-item.expanded .leader-position {
  color: #666;
  font-size: 0.9em;
  margin: 5px 0;
}

.department-item.expanded .leader-contact-info {
  margin-top: 8px;
}

.department-item.expanded .leader-contact-info p {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 4px 0;
  font-size: 0.85em;
  color: #666;
}

/* Responsive adjustments for leader cards */
@media (max-width: 1400px) {
  .department-item.expanded .leader-card {
    max-width: calc(25% - 12px); /* 4 cards across */
  }
}

@media (max-width: 1024px) {
  .department-item.expanded .leader-card {
    max-width: calc(33.333% - 11px); /* 3 cards across */
  }
}

@media (max-width: 768px) {
  .department-item.expanded .leader-card {
    max-width: calc(50% - 8px); /* 2 cards across */
  }
}

@media (max-width: 480px) {
  .department-item.expanded .leader-card {
    max-width: 100%; /* 1 card across */
  }
}

/* Status badge styles */
.status-badge {
  font-size: 0.75rem;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  background-color: #EDF2F7;
  color: #4A5568;
}

.status-badge.active {
  background-color: #C6F6D5;
  color: #2F855A;
}

.status-badge.pending {
  background-color: #FEEBC8;
  color: #C05621;
}

.status-badge.completed {
  background-color: #BEE3F8;
  color: #2B6CB0;
}

/* Loading spinner */
.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.spinner {
  width: 2.5rem;
  height: 2.5rem;
  border: 0.25rem solid #e2e8f0;
  border-top-color: #4299e1;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Modal styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.modal-content {
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  width: 100%;
  max-width: 32rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.cancel-button {
  padding: 0.5rem 1rem;
  background-color: #e2e8f0;
  color: #4a5568;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 0.875rem;
}

.save-button {
  padding: 0.5rem 1rem;
  background-color: #48bb78;
  color: white;
  border: none;
  border-radius: 0.375rem;
  cursor: pointer;
  font-size: 0.875rem;
}

.save-button:hover {
  background-color: #38a169;
}

.cancel-button:hover {
  background-color: #cbd5e0;
}

.success-message {
  background-color: #C6F6D5;
  color: #2F855A;
  padding: 1rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}

.error-message {
  background-color: #FED7D7;
  color: #C53030;
  padding: 1rem;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
}

.run-request-button {
  margin-top: 15px;
  padding: 8px 16px;
  background-color: #27ae60;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.run-request-button:hover {
  background-color: #219a52;
}

.run-request-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  z-index: 1000;
  width: 90%;
  max-width: 500px;
}

.run-request-popup h3 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #2c3e50;
}

.run-request-popup form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.run-request-popup input,
.run-request-popup select,
.run-request-popup textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.run-request-popup textarea {
  min-height: 100px;
  resize: vertical;
}

.run-request-popup .button-group {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
  margin-top: 10px;
}

.run-request-popup button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.run-request-popup button[type="submit"] {
  background-color: #27ae60;
  color: white;
}

.run-request-popup button[type="button"] {
  background-color: #e74c3c;
  color: white;
}

.run-request-popup button:hover {
  opacity: 0.9;
}

.run-request-form textarea[name="description"] {
  min-height: 200px; 
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.5;
  resize: vertical;
  margin: 10px 0;
}

.run-request-form textarea[name="description"]:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.run-request-form {
  padding: 20px;
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
}

.form-column {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

@media screen and (max-width: 768px) {
  .run-request-form {
    padding: 15px;
  }

  .form-grid {
    grid-template-columns: 1fr;  
  }

  .form-column {
    gap: 10px;
  }

  .address-grid {
    grid-template-columns: 1fr !important;  
  }

  .run-request-form input,
  .run-request-form select,
  .run-request-form textarea {
    width: 100%;
    margin-bottom: 10px;
    font-size: 16px;  
    padding: 12px;    
  }

  .button-group {
    flex-direction: column;
    gap: 10px;
  }

  .button-group button {
    width: 100%;
    padding: 12px;
    font-size: 16px;
  }

  .address-section {
    margin: 20px 0;
    padding: 15px;
  }

  .run-request-header {
    margin-bottom: 15px;
  }

  .photo-upload-section {
    margin: 15px 0;
  }

  .selected-files {
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  }
}

.run-request-form h3 {
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 25px;
}

.run-request-form input,
.run-request-form select,
.run-request-form textarea {
  width: 100%;
  padding: 12px 16px;
  border: 2px solid #e0e0e0;
  border-radius: 6px;
  font-size: 16px;
  line-height: 1.5;
  transition: all 0.3s ease;
  color: #2c3e50;
  background-color: #f8f9fa;
}

.run-request-form input:focus,
.run-request-form select:focus,
.run-request-form textarea:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
  background-color: #fff;
}

.run-request-form input::placeholder,
.run-request-form textarea::placeholder {
  color: #95a5a6;
}

.run-request-form input,
.run-request-form select {
  height: 48px;
}

.run-request-form select {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%232c3e50' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}

.run-request-form textarea[name="description"] {
  min-height: 200px;
  padding: 16px;
  margin: 15px 0;
  font-family: inherit;
  resize: vertical;
}

.run-request-form .button-group {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 25px;
}

.run-request-form .button-group button {
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.run-request-form .button-group button[type="submit"] {
  background-color: #27ae60;
  color: white;
}

.run-request-form .button-group button[type="submit"]:hover {
  background-color: #219a52;
}

.run-request-form .button-group button[type="button"] {
  background-color: #e74c3c;
  color: white;
}

.run-request-form .button-group button[type="button"]:hover {
  background-color: #c0392b;
}

.photo-upload-section {
  margin: 20px 0;
}

.file-upload-label {
  display: inline-block;
  padding: 12px 24px;
  background-color: #3498db;
  color: white;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 16px;
  font-weight: 500;
}

.file-upload-label:hover {
  background-color: #2980b9;
}

.form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  margin-bottom: 25px;
}

.form-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.selected-files {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 15px;
  margin-top: 20px;
}

.file-preview {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.file-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.remove-file {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(231, 76, 60, 0.9);
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  padding: 0;
  line-height: 1;
  transition: all 0.3s ease;
}

.remove-file:hover {
  background-color: #c0392b;
  transform: scale(1.1);
}

.address-section {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 6px;
  margin: 20px 0;
}

.address-section h4 {
  color: #2c3e50;
  margin: 0 0 15px 0;
  font-size: 18px;
}

.address-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
  margin-bottom: 15px;
}

.address-grid input[name="address.streetNumber"] {
  width: 100%;
}

.address-grid input[name="address.streetName"] {
  width: 100%;
}

.address-grid input[name="address.suite"] {
  width: 100%;
}

.address-grid input[name="address.city"] {
  width: 100%;
}

.address-grid input[name="address.state"] {
  width: 100%;
}

.address-grid input[name="address.zip"] {
  width: 100%;
}

.address-grid input[name="address.suite"]::placeholder {
  color: #95a5a6;
  font-style: italic;
}

.run-request-form select[name="department"] {
  width: 100%;
  padding: 12px 16px;
  border: 2px solid #e0e0e0;
  border-radius: 6px;
  font-size: 16px;
  line-height: 1.5;
  height: 48px;
  background-color: #f8f9fa;
  color: #2c3e50;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%232c3e50' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 12px center;
  background-size: 16px;
  padding-right: 40px;
}

.run-request-form select[name="department"]:focus {
  border-color: #3498db;
  outline: none;
  box-shadow: 0 0 0 3px rgba(52, 152, 219, 0.2);
  background-color: #fff;
}

.run-request-form select[name="department"] option {
  color: #2c3e50;
  padding: 8px;
}

.department-leaders-section {
  background-color: white;
  margin: 15px 0;
  padding: 15px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.add-leader-form {
  background-color: white;
  margin-bottom: 15px;
}

.add-leader-form input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.add-leader-btn {
  grid-column: 1 / -1;
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-leader-btn:hover {
  background-color: #2980b9;
}

.leader-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  margin-bottom: 10px;
}

.leader-info {
  flex: 1;
}

.leader-main {
  font-size: 16px;
  margin-bottom: 5px;
}

.leader-contact {
  display: flex;
  gap: 15px;
  font-size: 14px;
  color: #666;
}

.leader-actions {
  display: flex;
  gap: 10px;
}

.edit-leader-btn {
  padding: 6px 12px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.remove-leader-btn {
  padding: 6px 12px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.leader-card {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  font-size: 0.9em;
}

.leader-card h5 {
  margin: 0 0 3px 0;
  color: #2c3e50;
  font-size: 14px;
  font-weight: 600;
}

.leader-position {
  color: #7f8c8d;
  margin-bottom: 5px;
  font-size: 12px;
}

.leader-contact-info {
  display: flex;
  flex-direction: column;
  gap: 3px;
  font-size: 12px;
  color: #666;
}

.leader-phone,
.leader-email {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0;
}

.leader-phone i,
.leader-email i {
  color: #3498db;
  font-size: 12px;
}

.leaders-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 10px;
  margin-top: 10px;
}

.department-leaders {
  margin: 10px 0;
  padding-bottom: 10px;
}

.department-leaders h4 {
  color: #2c3e50;
  margin: 0 0 8px 0;
  font-size: 16px;
}

.run-request-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.close-run-request-btn {
  background: none;
  border: none;
  font-size: 24px;
  color: #666;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.close-run-request-btn:hover {
  background-color: #f0f0f0;
  color: #333;
}

.department-header-expanded {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.close-department-btn {
  background: none;
  border: none;
  font-size: 24px;
  color: #666;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.close-department-btn:hover {
  background-color: #f0f0f0;
  color: #333;
}

.leader-form-buttons {
  grid-column: 1 / -1;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.leader-form-buttons button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.leader-form-buttons .add-leader-btn {
  background-color: #3498db;
  color: white;
}

.leader-form-buttons .add-leader-btn:hover {
  background-color: #2980b9;
}

.leader-form-buttons button[type="submit"] {
  background-color: #3498db;
  color: white;
}

.leader-form-buttons button[type="button"]:not(.add-leader-btn) {
  background-color: #e74c3c;
  color: white;
}

.form-section {
  margin: 15px 0;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 6px;
  max-width: 100%;  
  box-sizing: border-box;  
}

.form-section h4 {
  margin: 0 0 10px 0;
  color: #2c3e50;
  font-size: 14px;
}

.form-section textarea {
  width: 100%;
  min-height: 80px;  
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
  box-sizing: border-box;  
}

.services-list {
  margin-bottom: 10px;
  max-height: 200px;  
  overflow-y: auto;  
}

.service-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 8px;  
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin-bottom: 6px;  
  font-size: 13px;  
}

.add-service {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.add-service input {
  flex: 1;
  padding: 6px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 13px;
}

.add-service-btn {
  padding: 6px 12px;
  font-size: 13px;
}

.department-name-readonly {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: #f8f9fa;
  color: #2c3e50;
  cursor: not-allowed;
}

.leaders-list {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 5px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
}

.modal-content h2 {
  margin-bottom: 20px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top-color: #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.department-leaders {
  margin-top: 20px;
  width: 100%;
}

.leaders-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin-top: 15px;
}

.leader-card {
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.leader-card h5 {
  margin: 0 0 10px 0;
  color: #2c3e50;
  font-size: 1.1em;
}

.leader-position {
  color: #666;
  font-size: 0.9em;
  margin: 5px 0;
}

.leader-contact-info {
  margin-top: 10px;
}

.leader-contact-info p {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 5px 0;
  font-size: 0.9em;
  color: #666;
}

.leader-contact-info i {
  color: #3498db;
}

.departments-header {
  padding: 30px;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  text-align: center;
}

.departments-header h1 {
  margin: 0;
  color: #2c3e50;
  font-size: 2.5em;
  font-weight: 600;
}

.departments-subtitle {
  margin: 10px 0 0 0;
  color: #6c757d;
  font-size: 1.1em;
}

.departments-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;
  padding: 20px;
}

@media (max-width: 1400px) {
  .departments-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 1024px) {
  .departments-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .departments-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .departments-grid {
    grid-template-columns: 1fr;
  }
}

.members-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.member-tag {
  background-color: #e9ecef;
  color: #495057;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.85em;
}

.department-info {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 24px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.info-section {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.info-section h4 {
  color: #2c3e50;
  font-size: 1.1em;
  font-weight: 600;
  margin: 0 0 16px 0;
}

.info-section p {
  color: #666;
  line-height: 1.6;
  margin: 0;
}

.services-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.services-list li {
  color: #666;
  padding: 8px 0;
  display: flex;
  align-items: center;
  line-height: 1.4;
}

.services-list li:before {
  content: "•";
  color: #3498db;
  font-weight: bold;
  margin-right: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .department-info {
    grid-template-columns: 1fr;
  }
}

.department-actions {
  margin-top: 20px;
  padding: 20px;
  display: flex;
  justify-content: center;
}

.submit-run-button {
  background-color: #27ae60;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9em;
  transition: background-color 0.2s;
  width: 200px;
  text-align: center;
}

.submit-run-button:hover {
  background-color: #219a52;
}