.edit-profile-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-profile-popup-content {
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  width: 90%;
  max-width: 700px;
  max-height: 90vh;
  overflow-y: auto;
}

.edit-profile-popup h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

.edit-profile-popup .form-group {
  margin-bottom: 20px;
}

.edit-profile-popup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.edit-profile-popup input,
.edit-profile-popup select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.profile-photo-info-group {
  display: flex;
  gap: 30px;
  margin-bottom: 25px;
}

.profile-photo-editor {
  flex-shrink: 0;
  width: 150px;
}

.personal-info-group {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.name-birth-ssn-group,
.contact-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.profile-photo-preview {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid #3498db;
  transition: border-color 0.3s ease;
}

.profile-photo-preview:hover {
  border-color: #2980b9;
}

.profile-photo-editor p {
  margin-top: 10px;
  font-size: 0.9em;
  color: #7f8c8d;
  text-align: center;
}

.address-group {
  display: flex;
  gap: 20px;
}

.address-group > div:first-child {
  flex-grow: 1;
}

.address-group > div:last-child {
  width: 30%;
}

.emergency-contacts {
  margin-top: 30px;
}

.emergency-contact {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.emergency-contact button {
  grid-column: span 2;
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
}

.emergency-contacts > button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 15px;
}

.vehicle-info {
  margin-top: 30px;
  border-top: 1px solid #e9ecef;
  padding-top: 20px;
}

.vehicle-info h3 {
  margin-bottom: 20px;
  color: #2c3e50;
}

.vehicle-fields-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e9ecef;
}

.add-vehicle-button,
.remove-vehicle-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.add-vehicle-button {
  background-color: #3498db;
  color: white;
  margin-top: 10px;
}

.add-vehicle-button:hover {
  background-color: #2980b9;
}

.remove-vehicle-button {
  background-color: #e74c3c;
  color: white;
  grid-column: span 2;
}

.remove-vehicle-button:hover {
  background-color: #c0392b;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 30px;
}

.save-button,
.cancel-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.save-button {
  background-color: #2ecc71;
  color: white;
}

.cancel-button {
  background-color: #e74c3c;
  color: white;
}

.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
}

.drivers-license-group {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.drivers-license-group .form-group {
  flex: 1;
  min-width: 150px;
  max-width: 200px;
  width: 200px;
}

.drivers-license-group label {
  margin-bottom: 0;
  white-space: nowrap;
}

.form-group {
  width: 200px;
}