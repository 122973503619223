.hamburger-menu-dropdown {
  position: fixed;
  top: 60px;
  right: 0;
  max-height: calc(100vh - 60px);
  overflow-y: auto;
  background-color: white;
  z-index: 1000;
  width: 250px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.hamburger-menu-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.hamburger-menu-list li {
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
}

.hamburger-menu-list li:last-child {
  border-bottom: none;
}

.hamburger-menu-list a {
  color: #333;
  text-decoration: none;
  display: block;
}

.hamburger-menu-list a:hover {
  background-color: #f5f5f5;
}

.notification-count {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.8em;
  margin-left: 5px;
}