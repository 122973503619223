.production-page {
  padding: 1rem;
  width: 100%;
}

.production-page h1 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #2d3748;
  border-bottom: 2px solid #e2e8f0;
  padding-bottom: 0.5rem;
}

.production-page h2 {
  font-size: 1.5rem;
  margin: 1.5rem 0 1rem;
  color: #2d3748;
}

.page-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.filters-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f8fafc;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
}

.filter-group {
  display: flex;
  flex-direction: column;
  min-width: 150px;
}

.filter-group label {
  font-size: 0.85rem;
  margin-bottom: 0.3rem;
  font-weight: 500;
  color: #4a5568;
}

.form-control {
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: 1px solid #e2e8f0;
  font-size: 0.9rem;
  background-color: white;
  transition: all 0.2s;
}

.form-control:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.2);
}

.toast {
  position: fixed;
  top: 1rem;
  right: 1rem;
  padding: 0.75rem 1.25rem;
  border-radius: 0.25rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
}

.toast-success {
  background-color: #48bb78;
}

.toast-error {
  background-color: #e53e3e;
}

.toast button {
  background: none;
  border: none;
  color: white;
  font-size: 1.25rem;
  cursor: pointer;
}

.add-vehicle-section {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.vehicle-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.form-group {
  flex: 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
}

.form-group.full-width {
  flex-basis: 100%;
}

.form-group label {
  font-size: 0.85rem;
  margin-bottom: 0.3rem;
  font-weight: 500;
  color: #4a5568;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.btn-primary {
  background-color: #4299e1;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-primary:hover {
  background-color: #3182ce;
}

.btn-danger {
  background-color: #e53e3e;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.btn-danger:hover {
  background-color: #c53030;
}

.btn-small {
  padding: 0.25rem 0.5rem;
  font-size: 0.8rem;
}

.vehicles-list-section {
  background-color: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.vehicles-table-container {
  overflow-x: auto;
  margin-top: 1rem;
}

.vehicles-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9rem;
}

.vehicles-table th {
  text-align: left;
  padding: 0.75rem;
  border-bottom: 2px solid #e2e8f0;
  color: #4a5568;
  font-weight: 600;
}

.vehicles-table td {
  padding: 0.75rem;
  border-bottom: 1px solid #e2e8f0;
  vertical-align: top;
}

.vehicles-table tr:last-child td {
  border-bottom: none;
}

.vehicle-info {
  display: flex;
  flex-direction: column;
}

.vehicle-name {
  font-weight: 500;
  color: #2d3748;
}

.vehicle-details {
  color: #718096;
  font-size: 0.85rem;
  margin-top: 0.25rem;
}

.rental-purpose {
  color: #718096;
  font-size: 0.85rem;
  margin-top: 0.25rem;
}

.rental-total {
  font-weight: 500;
  color: #2d3748;
  margin-top: 0.25rem;
}

.no-data {
  text-align: center;
  padding: 2rem;
  color: #718096;
}

.no-data p {
  margin-bottom: 0.5rem;
}

.no-data ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  text-align: left;
  margin: 0.5rem 0;
  display: inline-block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media screen and (max-width: 768px) {
  .form-group {
    min-width: 100%;
  }
}
