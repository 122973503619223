.add-vehicle-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.add-vehicle-popup-content {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-vehicle-popup-content h2 {
  font-size: 18px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
  color: #2c3e50;
}

.add-vehicle-popup-content form {
  display: grid;
  gap: 15px;
}

.add-vehicle-popup-content input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-vehicle-popup-content input[type="file"] {
  border: none;
  padding: 0;
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.add-vehicle-popup-content button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
}

.add-vehicle-popup-content button[type="submit"] {
  background-color: #3498db;
  color: white;
}

.add-vehicle-popup-content button[type="submit"]:hover {
  background-color: #2980b9;
}

.add-vehicle-popup-content button[type="button"] {
  background-color: #e74c3c;
  color: white;
}

.add-vehicle-popup-content button[type="button"]:hover {
  background-color: #c0392b;
}

.file-input-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.file-input-container label {
  font-weight: bold;
}

.selected-files {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
  font-size: 0.9em;
}

.selected-files p {
  font-weight: bold;
  margin-bottom: 5px;
}

.selected-files ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.selected-files li {
  margin-bottom: 3px;
}

.license-plate-group {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
}

.state-select {
  width: 80px; /* Adjust width as needed */
}

.state-select select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}

.license-input {
  flex: 1;
}

.license-input input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.state-select select:focus,
.license-input input:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}