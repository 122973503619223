.navbar {
  display: none;
  background-color: #333;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  height: 60px; /* Set a fixed height for the navbar */
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 100%; /* Make the container fill the navbar height */
}

.navbar-left, .navbar-right {
  display: flex;
  align-items: center;
  height: 100%; /* Ensure full height */
}

.navbar-logo img {
  height: 40px;
  margin-right: 10px;
}

.navbar-title {
  font-size: 1.2rem;
  margin: 0;
  color: white;
  white-space: nowrap;
}

.hamburger-menu {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: 10px;
  height: 100%; /* Make the button fill the navbar height */
  display: flex;
  align-items: center;
}

.navbar-links {
  display: flex;
  height: 100%; /* Ensure full height */
}

.navbar-links a {
  color: white;
  text-decoration: none;
  margin-left: 20px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 100%; /* Make links fill the navbar height */
}

@media screen and (max-width: 768px) {
  .navbar {
    display: block;
  }
}

@media screen and (max-width: 600px) {
  .navbar-logo img {
    height: 30px;
  }

  .navbar-title {
    font-size: 1rem;
  }
}

@media screen and (max-width: 480px) {
  .navbar-logo img {
    height: 25px;
  }

  .navbar-title {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 360px) {
  .navbar-logo img {
    height: 22px;
  }

  .navbar-title {
    font-size: 0.8rem;
  }
}