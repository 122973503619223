.management-container {
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.management-section {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin-bottom: 2rem;
}

.management-section h2 {
    color: #2c3e50;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    border-bottom: 2px solid #eee;
    padding-bottom: 0.5rem;
}

.management-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 2rem;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #34495e;
    font-weight: 500;
}

.form-control {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    transition: border-color 0.2s;
}

.form-control:focus {
    border-color: #3498db;
    outline: none;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.btn {
    padding: 0.75rem 1.5rem;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s;
}

.btn-primary {
    background-color: #3498db;
    color: white;
}

.btn-primary:hover {
    background-color: #2980b9;
}

.btn-primary:disabled {
    background-color: #bdc3c7;
    cursor: not-allowed;
}

.alert {
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 1rem;
}

.alert-success {
    background-color: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}

.alert-danger {
    background-color: #f8d7da;
    color: #721c24;
    border: 1px solid #f5c6cb;
}

.alert-warning {
    background-color: #fff3cd;
    color: #856404;
    border: 1px solid #ffeeba;
}

.user-list {
    margin-top: 2rem;
}

.user-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    border: 1px solid #ddd;
    margin-bottom: 10px;
    border-radius: 4px;
}

.user-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.login-count {
    font-size: 0.9em;
    color: #666;
}

.last-login {
    font-size: 0.9em;
    color: #666;
}

.user-actions {
    display: flex;
    gap: 10px;
}

.btn-danger {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.btn-danger:disabled {
    background-color: #dc354580;
    cursor: not-allowed;
}

.production-form {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
}

.production-form .form-group:last-child {
    grid-column: 1 / -1;
}

textarea.form-control {
    min-height: 100px;
    resize: vertical;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .management-container {
        padding: 1rem;
    }
    
    .management-section {
        padding: 1rem;
    }
    
    .management-grid {
        grid-template-columns: 1fr;
    }
}

.user-name {
    font-weight: 600;
    font-size: 1.1em;
    color: #2c3e50;
}

.user-email {
    color: #666;
    font-size: 0.9em;
    margin-bottom: 5px;
}

.user-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.user-role {
    font-size: 0.9em;
    background: #e9ecef;
    padding: 2px 8px;
    border-radius: 12px;
    display: inline-block;
    color: #495057;
}
