.production-subscription-dashboard {
  padding: 2rem;
  max-width: 1000px;
  margin: 0 auto;
}

.subscription-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.subscription-header h2 {
  margin: 0;
  color: #2c3e50;
}

.status-badge {
  padding: 0.5rem 1rem;
  border-radius: 20px;
  color: white;
  font-weight: 500;
  font-size: 0.875rem;
}

.subscription-details {
  display: grid;
  gap: 1.5rem;
}

.detail-card {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.detail-card h3 {
  margin: 0 0 1.5rem 0;
  color: #2c3e50;
}

.detail-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1.5rem;
}

.detail-item {
  display: flex;
  flex-direction: column;
}

.detail-item label {
  font-size: 0.875rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.detail-item span {
  font-size: 1rem;
  color: #2c3e50;
  font-weight: 500;
}

.warning-card {
  background: #fff3cd;
  border: 1px solid #ffeeba;
  padding: 1.5rem;
  border-radius: 8px;
  margin-top: 1.5rem;
}

.warning-card h3 {
  color: #856404;
  margin: 0 0 1rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.warning-card p {
  color: #856404;
  margin: 0 0 1.5rem 0;
}

.subscribe-button {
  background-color: #28a745;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.subscribe-button:hover {
  background-color: #218838;
}

.loading {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.error {
  text-align: center;
  padding: 2rem;
  color: #dc3545;
}

.subscription-dashboard {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.subscription-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s ease;
}

.subscription-header:hover {
  background-color: #f8f9fa;
}

.subscription-header h2 {
  margin: 0;
  font-size: 1.2rem;
  color: #2c3e50;
}

.collapse-button {
  background: none;
  border: none;
  font-size: 1rem;
  color: #6c757d;
  cursor: pointer;
  padding: 0.5rem;
  transition: transform 0.2s ease;
}

.collapse-button:hover {
  color: #2c3e50;
}

.dashboard-content {
  padding: 1.5rem;
}

.subscription-status {
  padding: 1.5rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  color: white;
}

.subscription-status h3 {
  margin: 0 0 1rem 0;
  font-size: 1.2rem;
}

.subscription-status p {
  margin: 0.5rem 0;
  font-size: 1rem;
}

.subscribe-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: white;
  color: #3498db;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: opacity 0.2s ease;
}

.subscribe-button:hover {
  opacity: 0.9;
}

.features-section {
  margin-top: 2rem;
}

.features-section h3 {
  margin: 0 0 1rem 0;
  color: #2c3e50;
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
}

.feature-item {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 6px;
}

.feature-item h4 {
  margin: 0 0 0.5rem 0;
  color: #2c3e50;
}

.feature-item p {
  margin: 0;
  color: #6c757d;
}

.payment-section {
  margin-top: 2rem;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 8px;
}

.payment-section h3 {
  margin: 0 0 1rem 0;
  color: #2c3e50;
}

.payment-details {
  margin-bottom: 1rem;
}

.payment-details p {
  margin: 0.5rem 0;
  color: #6c757d;
}

.manage-subscription-button {
  padding: 0.5rem 1rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s ease;
}

.manage-subscription-button:hover {
  background-color: #2980b9;
}

.loading, .error, .no-data {
  padding: 2rem;
  text-align: center;
}

.error {
  color: #dc3545;
}
