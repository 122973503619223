/* Improved base styling with better support for all devices */
.times-container {
  display: flex;
}

.times-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-right: 0;
  margin-left: 0;
}

.add-time-entry, .weekly-calendar {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Improve form layout with consistent responsive grid */
.add-time-entry form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 20px;
}

/* Make form controls more touch-friendly */
.add-time-entry input, 
.add-time-entry select, 
.add-time-entry button {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px; /* Minimum font size for mobile */
  min-height: 44px; /* Minimum touch target size */
}

.add-time-entry button {
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 600;
  min-height: 48px; /* Slightly larger touch target for primary actions */
}

.add-time-entry button:hover {
  background-color: #2980b9;
}

/* Better calendar navigation with improved spacing */
.calendar-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  flex-wrap: wrap;
  gap: 10px;
}

.calendar-navigation div {
  display: flex;
  gap: 10px;
}

.calendar-navigation button {
  padding: 10px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-height: 44px;
  min-width: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.calendar-navigation button:hover {
  background-color: #2980b9;
}

.calendar-navigation h2 {
  flex-grow: 1;
  text-align: center;
  margin: 0;
  font-size: 1.25rem;
}

/* Improve calendar table layout */
.calendar-table {
  border: 1px solid #e9ecef;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}

.calendar-header {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  background-color: #f8f9fa;
  font-weight: bold;
  padding: 10px;
  border-bottom: 2px solid #e9ecef;
}

.calendar-header div {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-right: 1px solid #e9ecef;
  padding: 8px 4px;
}

.calendar-header div:last-child {
  border-right: none;
}

.calendar-body {
  display: flex;
  flex-direction: column;
}

.calendar-row {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  padding: 10px;
  border-bottom: 1px solid #e9ecef;
}

.calendar-row:last-child {
  border-bottom: none;
}

.calendar-row:nth-child(even) {
  background-color: #f2f2f2;
}

.calendar-cell {
  padding: 8px 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #e9ecef;
  min-height: 50px;
}

.calendar-cell:last-child {
  border-right: none;
}

.calendar-day ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.calendar-day li {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 8px;
  margin-top: 8px;
  font-size: 0.9em;
}

.calendar-day li strong {
  color: #2c3e50;
}

.calendar-day li p {
  margin: 2px 0;
  color: #34495e;
}

.date-cell {
  font-size: 0.9em;
}

.date-cell h3 {
  margin: 0;
  font-size: 1em;
  color: #3498db;
}

.date-cell p {
  margin: 0;
  font-size: 0.8em;
  color: #7f8c8d;
}

.calendar-cell button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-height: 44px;
  min-width: 44px;
}

.calendar-cell button:hover {
  background-color: #2980b9;
}

/* Improve time type selector for better mobile layout */
.time-type-selector {
  grid-column: 1 / -1;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 10px 0;
  justify-content: flex-start;
}

.time-type-selector label {
  display: flex;
  align-items: center;
  min-height: 44px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #f8f9fa;
  margin-right: 8px;
}

.time-type-selector input[type="radio"] {
  margin-right: 8px;
  min-width: 20px;
  min-height: 20px;
}

.add-time-entry input[type="number"],
.add-time-entry input[type="date"],
.add-time-entry input[type="time"] {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  min-height: 44px;
}

.weekly-summary {
  margin-top: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  text-align: right;
}

.weekly-summary h3 {
  margin: 0;
  color: #2c3e50;
}

/* Mobile responsive adjustments */
@media (max-width: 992px) {
  .times-content {
    padding: 16px;
  }
  
  .add-time-entry, .weekly-calendar {
    padding: 16px;
  }
  
  .calendar-header div,
  .calendar-cell {
    font-size: 0.9rem;
  }
}

/* Medium devices (tablets) */
@media (max-width: 768px) {
  .times-content {
    margin-left: 0;
    width: 100%;
    padding: 12px;
  }
  
  .add-time-entry, .weekly-calendar {
    padding: 14px;
  }

  /* Improve calendar layout for tablets */
  .calendar-header,
  .calendar-row {
    grid-template-columns: repeat(4, 1fr);
  }
  
  .calendar-header div:nth-child(n+5),
  .calendar-row .calendar-cell:nth-child(n+5) {
    display: none;
  }
  
  .calendar-header div:nth-child(4),
  .calendar-row .calendar-cell:nth-child(4) {
    border-right: none;
  }
  
  .calendar-navigation {
    flex-direction: column;
    align-items: stretch;
  }
  
  .calendar-navigation h2 {
    order: -1;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .calendar-navigation div {
    justify-content: center;
  }
}

/* Small devices (phones) */
@media (max-width: 576px) {
  .times-content {
    padding: 10px;
  }
  
  .add-time-entry, .weekly-calendar {
    padding: 12px;
    margin-bottom: 16px;
  }
  
  .add-time-entry form {
    grid-template-columns: 1fr;
    gap: 12px;
  }
  
  /* Further reduce calendar size for phones */
  .calendar-header,
  .calendar-row {
    grid-template-columns: repeat(3, 1fr);
    font-size: 0.85rem;
  }
  
  .calendar-header div:nth-child(n+4),
  .calendar-row .calendar-cell:nth-child(n+4) {
    display: none;
  }
  
  .calendar-header div:nth-child(3),
  .calendar-row .calendar-cell:nth-child(3) {
    border-right: none;
  }
  
  .calendar-navigation button {
    padding: 8px 12px;
    font-size: 0.9rem;
  }
  
  .calendar-navigation h2 {
    font-size: 1.1rem;
  }
  
  /* Improve radio button layout on very small screens */
  .time-type-selector {
    justify-content: space-between;
  }
  
  .time-type-selector label {
    flex-basis: 48%;
    margin-right: 0;
    justify-content: center;
    font-size: 0.9rem;
  }
}

/* Very small devices */
@media (max-width: 400px) {
  .times-content {
    padding: 8px;
  }
  
  .add-time-entry, .weekly-calendar {
    padding: 10px;
  }
  
  .calendar-header,
  .calendar-row {
    font-size: 0.8rem;
    padding: 6px;
  }
  
  .calendar-cell {
    padding: 6px 2px;
    min-height: 40px;
  }
  
  /* Stack radio buttons vertically on very small screens */
  .time-type-selector label {
    flex-basis: 100%;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .times-content {
    padding: 10px;
  }

  .page-title {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  /* Hide desktop components */
  .add-time-entry, 
  .weekly-calendar {
    display: none;
  }

  /* Mobile components container */
  .mobile-times-view {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 120px);
    position: relative;
  }

  .mobile-content {
    flex: 1;
    overflow-y: auto;
    padding-bottom: 70px; /* Space for navigation */
  }

  /* Mobile navigation */
  .mobile-navigation {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    border-top: 1px solid #e0e0e0;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .mobile-nav-button {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background-color: transparent;
    border: none;
    color: #555;
    font-size: 0.8rem;
    cursor: pointer;
  }

  .mobile-nav-button.active {
    color: #007bff;
    background-color: rgba(0, 123, 255, 0.1);
  }

  .mobile-nav-icon {
    font-size: 1.2rem;
    margin-bottom: 3px;
  }

  /* Mobile Add Entry Form */
  .mobile-add-entry-form {
    padding: 15px;
  }

  .mobile-add-entry-form h2 {
    font-size: 1.3rem;
    margin-bottom: 15px;
  }

  .mobile-add-entry-form .form-group {
    margin-bottom: 15px;
  }

  .mobile-add-entry-form label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
  }

  .mobile-add-entry-form .date-picker,
  .mobile-add-entry-form .time-input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }

  .mobile-time-type-buttons {
    display: flex;
    margin-bottom: 20px;
  }

  .time-type-button {
    flex: 1;
    padding: 12px;
    border: 1px solid #ccc;
    background-color: #f8f8f8;
    border-radius: 4px;
    margin-right: 10px;
    font-size: 1rem;
    cursor: pointer;
  }

  .time-type-button:last-child {
    margin-right: 0;
  }

  .time-type-button.active {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }

  .time-summary {
    background-color: #f8f8f8;
    border: 1px solid #e0e0e0;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 20px;
  }

  .time-summary-item {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
  }

  .time-summary-item label {
    font-weight: 500;
    margin-bottom: 0;
  }

  .mobile-submit-button {
    width: 100%;
    padding: 12px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
  }

  .mobile-submit-button:hover {
    background-color: #0069d9;
  }

  /* Mobile Weekly View */
  .mobile-weekly-view {
    padding: 15px;
  }

  .mobile-week-header {
    margin-bottom: 20px;
  }

  .mobile-week-header h2 {
    font-size: 1.2rem;
    margin-bottom: 10px;
  }

  .mobile-week-navigation {
    display: flex;
    justify-content: space-between;
  }

  .mobile-week-navigation button {
    padding: 8px 12px;
    font-size: 0.8rem;
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }

  .mobile-days-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .mobile-day-card {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    padding: 15px;
  }

  .mobile-day-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .mobile-day-info {
    display: flex;
    align-items: center;
  }

  .mobile-day-name {
    font-weight: bold;
    font-size: 1rem;
    margin-right: 10px;
  }

  .mobile-day-number {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .day-num {
    font-size: 1.2rem;
    font-weight: bold;
  }

  .month-name {
    font-size: 0.8rem;
  }

  .mobile-day-hours {
    font-size: 1.2rem;
    font-weight: bold;
    color: #007bff;
  }

  .mobile-day-times {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }

  .mobile-time-item {
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    border-bottom: 1px solid #f0f0f0;
  }

  .mobile-time-item:last-child {
    border-bottom: none;
  }

  .mobile-edit-button {
    width: 100%;
    padding: 8px;
    background-color: #f8f8f8;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.9rem;
    cursor: pointer;
  }

  .mobile-edit-button:hover {
    background-color: #e0e0e0;
  }

  .mobile-weekly-summary {
    margin-top: 20px;
    text-align: center;
    font-size: 1.1rem;
    font-weight: bold;
    color: #333;
  }
}

/* Two column form layout */
.form-two-columns {
  display: flex;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto 20px;
  gap: 30px;
  padding: 10px 0;
}

.left-column, .right-column {
  flex: 1;
}

.left-column {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.right-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
}

.radio-row {
  margin-bottom: 15px;
}

.time-type-selector.horizontal {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
}

.time-type-selector.horizontal label {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.time-type-selector.horizontal label:hover {
  background-color: #f5f5f5;
}

.time-type-selector.horizontal input {
  margin-right: 8px;
}

.button-container {
  margin-top: 5px;
  display: flex;
  justify-content: center;
  width: 100%;
}

.button-container .submit-button {
  min-width: 200px;
}

@media (max-width: 767px) {
  .form-two-columns {
    flex-direction: column;
    gap: 20px;
  }
  
  .right-column {
    align-items: flex-start;
  }
}

/* Wider card for time entry */
.wide-card {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 20px;
  padding: 30px 40px;
  box-sizing: border-box;
}

.add-time-entry h2 {
  margin-top: 0;
  margin-bottom: 25px;
  text-align: center;
  font-size: 22px;
}

/* Two column layout for radio buttons */
.form-row.two-columns {
  display: flex;
  justify-content: space-between;
  gap: 30px;
}

.form-column {
  flex: 1;
  min-width: 200px;
}

.form-column .time-type-selector {
  width: 100%;
}

.time-type-selector h4 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 16px;
  color: #555;
}

@media (max-width: 768px) {
  .form-row.two-columns {
    flex-direction: column;
    gap: 15px;
  }
}

/* Clean implementation of wider time entry card */
.wide-time-entry-card {
  width: 100%;
  max-width: 900px;
  margin: 30px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
}

.wide-time-entry-card h2 {
  margin-top: 0;
  margin-bottom: 25px;
  text-align: center;
  font-size: 22px;
  color: #333;
}

.wide-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.wide-form-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
}

.wide-form-left, 
.wide-form-right {
  width: 48%;
}

.wide-form-group {
  margin-bottom: 20px;
  width: 100%;
}

.date-time-group input,
.date-time-group .date-picker {
  margin-left: 40px;
  width: calc(100% - 40px);
}

.wide-form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.wide-form-group input[type="time"],
.wide-form-group .date-picker {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.wide-option-buttons {
  display: flex;
  gap: 15px;
}

.wide-option-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
}

.wide-option-button input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.wide-option-button.selected {
  background-color: #edf7ff;
  border-color: #3498db;
  color: #3498db;
}

.wide-submit-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  width: 100%;
}

.wide-submit-button {
  width: 100%;
  max-width: 300px;
  padding: 12px 0;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.wide-submit-button:hover {
  background-color: #2980b9;
}

/* New time entry wide card implementation */
.time-entry-wide-card {
  width: 100%;
  max-width: 900px;
  margin: 30px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
}

.time-entry-wide-card h2 {
  margin-top: 0;
  margin-bottom: 25px;
  text-align: center;
  font-size: 22px;
  color: #333;
}

.time-entry-wide-form {
  width: 100%;
}

.time-entry-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.time-entry-column {
  width: 48%;
}

.form-group {
  margin-bottom: 20px;
}

.date-group input,
.date-group .date-picker,
.time-group input {
  margin-left: 40px;
  width: calc(100% - 40px);
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.form-group input[type="time"],
.form-group .date-picker {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.option-buttons {
  display: flex;
  gap: 15px;
}

.option-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
}

.option-button input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.option-button.selected {
  background-color: #edf7ff;
  border-color: #3498db;
  color: #3498db;
}

.submit-row {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.submit-button {
  width: 100%;
  max-width: 300px;
  padding: 12px 0;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: #2980b9;
}

/* Add header styling to match Home.js */
.page-header {
  background-color: #f5f5f5;
  padding: 15px 20px;
  margin-bottom: 20px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.page-header h1 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

/* Compact form styling */
.compact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: flex-end;
}

.form-row .form-group {
  flex: 1;
  min-width: 200px;
}

.form-row .time-type-selector {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  min-width: 150px;
}

.form-row .submit-button {
  margin-top: 10px;
  height: 42px;
  align-self: flex-end;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
  
  .form-row .submit-button {
    width: 100%;
  }
}

/* Form layout updates */
.form-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.form-column.main-column {
  width: 100%;
  max-width: 400px;
  margin-bottom: 20px;
}

.radio-options {
  width: 100%;
  max-width: 500px;
}

.radio-row {
  margin-bottom: 15px;
}

.time-type-selector.horizontal {
  display: flex;
  justify-content: center;
  gap: 30px;
}

.time-type-selector.horizontal label {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 8px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.time-type-selector.horizontal label:hover {
  background-color: #f5f5f5;
}

.time-type-selector.horizontal input {
  margin-right: 8px;
}

.button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.button-container .submit-button {
  min-width: 200px;
}

@media (max-width: 600px) {
  .time-type-selector.horizontal {
    flex-direction: column;
    gap: 10px;
  }
  
  .time-type-selector.horizontal label {
    width: 100%;
  }
}

/* Time entry card styling to match screenshot */
.time-entry-card {
  width: 100%;
  max-width: 850px;
  margin: 0 auto 20px;
  padding: 25px 30px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
}

.time-entry-card h2 {
  margin-top: 0;
  margin-bottom: 25px;
  text-align: center;
  font-size: 22px;
  color: #333;
}

.time-entry-form .form-two-columns {
  display: flex;
  width: 100%;
  margin: 0 auto 20px;
  gap: 40px;
  justify-content: space-between;
}

.time-entry-form .left-column,
.time-entry-form .right-column {
  flex: 0 0 47%;
  max-width: 47%;
}

.time-entry-form .form-group {
  margin-bottom: 15px;
}

.time-entry-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #333;
}

.time-entry-form .required {
  color: #e53e3e;
}

.time-entry-form .date-picker,
.time-entry-form .time-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

/* Option groups styling */
.option-group {
  margin-bottom: 15px;
}

.option-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #333;
}

.option-buttons {
  display: flex;
  gap: 10px;
}

.option-button {
  display: flex;
  align-items: center;
  padding: 8px 15px;
  min-width: 110px;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s;
}

.option-button:hover {
  background-color: #f9f9f9;
}

.option-button.selected {
  background-color: #f0f8ff;
  border-color: #4299e1;
  color: #2b6cb0;
}

.option-button input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

/* Submit button styling */
.button-container {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}

.submit-button {
  background-color: #222;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 24px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  min-width: 200px;
}

.submit-button:hover {
  background-color: #111;
}

/* Mobile Time Entry Styling */
.mobile-form-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  background-color: #f9f9f9;
  padding-bottom: 20px;
}

.mobile-form-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.08);
  padding: 20px;
  margin: 0 15px 20px;
}

.mobile-form-header {
  text-align: center;
  padding: 15px 0;
  margin-bottom: 15px;
}

.mobile-form-title {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin: 0;
}

.mobile-form-content {
  padding: 0 15px;
}

.mobile-form-group {
  margin-bottom: 20px;
}

.mobile-label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
  color: #333;
}

.mobile-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  background-color: white;
}

.mobile-time-input {
  font-size: 18px;
  height: 50px;
}

/* Mobile buttons grid */
.mobile-button-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin-bottom: 20px;
}

.mobile-option-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px;
  background-color: #f0f0f0;
  transition: all 0.2s;
  cursor: pointer;
}

.mobile-option-button.active {
  background-color: #555;
  color: white;
}

.mobile-button-icon {
  font-size: 24px;
  margin-bottom: 5px;
}

.mobile-button-label {
  font-weight: 500;
  margin-bottom: 3px;
}

.mobile-button-time {
  font-size: 12px;
  opacity: 0.8;
}

/* Time summary box */
.mobile-time-summary {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 15px;
  margin-bottom: 20px;
}

.mobile-time-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}

.mobile-time-row:last-child {
  margin-bottom: 0;
}

.mobile-time-label {
  font-weight: 500;
}

.mobile-submit-button {
  display: block;
  width: 100%;
  padding: 14px;
  background-color: #0052cc;
  color: white;
  font-size: 16px;
  font-weight: 500;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 20px;
}

.mobile-submit-button:hover {
  background-color: #0047b3;
}

/* Mini weekly timesheet */
.mobile-mini-weekly {
  margin-top: 30px;
  border: 1px solid #ddd;
  border-radius: 6px;
  overflow: hidden;
  background-color: white;
  margin: 15px;
}

.mobile-mini-weekly-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #ddd;
  background-color: #f5f5f5;
}

.mobile-mini-weekly-title {
  font-size: 14px;
  font-weight: 500;
}

.mobile-mini-weekly-controls {
  display: flex;
  gap: 5px;
}

.mobile-mini-weekly-button {
  padding: 4px 8px;
  background: none;
  border: none;
  color: #0052cc;
  cursor: pointer;
  font-size: 14px;
}

.mobile-mini-weekly-table-container {
  overflow-x: auto;
}

.mobile-mini-weekly-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 12px;
}

.mobile-mini-weekly-table th {
  text-align: left;
  padding: 8px 10px;
  font-weight: 500;
  border-bottom: 1px solid #ddd;
  background-color: #f8f8f8;
}

.mobile-mini-weekly-table td {
  padding: 8px 10px;
  border-bottom: 1px solid #eee;
}

.mobile-alt-row {
  background-color: #f9f9f9;
}

.mobile-day-cell {
  display: flex;
  align-items: center;
  gap: 5px;
}

.mobile-date-display {
  text-align: center;
}

.mobile-date-number {
  color: #0052cc;
  font-weight: 500;
}

.mobile-date-month {
  font-size: 10px;
  color: #666;
}

.mobile-mini-edit-button {
  padding: 4px 8px;
  background-color: #0052cc;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 11px;
  cursor: pointer;
}

.mobile-weekly-total {
  text-align: right;
  font-weight: 500;
  padding: 10px 15px;
}

/* Mobile Weekly View */
.mobile-weekly-container {
  padding: 15px;
}

.mobile-weekly-header {
  text-align: center;
  margin-bottom: 15px;
}

.mobile-weekly-title {
  font-size: 18px;
  font-weight: 500;
  margin: 0;
}

.mobile-weekly-controls {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
  overflow-x: auto;
  padding-bottom: 5px;
}

.mobile-weekly-button {
  white-space: nowrap;
  padding: 8px 12px;
  background-color: #0052cc;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 13px;
  cursor: pointer;
}

.mobile-weekly-days {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.mobile-day-card {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 12px;
}

.mobile-day-card.alt-bg {
  background-color: #f9f9f9;
}

.mobile-day-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.mobile-day-info {
  display: flex;
  align-items: center;
  gap: 8px;
}

.mobile-day-name {
  font-size: 16px;
  font-weight: 500;
}

.mobile-date-info {
  text-align: center;
}

.mobile-date-number {
  color: #0052cc;
  font-weight: 500;
}

.mobile-day-hours {
  font-size: 16px;
  font-weight: 500;
}

.mobile-day-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile-time-detail {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.mobile-time-label {
  font-size: 12px;
  color: #666;
}

.mobile-time-value {
  font-size: 14px;
}

.mobile-edit-day-button {
  padding: 6px 10px;
  background-color: #0052cc;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.mobile-grand-total {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 6px;
  padding: 15px;
  margin-top: 20px;
  text-align: right;
}

.mobile-grand-total-label {
  font-size: 16px;
  font-weight: 500;
}

/* Mobile Navigation */
.mobile-navigation {
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-top: 1px solid #ddd;
  z-index: 100;
}

.mobile-nav-button {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: none;
  border: none;
  font-size: 12px;
  color: #555;
  cursor: pointer;
}

.mobile-nav-button.active {
  color: #0052cc;
}

.mobile-nav-icon {
  font-size: 22px;
  margin-bottom: 5px;
}

/* Mobile view container */
.mobile-times-view {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 60px);
}

.mobile-content {
  flex: 1;
  padding-bottom: 60px; /* Space for navigation */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .times-content {
    padding: 10px 0;
  }
  
  .page-header {
    padding: 15px;
  }
}

/* Fixed layout time entry card styling */
.fixed-card {
  width: 100%;
  max-width: 900px;
  margin: 30px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
}

.fixed-card h2 {
  margin-top: 0;
  margin-bottom: 25px;
  text-align: center;
  font-size: 22px;
  color: #333;
}

.fixed-grid {
  display: table;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 20px;
}

.fixed-grid-left,
.fixed-grid-right {
  display: table-cell;
  width: 50%;
  vertical-align: top;
  padding: 0 10px;
}

.fixed-form-group {
  margin-bottom: 20px;
}

.fixed-form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.fixed-form-group input[type="time"],
.fixed-form-group .date-picker {
  width: 80%;
  margin-left: 40px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box;
}

.fixed-buttons {
  display: flex;
  gap: 15px;
}

.fixed-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 14px;
}

.fixed-button input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.fixed-button.selected {
  background-color: #edf7ff;
  border-color: #3498db;
  color: #3498db;
}

.fixed-submit {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.fixed-submit-button {
  width: 100%;
  max-width: 300px;
  padding: 12px 0;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.fixed-submit-button:hover {
  background-color: #2980b9;
}

/* Add these styles for the improved time entry card */

.improved-time-entry-card {
  width: 100%;
  max-width: 900px;
  margin: 0 auto 30px;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.improved-time-entry-card h2 {
  margin-top: 0;
  margin-bottom: 25px;
  text-align: center;
  font-size: 22px;
  color: #333;
}

.improved-time-form {
  width: 100%;
}

.improved-form-row {
  display: flex;
  gap: 40px;
  margin-bottom: 20px;
}

.improved-form-column {
  flex: 1;
}

/* Add specific styling for left column alignment and positioning */
.improved-time-entry-card .improved-form-column:first-child {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align instead of flex-end */
  text-align: right; /* Right align text */
}

/* Reset all previous form group styles that might conflict */
.improved-time-entry-card .improved-form-column:first-child .improved-form-group {
  width: 100%;
  padding: 0;
  margin-bottom: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-end; /* Right align the contents */
}

/* Style all labels consistently */
.improved-time-entry-card .improved-form-column:first-child .improved-form-group label {
  width: 150px; /* Match input width */
  text-align: left;
  margin-bottom: 8px;
  padding: 0;
}

/* Style Date Picker properly - completely new approach */
.improved-time-entry-card .improved-form-column:first-child .react-datepicker-wrapper {
  width: auto;
  padding: auto;
  border: none;
  margin: 0;
  display: block;
}

.improved-time-entry-card .improved-form-column:first-child .react-datepicker__input-container {
  display: block !important;
}

/* Override any styles that might cause stacking */
.improved-time-entry-card .improved-form-column:first-child .react-datepicker__input-container input {
  position: static !important;
  display: block !important;
  width: 150px !important;
  background-color: white !important;
  border: 1px solid #ddd !important;
  padding: 12px !important;
  border-radius: 4px !important;
}

/* Style time input consistently */
.improved-time-entry-card .improved-form-column:first-child input[type="time"].improved-input {
  width: 150px !important;
  padding: 12px !important;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
  font-size: 16px !important;
  display: block !important;
}

.improved-form-group {
  margin-bottom: 25px;
}

.improved-form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.improved-form-group .required {
  color: #e53e3e;
}

.improved-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.improved-radio-group {
  display: flex;
  gap: 15px;
}

.improved-radio-button {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
}

.improved-radio-button input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.improved-radio-button.selected {
  background-color: #e6f3ff;
  border-color: #3498db;
  color: #3498db;
}

.improved-submit-row {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.improved-submit-button {
  padding: 12px 24px;
  min-width: 180px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.improved-submit-button:hover {
  background-color: #2980b9;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .improved-form-row {
    flex-direction: column;
    gap: 15px;
  }
  
  .improved-time-entry-card {
    padding: 20px;
    margin-bottom: 20px;
  }
}

/* Single date input style */
.date-input-single {
  width: 150px !important;
  padding: 12px !important;
  border: 1px solid #ddd !important;
  border-radius: 4px !important;
  font-size: 16px !important;
  box-sizing: border-box !important;
  display: block !important;
  background-color: white !important;
}

/* Set Time Adjustment buttons to 120px width */
.improved-time-entry-card .improved-form-column:last-child .improved-form-group:last-child .improved-radio-button {
  width: 120px !important;
  flex: none !important; /* Override the flex: 1 to ensure exact width */
  justify-content: center !important;
}

/* Set Call Type buttons (Call and Wrap) to 120px width */
.improved-time-entry-card .improved-form-column:last-child .improved-form-group:first-child .improved-radio-button {
  width: 120px !important;
  flex: none !important; /* Override the flex: 1 to ensure exact width */
  justify-content: center !important;
}