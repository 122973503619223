.profile-completion {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.profile-completion-content {
  background-color: white;
  padding: 25px;
  border-radius: 8px;
  width: 90%;
  max-width: 700px;
  max-height: 90vh;
  overflow-y: auto;
}

.profile-completion h2 {
  margin-top: 0;
  margin-bottom: 20px;
}

.profile-completion .form-group {
  margin-bottom: 20px;
}

.profile-completion label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.profile-completion input,
.profile-completion select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.personal-info-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.name-birth-ssn-group,
.contact-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.address-group {
  display: flex;
  gap: 20px;
}

.address-group > div:first-child {
  flex-grow: 1;
}

.address-group > div:last-child {
  width: 30%;
}

.emergency-contacts {
  margin-top: 30px;
}

.emergency-contact {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 15px;
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.vehicle-info {
  margin-top: 30px;
  border-top: 1px solid #e9ecef;
  padding-top: 20px;
}

.vehicle-info h3 {
  margin-bottom: 20px;
  color: #2c3e50;
}

.vehicle-fields-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e9ecef;
}

.profile-completion button {
  padding: 10px 20px;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.profile-completion button:hover {
  background-color: #27ae60;
}

.error-message {
  color: #721c24;
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 15px;
}