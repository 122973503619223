.page-container {
  display: flex;
}

.receipt-viewer {
  flex: 1;
  padding: 20px;
}

.page-header {
  background-color: #f8f9fa;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.page-header h1 {
  margin: 0;
  color: #2c3e50;
  font-size: 24px;
}

.page-header p {
  margin: 5px 0 0;
  color: #666;
  font-size: 14px;
}

.receipt-filters {
  margin: 20px 0;
}

.receipt-type-filter {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 14px;
  min-width: 200px;
}

.receipt-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px 0;
}

.receipt-card {
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.receipt-header {
  padding: 15px;
  background: #f8f9fa;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.receipt-header h3 {
  margin: 0;
  color: #333;
}

.receipt-date {
  color: #666;
  font-size: 0.9em;
}

.receipt-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-bottom: 1px solid #eee;
}

.receipt-details {
  padding: 15px;
}

.receipt-details p {
  margin: 5px 0;
  color: #666;
}

.receipt-details strong {
  color: #333;
}

.error {
  color: #dc3545;
  padding: 20px;
  text-align: center;
  background: #f8d7da;
  border-radius: 4px;
  margin: 20px 0;
}

@media (max-width: 768px) {
  .receipt-grid {
    grid-template-columns: 1fr;
  }
}

.loading-message,
.error-message,
.no-receipts-message {
  text-align: center;
  padding: 20px;
  margin: 20px;
  border-radius: 8px;
}

.loading-message {
  background-color: #e3f2fd;
  color: #1976d2;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
}

.no-receipts-message {
  background-color: #f5f5f5;
  color: #616161;
}

.receipt-sections {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px 0;
}

.receipt-section {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.receipt-section h2 {
  color: #2c3e50;
  margin-bottom: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid #f0f0f0;
}

.receipt-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.no-receipts-message {
  text-align: center;
  color: #666;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 4px;
}
