.crew-members-container {
  display: flex;
}

.crew-members-content {
  margin-left: 0px; /* Width of the sidebar */
  margin-right: 0px;
  padding: 20px;
  width: calc(100% - 0px); /* Adjust width to account for sidebar */
}

.add-crew-member, .crew-filters, .crew-member-list {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-crew-member form {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

.add-crew-member input, .add-crew-member select, .crew-filters select, .crew-filters input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-crew-member button, .crew-member-item button {
  padding: 8px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-crew-member button:hover, .crew-member-item button:hover {
  background-color: #2980b9;
}

.crew-filters {
  display: flex;
  gap: 10px;
}

.crew-member-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 15px;
}

.crew-member-item {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 15px;
  text-align: center;
}

.crew-member-item h3 {
  margin-top: 0;
  color: #2c3e50;
}

.crew-member-item p {
  margin: 5px 0;
  color: #34495e;
}

.crew-member-item select {
  width: 100%;
  margin-top: 10px;
  padding: 5px;
}

.crew-member-item button {
  margin-top: 10px;
  width: 100%;
}

.license-select-container {
  margin: 10px 0;
}

.license-select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.license-select:focus {
  outline: none;
  border-color: #3498db;
}

/* Role editing styles */
.role-section {
  margin: 10px 0;
}

.role-display {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.role-display:hover {
  background-color: #f5f5f5;
}

.role-value {
  font-weight: 500;
}

.edit-icon {
  opacity: 0;
  font-size: 14px;
  color: #666;
  margin-left: 5px;
}

.role-display:hover .edit-icon {
  opacity: 1;
}

.role-edit {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 4px;
  margin-top: 5px;
}

.role-edit select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.role-actions {
  display: flex;
  gap: 10px;
}

.role-actions button {
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.save-role {
  background-color: #4CAF50;
  color: white;
  border: none;
}

.cancel-role {
  background-color: #f5f5f5;
  border: 1px solid #ddd;
}

.role-actions button:hover {
  opacity: 0.9;
}