.dispatch-container {
  display: flex;
}

.dispatch-content {
  margin-left: 0px; /* Width of the sidebar */
  margin-right: 0px;
  padding: 20px;
  width: calc(100% - 0px); /* Adjust width to account for sidebar */
}

.view-selector {
  margin-bottom: 20px;
}

.view-selector select {
  padding: 8px 12px;
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.dispatch-tile {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dispatch-layout {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.add-time-entry {
  flex: 0 0 auto;
  width: 300px;
  padding: 30px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-time-entry h2 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.2em;
  color: #2c3e50;
}

.time-entry-inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.form-group label {
  font-size: 0.9em;
  font-weight: bold;
  color: #34495e;
}

.form-group input,
.form-group select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 0.9em;
}

.form-group input[type="text"],
.form-group input[type="date"] {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 150px; /* Set width to 150px */
}

.submit-button {
  padding: 8px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9em;
  align-self: flex-start;
}

.submit-button:hover {
  background-color: #2980b9;
}

.crew-selection {
  flex: 1;
}

.crew-members-list {
  margin-top: 20px;
}

.crew-members-list h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.2em;
  color: #2c3e50;
}

.crew-members-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.crew-member-tile {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 15px;
  position: relative;
}

.crew-member-tile input[type="checkbox"] {
  position: absolute;
  top: 10px;
  right: 10px;
}

.crew-member-tile h4 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 1em;
}

.crew-member-tile p {
  margin: 3px 0;
  font-size: 0.8em;
  color: #6c757d;
}

.assigned-times {
  margin-top: 20px;
}

.assigned-times h3 {
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 1.2em;
  color: #2c3e50;
}

.assigned-times table {
  width: 100%;
  border-collapse: collapse;
}

.assigned-times th,
.assigned-times td {
  padding: 10px;
  border: 1px solid #e9ecef;
  text-align: left;
}

.assigned-times th {
  background-color: #f8f9fa;
  font-weight: bold;
}

.assigned-times tr:nth-child(even) {
  background-color: #f8f9fa;
}

@media (max-width: 768px) {
  .dispatch-content {
    margin-left: 0;
    width: 100%;
  }

  .dispatch-layout {
    flex-direction: column;
  }

  .add-time-entry {
    width: 100%;
  }
}

.dispatch-run-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dispatch-run-tile,
.dispatched-runs-tile {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dispatch-run-tile h2,
.dispatched-runs-tile h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #2c3e50;
}

.crew-selection {
  margin-bottom: 20px;
}

.crew-search-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.crew-members-list {
  max-height: 400px;
  overflow-y: auto;
}

.crew-members-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 20px;
}

.crew-member-tile {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 15px;
  position: relative;
}

.crew-member-tile input[type="checkbox"] {
  position: absolute;
  top: 10px;
  right: 10px;
}

.crew-member-tile h4 {
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 1em;
}

.crew-member-tile p {
  margin: 5px 0;
  font-size: 0.9em;
  color: #6c757d;
}

.dispatched-runs-table {
  width: 100%;
  border-collapse: collapse;
}

.dispatched-runs-table th,
.dispatched-runs-table td {
  border: 1px solid #e9ecef;
  padding: 12px;
  text-align: left;
}

.dispatched-runs-table th {
  background-color: #f8f9fa;
  font-weight: bold;
  color: #2c3e50;
}

.dispatched-runs-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.dispatched-runs-table tr:hover {
  background-color: #e9ecef;
}

.dispatch-run-layout {
  display: flex;
  gap: 20px;
}

.dispatch-run-content .crew-selection {
  flex: 1;
}

.dispatch-run-content .dispatched-runs {
  flex: 2;
}

.dispatch-run-content .crew-members-list {
  margin-top: 20px;
}

.dispatch-run-content .crew-members-grid {
  max-height: 400px;
  overflow-y: auto;
}

.dispatch-tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 1rem;
}

.dispatch-tabs button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  background-color: transparent;
  color: #666;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.dispatch-tabs button:hover {
  background-color: #f5f5f5;
  color: #333;
}

.dispatch-tabs button.active {
  background-color: #007bff;
  color: white;
}

.dispatch-run-tile form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.dispatch-run-button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-start;
}

.dispatch-run-button:hover {
  background-color: #2980b9;
}

.assignment-confirmation {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;
}

.unassigned-crew-members {
  background-color: #fff3cd;
  color: #856404;
  padding: 10px;
  margin-top: 15px;
  border-radius: 4px;
}

.unassigned-crew-members ul {
  list-style-type: none;
  padding-left: 0;
}

.unassigned-crew-members li {
  margin-bottom: 5px;
}

.crew-member-tile.selected {
  background-color: #e0f7fa;
  border: 2px solid #00bcd4;
}

.calendar {
  width: 100%;
  border-collapse: collapse;
}

.calendar th, .calendar td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.calendar th {
  background-color: #f2f2f2;
}

.week-navigation {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.week-navigation button {
  padding: 5px 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.week-navigation button:hover {
  background-color: #2980b9;
}

.date-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.date-navigation button {
  padding: 5px 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.date-navigation button:hover {
  background-color: #2980b9;
}

.date-navigation input[type="date"] {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.dispatch-run-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dispatch-run-tile {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dispatch-run-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 350px; /* Adjust this value if needed */
}

.form-row {
  display: flex;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input[type="text"],
.form-group input[type="date"],
.form-group select,
.form-group textarea {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group textarea {
  resize: vertical;
  min-height: 100px;
}

.run-type-buttons {
  display: flex;
  gap: 10px;
}

.run-type-buttons button {
  padding: 8px 16px;
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.run-type-buttons button.active {
  background-color: #3498db;
  color: white;
}

.dispatch-run-button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  align-self: flex-start;
}

.dispatch-run-button:hover {
  background-color: #2980b9;
}

/* Responsive design */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }
}

.form-group .run-title-input {
  width: 350px;
}

.form-group .run-date-input {
  width: 150px;
}

.form-group input[type="text"].run-title-input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 350px; /* Set width to 350px for Run Title */
}

.form-group input[type="date"].run-date-input,
.form-group select#assignedTo {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 150px; /* Date input and Assign To dropdown width */
}

.form-group textarea#address,
.form-group textarea#itemDescription {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 350px; /* Pickup/Drop-off Address and Item Description width */
  resize: vertical;
  min-height: 60px;
}

.form-group input[type="file"]#picture {
  width: 225px; /* Add Picture input width */
}

.dispatch-run-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.dispatch-run-tile {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.crew-selection .form-group {
  margin-bottom: 15px;
}

.crew-members-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

.crew-member-tile {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 10px;
  position: relative;
}

.crew-member-tile input[type="checkbox"] {
  position: absolute;
  top: 10px;
  right: 10px;
}

.crew-member-tile h4 {
  margin-top: 0;
  margin-bottom: 5px;
}

.crew-member-tile p {
  margin: 5px 0;
  font-size: 0.9em;
  color: #6c757d;
}

.time-input-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.time-input-container input[type="number"] {
  width: 150px;
  margin-right: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.time-input-container input[type="number"]::placeholder {
  color: #999;
  font-size: 14px;
}

.time-input-container span {
  font-weight: bold;
}

.date-time-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 15px;
}

.time-entry-inputs {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.time-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.time-input-container input {
  width: 150px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.time-input-container span {
  min-width: 60px;
  color: #666;
}

.deselect-all-button {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.deselect-all-button:hover {
  background-color: #c0392b;
}

.selection-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.select-all-button {
  padding: 8px 16px;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.select-all-button:hover {
  background-color: #27ae60;
}

/* Update existing deselect button styles to match */
.deselect-all-button {
  padding: 8px 16px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.deselect-all-button:hover {
  background-color: #c0392b;
}

.run-row {
  transition: background-color 0.3s ease;
}

.run-row:hover {
  background-color: #f5f5f5;
}

.run-row.expanded {
  background-color: #e3f2fd;
}

.expanded-details {
  background-color: #f8f9fa;
}

.run-details {
  padding: 15px;
  border-top: 1px solid #dee2e6;
}

.run-details p {
  margin: 8px 0;
}

.run-details strong {
  color: #2c3e50;
  margin-right: 8px;
}

.run-image {
  margin-top: 10px;
  max-width: 300px;
}

.run-image img {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.date-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  gap: 20px;
}

.date-navigation button {
  padding: 8px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.date-navigation button:hover {
  background-color: #2980b9;
}

.date-display {
  font-size: 1.2em;
  font-weight: bold;
  color: #2c3e50;
  min-width: 300px;
  text-align: center;
}

.future-runs-tile {
  margin-top: 30px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.run-requests-wrapper {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-height: 300px;
}

.loading-state,
.error-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  font-size: 1.1rem;
  color: #666;
}

.error-state {
  color: #dc3545;
  text-align: center;
  padding: 2rem;
  background-color: #fff3f3;
  border-radius: 8px;
}

.crew-members-section {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.crew-members-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.header-actions {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.assign-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
  height: 36px;
  white-space: nowrap;
}

.assign-button:hover {
  background: #0056b3;
}

.assign-button:disabled,
.assign-button.disabled {
  background: #ccc;
  cursor: not-allowed;
  opacity: 0.7;
}

.assign-button.processing {
  background: #ffc107;
  cursor: wait;
}

.assign-button.success {
  background: #28a745;
}

.assign-button.error {
  background: #dc3545;
}

.crew-members-header h2 {
  margin: 0 0 15px 0;
  font-size: 1.5rem;
  color: #333;
}

.search-bar {
  width: 100%;
  max-width: 300px;
  margin-bottom: 15px;
}

.search-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.view-toggle {
  display: flex;
  gap: 10px;
  margin-bottom: 15px;
  width: 100%;
}

.view-toggle button {
  padding: 8px 16px;
  border: 1px solid #ddd;
  background: white;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s;
}

.view-toggle button.active {
  background: #007bff;
  color: white;
  border-color: #007bff;
}

.select-actions {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
  width: 100%;
}

.select-actions button {
  padding: 6px 12px;
  border: 1px solid #ddd;
  background: white;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.members-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 15px;
  width: 100%;
}

.member-tile {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 12px;
  background: white;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
}

.member-tile:hover {
  border-color: #007bff;
}

.member-tile.selected {
  background-color: #f8f9fa;
  border-color: #007bff;
}

.checkbox {
  position: absolute;
  top: 12px;
  right: 12px;
}

.checkbox input[type="checkbox"] {
  width: 16px;
  height: 16px;
  margin: 0;
  cursor: pointer;
}

.member-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.member-info h3 {
  margin: 0;
  font-size: 14px;
  color: #000;
  font-weight: 500;
  padding-right: 24px;
}

.member-info p {
  margin: 0;
  font-size: 13px;
  color: #666;
  line-height: 1.4;
}

.member-detail {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.member-detail span {
  color: #666;
  font-size: 13px;
}

.member-status {
  padding: 4px 12px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 500;
  background-color: #e9ecef;
  color: #495057;
  margin-left: auto;
}

.member-status.available {
  background-color: #d4edda;
  color: #155724;
}

.member-status.unavailable {
  background-color: #f8d7da;
  color: #721c24;
}

.run-requests-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.run-request-tile {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.run-request-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.run-request-title {
  margin: 0;
  color: #2c3e50;
  font-size: 1.2em;
  font-weight: 500;
}

.run-request-status {
  padding: 6px 12px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 500;
  background-color: #e9ecef;
  color: #495057;
}

.run-request-status.pending {
  background-color: #fff3cd;
  color: #856404;
}

.run-request-status.approved {
  background-color: #d4edda;
  color: #155724;
}

.run-request-status.rejected {
  background-color: #f8d7da;
  color: #721c24;
}

.run-request-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-bottom: 15px;
}

.detail-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.detail-label {
  font-size: 0.9em;
  color: #6c757d;
  font-weight: 500;
}

.detail-value {
  font-size: 1em;
  color: #2c3e50;
}

.run-request-description {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #e0e0e0;
}

.run-request-description h4 {
  margin: 0 0 10px 0;
  font-size: 1em;
  color: #2c3e50;
}

.run-request-description p {
  margin: 0;
  font-size: 0.9em;
  color: #6c757d;
  line-height: 1.5;
}

.run-requests-list {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.loading-state,
.error-state {
  text-align: center;
  padding: 20px;
  color: #6c757d;
}

.error-state {
  color: #dc3545;
}

/* Make sure buttons have consistent styling */
button {
  transition: all 0.2s;
}

button:hover {
  background-color: #f0f0f0;
}

button.active:hover {
  background-color: #0056b3;
}

.run-request-tile {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.run-request-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 15px;
}

.run-request-title {
  margin: 0;
  color: #2c3e50;
  font-size: 1.2em;
  font-weight: 500;
}

.run-request-status {
  padding: 6px 12px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: 500;
  background-color: #e9ecef;
  color: #495057;
}

.run-request-status.pending {
  background-color: #fff3cd;
  color: #856404;
}

.run-request-status.approved {
  background-color: #d4edda;
  color: #155724;
}

.run-request-status.rejected {
  background-color: #f8d7da;
  color: #721c24;
}

.run-request-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-bottom: 15px;
}

.detail-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.detail-label {
  font-size: 0.9em;
  color: #6c757d;
  font-weight: 500;
}

.detail-value {
  font-size: 1em;
  color: #2c3e50;
}

.run-request-description {
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #e0e0e0;
}

.run-request-description h4 {
  margin: 0 0 10px 0;
  font-size: 1em;
  color: #2c3e50;
}

.run-request-description p {
  margin: 0;
  font-size: 0.9em;
  color: #6c757d;
  line-height: 1.5;
}

.assigned-runs-section {
  margin-top: 2rem;
  padding: 1rem;
  background-color: var(--background-color);
  border-radius: 8px;
}

.assigned-runs-section h3 {
  margin-bottom: 1rem;
  color: var(--text-color);
}

.assigned-runs-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1rem;
  background-color: var(--background-color);
}

.assigned-runs-table th,
.assigned-runs-table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

.assigned-runs-table th {
  background-color: var(--secondary-background);
  font-weight: 600;
  color: var(--text-color);
}

.assigned-runs-table tr:hover {
  background-color: var(--hover-color);
}