.welcome-container {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  color: #333;
}

.welcome-header {
  text-align: center;
  margin-bottom: 2rem;
}

.welcome-logo {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 1rem;
}

.welcome-header h1 {
  font-size: 2.5rem;
  color: #3498db;
}

.welcome-content {
  line-height: 1.6;
}

.welcome-section {
  margin-bottom: 2rem;
}

.welcome-section h2 {
  font-size: 1.8rem;
  color: #2c3e50;
  margin-bottom: 1rem;
}

.welcome-section h3 {
  font-size: 1.4rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.welcome-section ul {
  padding-left: 1.5rem;
}

.welcome-footer {
  text-align: center;
  margin-top: 2rem;
  font-size: 0.9rem;
  color: #7f8c8d;
}

.feedback-section {
  margin-top: 2rem;
}

.feedback-section form {
  display: flex;
  flex-direction: column;
}

.feedback-section textarea {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  resize: vertical;
}

.feedback-section button {
  align-self: flex-start;
  padding: 0.5rem 1rem;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.feedback-section button:hover {
  background-color: #2980b9;
}

.feedback-section ul {
  margin-bottom: 1rem;
  padding-left: 1.5rem;
}

.feedback-section li {
  margin-bottom: 0.5rem;
}

@media (max-width: 600px) {
  .welcome-container {
    padding: 1rem;
  }

  .welcome-header h1 {
    font-size: 2rem;
  }

  .welcome-section h2 {
    font-size: 1.5rem;
  }

  .welcome-section h3 {
    font-size: 1.2rem;
  }

  .feedback-section button {
    align-self: stretch;
  }
}

.user-info {
  width: 300px; /* Set the width to 300px */
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  margin: 20px auto; /* Center the element horizontally and add some vertical margin */
}

.user-info p {
  margin: 0 0 10px 0;
  font-size: 0.9rem;
  color: #333;
  text-align: center; /* Center the text */
}

.logout-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
  width: 100px;
  text-align: center;
  display: block; /* Make the button a block element */
  margin: 0 auto; /* Center the button */
}

.logout-button:hover {
  background-color: #c0392b;
}

@media (max-width: 600px) {
  .user-info {
    width: 90%; /* Make it responsive for very small screens */
    max-width: 300px; /* Ensure it doesn't exceed 300px */
  }
}

/* ... (previous styles remain the same) ... */

.logout-button.welcome-logout-button {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 3px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.3s ease;
  width: 100px; /* Set the width to 100px */
  text-align: center;
}

.logout-button.welcome-logout-button:hover {
  background-color: #c0392b;
}

/* ... (rest of the styles remain the same) ... */
