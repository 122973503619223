.role-manager {
  background-color: #f9fafc;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #e2e8f0;
}

.role-manager h3 {
  font-size: 1.3rem;
  margin-top: 0;
  margin-bottom: 15px;
  color: #2d3748;
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 10px;
}

.role-manager .form-group {
  margin-bottom: 15px;
}

.role-manager label {
  display: block;
  margin-bottom: 5px;
  font-weight: 600;
  color: #4a5568;
}

.role-manager select {
  padding: 10px;
  border-radius: 4px;
  width: 100%;
  transition: all 0.2s;
}

.user-roles-section {
  margin-top: 25px;
  padding-top: 15px;
  border-top: 1px dashed #e2e8f0;
}

.user-roles-section h4 {
  font-size: 1.1rem;
  margin-bottom: 10px;
  color: #2d3748;
}

.role-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.role-item {
  background-color: #f0f8ff;
  border: 1px solid #4299e1;
  border-radius: 4px;
  padding: 10px 15px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.role-item strong {
  font-size: 0.95rem;
  color: #2b6cb0;
}

.role-date {
  font-size: 0.8rem;
  color: #718096;
}

@media (min-width: 768px) {
  .role-manager form {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 15px;
  }
  
  .role-manager button {
    grid-column: 1 / -1;
    width: auto;
    justify-self: start;
  }
}
