.page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem 0;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
  margin-bottom: 2rem;
}

.page-header h1 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin: 0;
  margin-bottom: 0.5rem;
  text-align: center;
}

.page-header p {
  font-size: 1.1rem;
  color: #6c757d;
  margin: 0;
  text-align: center;
}