body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding-bottom: 20px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Apply this to your top-level app container */
.app-container {
  margin: 0 auto; /* Center the content horizontally */
  padding: 0 20px; /* Add some padding on smaller screens */
  max-width: 100%; /* Full width on smaller screens */
}

/* Media query for screens wider than a certain width */
@media screen and (min-width: 1200px) {
  .app-container {
    max-width: 1160px; /* Set a maximum width */
    padding: 0; /* Remove padding on larger screens */
  }
}

/* Additional media queries for different screen sizes */
@media screen and (min-width: 768px) {
  .app-container {
    max-width: 720px;
  }
}

@media screen and (min-width: 992px) {
  .app-container {
    max-width: 960px;
  }
}

/*//*i added this and it seems to have fixed it//  Apply this to your top-level app container */
 .app-container {
/*  margin: 0 auto; /* Center the content horizontally *//*
  padding: 0 20px; /* Add some padding on smaller screens */
  max-width: 100%; /* Full width on smaller screens */
}
