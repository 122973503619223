.vehicles-container {
  display: flex;
  width: 100%;
}

.vehicles-content {
  flex: 1;
  padding: 20px;
  width: 100%;
}

.page-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem 0;
  background-color: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.page-header h1 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin: 0;
  margin-bottom: 0.5rem;
  text-align: center;
}

.page-header .subtitle {
  font-size: 1.1rem;
  color: #6c757d;
  margin: 0;
  text-align: center;
}

.add-vehicle {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.add-vehicle h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #2c3e50;
  font-size: 1.5em;
}

.add-vehicle form {
  display: grid;
  gap: 15px;
  grid-template-columns: repeat(2, 1fr);
}

.add-vehicle .form-group {
  display: flex;
  flex-direction: column;
}

.add-vehicle label {
  margin-bottom: 5px;
  font-weight: bold;
  color: #34495e;
}

.add-vehicle input,
.add-vehicle select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.add-vehicle input[type="file"] {
  border: none;
  padding: 0;
}

.add-vehicle button {
  padding: 10px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
  grid-column: span 2;
}

.add-vehicle button:hover {
  background-color: #2980b9;
}

.add-vehicle button[type="button"] {
  background-color: #e74c3c;
}

.add-vehicle button[type="button"]:hover {
  background-color: #c0392b;
}

@media (max-width: 600px) {
  .add-vehicle form {
    grid-template-columns: 1fr;
  }

  .add-vehicle button {
    grid-column: span 1;
  }
}

.vehicles-list ul {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  list-style: none;
  padding: 0;
}

.vehicles-list li {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: all 0.3s ease;
  cursor: pointer;
}

.vehicles-list li:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.vehicles-list h3 {
  color: #2c3e50;
  margin: 0 0 10px 0;
  font-size: 1.1em;
}

.vehicles-list p {
  margin: 5px 0;
  color: #34495e;
  font-size: 0.9em;
}

.vehicles-list li.expanded {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  transform: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
}

.no-vehicles-tile {
  background-color: #f8f9fa;
  border: 2px dashed #ced4da;
  border-radius: 8px;
  padding: 40px;
  text-align: center;
  margin-bottom: 20px;
}

.no-vehicles-tile h2 {
  color: #2c3e50;
  margin-bottom: 10px;
}

.no-vehicles-tile p {
  color: #7f8c8d;
  margin-bottom: 20px;
}

.add-vehicle-btn {
  padding: 10px 20px;
  font-size: 1.1em;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-vehicle-btn:hover {
  background-color: #2980b9;
}

.vehicle-image {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
  margin-bottom: 10px;
}

.add-vehicle input[type="file"] {
  margin-top: 10px;
}

.vendor-section {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.add-vendor-btn {
  width: 250px;  /* Set fixed width for Add New Vendor button */
  padding: 10px 20px;
  font-size: 1em;
  background-color: #2ecc71;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-vendor-btn:hover {
  background-color: #27ae60;
}

.add-vendor-form {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Create two equal columns */
  gap: 10px; /* Reduce gap from 20px to 10px */
  padding: 20px;
}

.add-vendor-form .form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.add-vendor-form input {
  width: 200px;  /* Fixed width for all inputs */
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
}

.add-vendor-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #2c3e50;
}

.add-vendor-form .button-group {
  grid-column: span 2; /* Make buttons span both columns */
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  margin-top: 10px;
}

.add-vendor-form button {
  width: 250px;  /* Set fixed width for vendor form buttons */
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-vendor-form button[type="submit"] {
  background-color: #2ecc71;
  color: white;
}

.add-vendor-form button[type="button"] {
  background-color: #e74c3c;
  color: white;
}

.vehicle-image-preview {
  max-width: 200px;
  max-height: 200px;
  object-fit: cover;
  margin-bottom: 10px;
}

.vehicle-image.small {
  width: 200px;
  height: 200px;
  object-fit: cover;
  border-radius: 4px;
}

.vehicle-images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 15px;
}

.vehicle-image-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vehicle-image-wrapper button {
  margin-top: 5px;
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.vehicle-image-wrapper button:hover {
  background-color: #d32f2f;
}

.vehicle-documents-container {
  margin-top: 15px;
}

.vehicle-document-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.vehicle-document-wrapper a {
  margin-right: 10px;
  color: #3498db;
  text-decoration: none;
}

.vehicle-document-wrapper button {
  padding: 3px 8px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
}

.vehicle-document-wrapper button:hover {
  background-color: #c0392b;
}

.add-vehicle select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}

.rental-dates {
  display: flex;
  gap: 20px;
  margin: 15px 0;
}

.date-picker-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.date-picker-group label {
  font-weight: 500;
  color: #2c3e50;
}

.rental-date-picker {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 120px;
  font-size: 14px;
}

.rental-date-picker:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.remove-production-button {
  background-color: #e74c3c !important;  /* Red color */
}

.remove-production-button:hover {
  background-color: #c0392b !important;  /* Darker red on hover */
}

.edit-vehicle-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.edit-vehicle-form h3 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #2c3e50;
}

.edit-vehicle-form .form-group {
  margin-bottom: 15px;
}

.edit-vehicle-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.edit-vehicle-form input[type="text"] {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.edit-form-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.edit-form-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-form-buttons button:first-child {
  background-color: #3498db;
  color: white;
}

.edit-form-buttons button:last-child {
  background-color: #e74c3c;
  color: white;
}

.current-images {
  margin: 15px 0;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
}

.image-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.edit-vehicle-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-vehicle-form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

.edit-form-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.edit-form-buttons button:first-child {
  background-color: #3498db;
}

.edit-form-buttons button:last-child {
  background-color: #e74c3c;
}

.vehicle-buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.attach-vehicle {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.attach-vehicle h2 {
  color: #2c3e50;
  margin: 0;  /* Remove margin to tighten spacing */
}

.attach-controls {
  display: flex;
  gap: 15px;
  align-items: center;  /* Align items vertically */
}

.attach-vehicle select {
  width: 200px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  color: #2c3e50;
  cursor: pointer;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.attach-vehicle button {
  width: 200px;
  padding: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.rental-rate-container {
  margin: 10px 0;
}

.rental-rate-inputs {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dollar-sign {
  font-size: 16px;
  color: #2c3e50;
}

.rental-rate-input {
  width: 100px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.rental-period-select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  cursor: pointer;
}

.rental-rate-input:focus,
.rental-period-select:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.existing-images-grid,
.new-images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  margin: 1rem 0;
}

.image-container {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 0.5rem;
}

.vehicle-thumbnail {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
}

.remove-image-btn {
  position: absolute;
  top: 0.25rem;
  right: 0.25rem;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
  font-size: 12px;
}

.remove-image-btn:hover {
  background-color: rgba(255, 0, 0, 1);
}

.edit-vehicle-form {
  max-height: 80vh;
  overflow-y: auto;
  padding: 1rem;
}

.vehicle-images-section,
.vehicle-damage-section {
  margin: 1rem 0;
  padding: 1rem;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 1rem;
  margin-top: 0.5rem;
}

.vehicle-damage-section h4 {
  color: #dc3545; /* Red color for damage section */
  margin-bottom: 0.5rem;
}

.production-info {
  background-color: #e8f4fd;
  padding: 8px;
  border-radius: 4px;
  margin: 8px 0;
  border-left: 4px solid #3498db;
}

.production-info strong {
  color: #2c3e50;
  margin-right: 8px;
}

.unassigned-vehicles {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.unassigned-vehicles h2 {
  color: #2c3e50;
  margin-bottom: 20px;
}

.unassigned-vehicles-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.unassigned-vehicle-card {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease;
}

.unassigned-vehicle-card:hover:not(.expanded) {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

/* Add styles for expanded state */
.unassigned-vehicle-card.expanded {
  grid-column: 1 / -1;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;
  transform: none;  /* Remove transform when expanded */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);  /* Permanent shadow when expanded */
  background-color: white;  /* Slightly different background when expanded */
  transition: all 0.3s ease;
}

.vehicle-details {
  display: none;
  padding: 15px;
  background-color: white;
  border-radius: 8px;
  margin-top: 15px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.vehicle-details.visible {
  display: block;
  opacity: 1;
}

.vehicle-images-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 15px;
}

.vehicle-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.vehicle-image:hover {
  transform: scale(1.05);
}

.vehicle-info h3 {
  color: #2c3e50;
  margin: 0 0 10px 0;
  font-size: 1.1em;
}

.vehicle-info p {
  margin: 5px 0;
  color: #34495e;
  font-size: 0.9em;
}

.vehicle-actions {
  margin-top: 15px;
}

.select-vehicle-btn {
  width: 100%;
  padding: 8px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.select-vehicle-btn:hover {
  background-color: #2980b9;
}

.no-vehicles-message {
  grid-column: 1 / -1;
  text-align: center;
  padding: 20px;
  background-color: #f8f9fa;
  border: 2px dashed #e9ecef;
  border-radius: 8px;
  color: #6c757d;
  font-size: 1.1em;
}

.vehicle-actions {
  display: flex;
  gap: 10px;
  margin-top: 15px;
}

.edit-btn {
  padding: 8px 16px;
  background-color: #f39c12;  /* Orange color for edit */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.edit-btn:hover {
  background-color: #d68910;
}

.remove-production-button {
  padding: 8px 16px;
  background-color: #e74c3c;  /* Red color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.remove-production-button:hover {
  background-color: #c0392b;
}

/* Ensure buttons don't trigger card expansion */
.vehicle-actions button {
  z-index: 2;
}

/* Adjust expanded view to accommodate buttons */
.unassigned-vehicle-card.expanded .vehicle-actions,
.vehicle-card.expanded .vehicle-actions {
  grid-column: 1;
  align-self: start;
  margin-top: 20px;
}

/* Add back the edit form modal styles */
.edit-vehicle-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.edit-vehicle-form {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
}

/* Add back the rental rate container styles */
.rental-rate-container {
  margin: 10px 0;
}

.rental-rate-inputs {
  display: flex;
  align-items: center;
  gap: 10px;
}

.dollar-sign {
  font-size: 16px;
  color: #2c3e50;
}

.rental-rate-input {
  width: 100px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.rental-period-select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  cursor: pointer;
}

/* Add back the edit form button styles */
.edit-form-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.edit-form-buttons button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.edit-form-buttons button:first-child {
  background-color: #3498db;
  color: white;
}

.edit-form-buttons button:last-child {
  background-color: #e74c3c;
  color: white;
}

/* Add back the vehicle tile styles */
.vehicle-card {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  transition: all 0.3s ease;
}

.vehicle-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.vehicle-card.expanded {
  transform: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.vehicle-images-section,
.vehicle-damage-section,
.vehicle-documents-section {
  margin: 20px 0;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.images-grid,
.documents-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 15px;
  margin: 15px 0;
}

.image-container,
.document-container {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
}

.vehicle-thumbnail {
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 4px;
}

.remove-image-btn,
.remove-document-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(231, 76, 60, 0.9);
  color: white;
  border: none;
  border-radius: 4px;
  padding: 4px 8px;
  cursor: pointer;
  font-size: 12px;
}

.remove-image-btn:hover,
.remove-document-btn:hover {
  background-color: rgba(192, 57, 43, 1);
}

.document-container a {
  display: block;
  padding: 10px;
  color: #3498db;
  text-decoration: none;
}

.document-container a:hover {
  text-decoration: underline;
}

.license-plate-group {
  margin-bottom: 15px;
}

.license-plate-inputs {
  display: flex;
  gap: 10px;
  align-items: center;
}

.license-plate-input {
  flex: 2;  /* Takes up more space than the state dropdown */
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.state-select {
  flex: 1;  /* Takes up less space than the license plate input */
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}

.license-plate-input:focus,
.state-select:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.rental-dates-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
  margin: 15px 0;
}

.date-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.production-status-container {
  margin: 20px 0;
  padding-top: 15px;
  border-top: 1px solid #eee;
}

.production-status-container .remove-production-button {
  width: 100%;
  padding: 10px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.production-status-container .remove-production-button:hover {
  background-color: #c0392b;
}

.vehicle-preview-image {
  width: 100%;
  height: 150px;
  margin-bottom: 15px;
  border-radius: 8px;
  overflow: hidden;
}

.vehicle-preview-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.vehicle-preview-image:hover img {
  transform: scale(1.05);
}

.image-container {
  position: relative;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 8px;
  cursor: move;  /* Add cursor indicator */
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.image-container:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.image-container.dragging {
  opacity: 0.5;
  transform: scale(0.95);
}

.image-container.drag-over {
  border: 2px dashed #3498db;
}