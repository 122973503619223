.page-container {
  display: flex;
  height: 100vh;
  background-color: #f3f4f6;
  }
  
  .content-wrapper {
  flex: 1;
  padding: 20px;
  overflow: hidden;
  }
  
  .messages-layout {
  display: flex;
  height: calc(100vh - 40px);
  gap: 15px;
  padding: 15px;
  background: #e5e7eb;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  }
  
  .group-navigation {
  width: 72px;
  background: #2c3e50;
  border-radius: 12px;
  padding: 20px 0;
  }
  
  .group-nav-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  }
  
  .group-nav-item {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  position: relative;
  transition: all 0.3s ease;
  }
  
  .group-nav-item:hover {
  background: rgba(255, 255, 255, 0.1);
  }
  
  .group-nav-item.active {
  background: #3498db;
  }
  
  .group-nav-icon {
  font-size: 20px;
  }
  
  .group-nav-tooltip {
  position: absolute;
  left: 100%;
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  font-size: 12px;
  color: white;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  margin-left: 10px;
  z-index: 100;
  }
  
  .group-nav-item:hover .group-nav-tooltip {
  opacity: 1;
  visibility: visible;
  }
  
  .group-nav-divider {
  width: 32px;
  height: 1px;
  background: rgba(255, 255, 255, 0.1);
  margin: 10px 0;
  }
  
  .messages-container {
  flex: 1;
  display: flex;
  gap: 15px;
  overflow: hidden;
  }
  
  .left-panel, .chat-area-container {
  background: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  }
  
  .left-panel {
  width: 320px;
  display: flex;
  flex-direction: column;
  }
  
  .chat-list-header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e1e4e8;
  }
  
  .chat-list-header h2 {
  margin: 0;
  font-size: 24px;
  color: #2c3e50;
  }
  
  .new-chat-button {
  padding: 8px 16px;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  transition: background-color 0.3s ease;
  }
  
  .new-chat-button:hover {
  background: #2980b9;
  }
  
  .search-bar {
  padding: 15px;
  border-bottom: 1px solid #e1e4e8;
  }
  
  .search-bar input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #e1e4e8;
  border-radius: 8px;
  font-size: 14px;
  outline: none;
  transition: border-color 0.3s ease;
  }
  
  .search-bar input:focus {
  border-color: #3498db;
  }
  
  .conversations-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
  overflow-y: auto;
  }
  
  .conversation-item {
  cursor: pointer;
  padding: 12px;
  border-bottom: 1px solid #e0e0e0;
  transition: background-color 0.2s;
  }
  
  .conversation-item:hover {
  background-color: #f5f5f5;
  }
  
  .conversation-item.selected {
  background-color: #e3f2fd;
  }
  
  .conversation-item-content {
  display: flex;
  align-items: center;
  padding: 12px;
  gap: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  border-radius: 8px;
  }
  
  .conversation-item.priority-important {
  border-left-color: #ffc107;
  }
  
  .conversation-item.priority-urgent {
  border-left-color: #dc3545;
  }
  
  .conversation-item.pinned {
  background-color: rgba(0, 123, 255, 0.05);
  border-left: 3px solid #007bff;
  }
  
  .conversation-item.pinned::before {
  content: '\f08d';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  top: 8px;
  right: 8px;
  color: #1565c0;
  transform: rotate(45deg);
  font-size: 14px;
  }
  
  .conversation-avatar {
  position: relative;
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  
  .avatar-image {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
  }
  
  .avatar-image.group-avatar {
  border-radius: 12px;
  width: 48px;
  height: 48px;
  max-width: 48px;
  max-height: 48px;
  flex-shrink: 0;
  }
  
  .avatar-placeholder {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #e2e8f0;
  color: #64748b;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 500;
  }
  
  .avatar-placeholder.group-avatar {
  border-radius: 12px;
  background-color: #f1f5f9;
  width: 48px;
  height: 48px;
  max-width: 48px;
  max-height: 48px;
  flex-shrink: 0;
  }
  
  .avatar-placeholder.group-avatar i {
  font-size: 24px;
  color: #64748b;
  }
  
  .conversation-info {
  flex: 1;
  min-width: 0;
  }
  
  .conversation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  }
  
  .conversation-name {
  font-weight: 600;
  color: #212529;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  
  .last-message-time {
  font-size: 0.8rem;
  color: #212529;
  white-space: nowrap;
  }
  
  .last-message {
  color: #6c757d;
  font-size: 0.9rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  max-height: 1.2em;
  }
  
  .last-message div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  }
  
  .no-messages {
  font-style: italic;
  color: #adb5bd;
  }
  
  .chat-area-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  }
  
  .chat-header {
  padding: 20px;
  border-bottom: 1px solid #e1e4e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  }
  
  .chat-header-info h3 {
  margin: 0;
  color: #2c3e50;
  font-size: 18px;
  }
  
  .chat-status {
  font-size: 13px;
  color: #6c757d;
  }
  
  .chat-header-actions {
  display: flex;
  align-items: center;
  gap: 10px;
  }
  
  .search-icon {
  cursor: pointer;
  padding: 8px;
  color: #666;
  transition: color 0.2s ease;
  }
  
  .search-icon:hover {
  color: #333;
  }
  
  .mobile-search-input {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background: white;
  padding: 0 15px;
  transform: translateY(-100%);
  transition: transform 0.3s ease;
  z-index: 30;
  }
  
  .mobile-search-input.active {
  transform: translateY(0);
  display: flex;
  align-items: center;
  }
  
  .mobile-search-input input {
  flex: 1;
  padding: 8px;
  border: none;
  outline: none;
  font-size: 14px;
  }
  
  .mobile-search-input .close-search {
  padding: 8px;
  color: #666;
  cursor: pointer;
  }
  
  .icon-button {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: none;
  background: #f8f9fa;
  color: #2c3e50;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  }
  
  .icon-button:hover {
  background: #e9ecef;
  }
  
  .messages-list {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  padding-bottom: 100px;
  max-height: calc(100vh - 172px - 120px);
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
  }
  
  .message-item {
  display: flex;
  gap: 12px;
  margin-bottom: 16px;
  width: 100%;
  }
  
  .message-item.received {
  justify-content: flex-start;
  flex-direction: row;
  }
  
  .message-item.sent {
  justify-content: flex-end;
  flex-direction: row-reverse;
  }
  
  .message-content {
  max-height: 350px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
  width: 450px;
  max-width: 75%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  }
  
  .message-content::-webkit-scrollbar {
  width: 6px;
  }
  
  .message-content::-webkit-scrollbar-track {
  background: transparent;
  }
  
  .message-content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  }
  
  .message-item.sent .message-content {
  align-items: flex-end;
  background-color: #e3f2fd;
  color: #212529;
  border-radius: 12px 12px 0 12px;
  margin-left: auto;
  }
  
  .message-item.received .message-content {
  align-items: flex-start;
  background-color: #f8f9fa;
  color: #212529;
  border-radius: 12px 12px 12px 0;
  margin-right: auto;
  }
  
  .message-sender-info {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 4px;
  width: 100%;
  }
  
  .message-item.sent .message-sender-info {
  justify-content: flex-end;
  }
  
  .message-item.received .message-sender-info {
  justify-content: flex-start;
  }
  
  .sender-name {
  font-weight: 600;
  color: #212529;
  }
  
  .message-item.sent .sender-name {
  color: #1565c0;
  }
  
  .sender-role {
  font-size: 0.8rem;
  color: #6c757d;
  background-color: #e9ecef;
  padding: 2px 6px;
  border-radius: 4px;
  }
  
  .message-text {
  padding: 12px;
  border-radius: inherit;
  word-break: break-word;
  }
  
  .message-text p {
  margin: 0;
  line-height: 1.4;
  }
  
  .message-text p:not(:last-child) {
  margin-bottom: 8px;
  }
  
  .message-text p:empty {
  display: none;
  }
  
  .message-item.sent .message-text {
  background-color: inherit;
  color: #212529;
  }
  
  .message-item.received .message-text {
  background-color: inherit;
  color: #212529;
  }
  
  .message-text.important {
  background-color: rgba(255, 193, 7, 0.1);
  }
  
  .message-text.urgent {
  background-color: rgba(220, 53, 69, 0.1);
  }
  
  .message-time {
  font-size: 0.75rem;
  color: #212529;
  margin-top: 4px;
  padding: 0 12px 8px;
  }
  
  .message-input-container {
  position: sticky;
  bottom: 0;
  background: white;
  padding: 10px;
  border-top: 1px solid #e5e7eb;
  z-index: 10;
  }
  
  .selected-files {
  padding: 12px;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 12px;
  min-height: 80px;
  max-height: 120px;
  overflow-y: auto;
  }
  
  .selected-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  background-color: white;
  border-radius: 6px;
  margin-bottom: 8px;
  border: 1px solid #e1e4e8;
  min-height: 60px;
  }
  
  .selected-file:last-child {
  margin-bottom: 0;
  }
  
  .selected-file span {
  font-size: 1rem;
  color: #495057;
  margin-right: 12px;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  
  .remove-file {
  background: none;
  border: none;
  color: #dc3545;
  cursor: pointer;
  padding: 8px;
  font-size: 1.2rem;
  line-height: 1;
  opacity: 0.7;
  transition: opacity 0.2s;
  flex-shrink: 0;
  }
  
  .remove-file:hover {
  opacity: 1;
  }
  
  .upload-progress {
  font-size: 0.9rem;
  color: #0d6efd;
  margin-left: auto;
  padding: 0 12px;
  flex-shrink: 0;
  }
  
  .file-errors {
  background-color: #fff3f3;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px;
  }
  
  .error-message {
  color: #dc3545;
  font-size: 0.9rem;
  margin-bottom: 4px;
  }
  
  .error-message:last-child {
  margin-bottom: 0;
  }
  
  .attach-button {
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  padding: 8px;
  font-size: 1.1rem;
  transition: color 0.2s;
  position: relative;
  }
  
  .attach-button:hover {
  color: #0d6efd;
  }
  
  .attach-button:disabled {
  color: #adb5bd;
  cursor: not-allowed;
  }
  
  .attach-button::after {
  content: attr(data-count);
  position: absolute;
  top: 0;
  right: 0;
  background-color: #0d6efd;
  color: white;
  font-size: 0.7rem;
  padding: 2px 4px;
  border-radius: 10px;
  display: none;
  }
  
  .attach-button[data-count]:not([data-count="0"])::after {
  display: block;
  }
  
  .message-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  }
  
  .send-button {
  padding: 8px 16px;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
  }
  
  .send-button:hover {
  background: #2980b9;
  }
  
  .send-button:disabled {
  background: #e1e4e8;
  cursor: not-allowed;
  }
  
  .modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  }
  
  .modal-content {
  background: white;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow-y: auto;
  }
  
  .modal-header {
  padding: 20px;
  border-bottom: 1px solid #e1e4e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  }
  
  .modal-header h2 {
  margin: 0;
  font-size: 20px;
  color: #2c3e50;
  }
  
  .close-button {
  background: transparent;
  border: none;
  font-size: 24px;
  color: #6c757d;
  cursor: pointer;
  padding: 4px;
  line-height: 1;
  }
  
  .modal-body {
  padding: 20px;
  }
  
  .input-field {
  width: 100%;
  padding: 10px;
  border: 1px solid #e1e4e8;
  border-radius: 6px;
  margin-bottom: 20px;
  font-size: 14px;
  }
  
  select.input-field {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
  }
  
  .members-selection {
  margin-bottom: 20px;
  }
  
  .members-selection h4 {
  margin-bottom: 12px;
  color: #2c3e50;
  }
  
  .modal-footer {
  padding: 20px;
  border-top: 1px solid #e1e4e8;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  }
  
  .cancel-button {
  padding: 8px 16px;
  border: 1px solid #e1e4e8;
  background: white;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  color: #6c757d;
  }
  
  .create-button {
  padding: 8px 16px;
  background: #3498db;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  }
  
  .create-button:disabled {
  background: #e1e4e8;
  cursor: not-allowed;
  }
  
  .no-chat-selected {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6c757d;
  font-size: 18px;
  }
  
  .right-panel {
  width: 280px;
  }
  
  .right-panel-header {
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #e1e4e8;
  }
  
  .profile-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  }
  
  .member-count {
  font-size: 13px;
  color: #6c757d;
  }
  
  .chat-details {
  padding: 20px;
  }
  
  .details-section {
  margin-bottom: 24px;
  }
  
  .details-section h4 {
  color: #2c3e50;
  margin-bottom: 12px;
  font-size: 16px;
  }
  
  .members-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  }
  
  .member-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  }
  
  .member-item:hover {
  background: #fff;
  }
  
  .member-info {
  flex: 1;
  min-width: 0;
  }
  
  .member-name {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #2c3e50;
  }
  
  .member-role {
  display: block;
  font-size: 12px;
  color: #6c757d;
  }
  
  .right-panel-placeholder {
  padding: 20px;
  text-align: center;
  color: #6c757d;
  }
  
  .details-tile {
  width: 300px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  }
  
  .details-header {
  padding: 20px;
  border-bottom: 1px solid #e1e4e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  }
  
  .details-header h3 {
  margin: 0;
  font-size: 18px;
  color: #2c3e50;
  }
  
  .close-details-btn {
  background: none;
  border: none;
  color: #64748b;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: background-color 0.2s;
  }
  
  .close-details-btn:hover {
  background-color: #f1f5f9;
  }
  
  .details-content {
  padding: 20px;
  overflow-y: auto;
  flex: 1;
  }
  
  .details-section {
  margin-bottom: 24px;
  }
  
  .details-section:last-child {
  margin-bottom: 0;
  }
  
  .details-section h4 {
  margin: 0 0 12px 0;
  font-size: 14px;
  color: #64748b;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  }
  
  .details-section p {
  margin: 0;
  color: #334155;
  font-size: 14px;
  }
  
  .participants-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
  }
  
  .participant-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  border-radius: 8px;
  transition: background-color 0.2s;
  }
  
  .participant-item:hover {
  background-color: #f8fafc;
  }
  
  .participant-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  background: #e2e8f0;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  
  .participant-avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  }
  
  .avatar-initials {
  color: #475569;
  font-weight: 600;
  font-size: 14px;
  }
  
  .participant-info {
  flex: 1;
  min-width: 0;
  }
  
  .participant-name {
  font-weight: 500;
  color: #334155;
  font-size: 14px;
  margin-bottom: 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  
  .participant-department {
  color: #64748b;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  }
  
  .chat-participants {
  display: flex;
  flex-direction: column;
  gap: 8px;
  }
  
  .participant-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 4px;
  border-radius: 8px;
  transition: background-color 0.2s ease;
  }
  
  .participant-item:hover {
  background-color: #f3f4f6;
  }
  
  .participant-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #4b5563;
  font-weight: 500;
  }
  
  .participant-info {
  display: flex;
  flex-direction: column;
  flex: 1;
  }
  
  .participant-name {
  font-size: 14px;
  font-weight: 500;
  color: #1f2937;
  }
  
  .participant-department {
  font-size: 12px;
  color: #6b7280;
  }
  
  .message-attachments {
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 100%;
  }
  
  .attachment-item {
  cursor: pointer;
  max-width: 300px;
  flex: 0 0 auto;
  }
  
  .image-attachment {
  max-width: 250px;
  border-radius: 8px;
  overflow: hidden;
  }
  
  .image-attachment img {
  width: 100%;
  height: auto;
  display: block;
  transition: opacity 0.2s;
  }
  
  .image-attachment img:hover {
  opacity: 0.9;
  }
  
  .file-attachment {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  transition: background-color 0.2s;
  }
  
  .file-attachment:hover {
  background-color: rgba(0, 0, 0, 0.08);
  }
  
  .file-attachment i {
  font-size: 1.2rem;
  color: #1565c0;
  }
  
  .file-name {
  font-size: 0.9rem;
  color: #1565c0;
  font-weight: 500;
  }
  
  .file-size {
  font-size: 0.8rem;
  color: #666;
  margin-left: auto;
  }
  
  .details-search {
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
  }
  
  .search-input {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f3f4f6;
  border-radius: 8px;
  padding: 8px 12px;
  }
  
  .search-input i {
  color: #6b7280;
  margin-right: 8px;
  }
  
  .search-input input {
  flex: 1;
  border: none;
  background: none;
  outline: none;
  font-size: 14px;
  color: #374151;
  }
  
  .search-input input::placeholder {
  color: #9ca3af;
  }
  
  .clear-search {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #6b7280;
  display: flex;
  align-items: center;
  justify-content: center;
  }
  
  .clear-search:hover {
  color: #374151;
  }
  
  .details-actions {
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
  }
  
  .pin-chat-button {
  width: 100%;
  padding: 8px 12px;
  margin: 5px 0;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #666;
  transition: all 0.2s ease;
  }
  
  .pin-chat-button:hover {
  background-color: #f5f5f5;
  border-color: #d0d0d0;
  }
  
  .pin-chat-button i {
  font-size: 14px;
  }
  
  .pin-chat-button .fa-trash-alt {
  transition: color 0.2s ease;
  }
  
  .pin-chat-button:hover .fa-trash-alt {
  color: #ff4d4d;
  }
  
  .pin-chat-button:hover:has(.fa-trash-alt) {
  background-color: #ffebeb;
  border-color: #ff4d4d;
  color: #ff4d4d;
  }
  
  .pin-chat-button:active:has(.fa-trash-alt) {
  background-color: #ff4d4d;
  border-color: #ff4d4d;
  color: white;
  }
  
  .group-avatar-section {
  padding: 16px;
  border-bottom: 1px solid #e5e7eb;
  }
  
  .group-avatar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 8px;
  }
  
  .group-avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #e5e7eb;
  }
  
  .group-avatar-placeholder {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #f3f4f6;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #e5e7eb;
  }
  
  .group-avatar-placeholder i {
  font-size: 40px;
  color: #9ca3af;
  }
  
  .change-avatar-button {
  padding: 6px 12px;
  background-color: #f3f4f6;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  color: #374151;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;
  }
  
  .change-avatar-button:hover {
  background-color: #e5e7eb;
  }
  
  .conversation-item.pinned::before {
  content: '\f08d';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  position: absolute;
  top: 8px;
  right: 8px;
  color: #1565c0;
  transform: rotate(45deg);
  font-size: 14px;
  }
  
  .conversation-item.pinned {
  background-color: rgba(0, 123, 255, 0.05);
  border-left: 3px solid #007bff;
  }
  
  .pin-indicator {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #007bff;
  font-size: 12px;
  transform: rotate(45deg);
  }
  
  .pin-indicator i {
  display: block;
  }
  
  .chat-details .delete-chat-button {
  width: 100%;
  padding: 8px 12px;
  margin: 5px 0;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  color: #666;
  transition: all 0.2s ease;
  }
  
  .chat-details .delete-chat-button:hover {
  background-color: #ffebeb;
  border-color: #ff4d4d;
  color: #ff4d4d;
  }
  
  .chat-details .delete-chat-button:active {
  background-color: #ff4d4d;
  border-color: #ff4d4d;
  color: white;
  }
  
  .chat-details .delete-chat-button i {
  font-size: 14px;
  }
  
  .conversation-item.ended {
  background-color: #fffde7;
  }
  
  .conversation-item.ended .conversation-name::after {
  content: ' (Thread Ended)';
  color: #9e9e9e;
  font-style: italic;
  font-size: 0.9em;
  }
  
  .conversation-item.ended .conversation-info {
  opacity: 0.8;
  }

  /* Base styles for back button - hide by default on all screens */
  .back-button {
    display: none;
    -webkit-tap-highlight-color: transparent;
  }

  @media (max-width: 767px) {
    /* Show back button only on mobile */
    .back-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border: none;
      background: none;
      color: #666;
      cursor: pointer;
      padding: 8px;
      margin-right: 8px;
    }

    .back-button:hover {
      color: #3498db;
    }

    .back-button:active {
      background: transparent;
      color: #2980b9;
    }
  }

  @media (max-width: 767px) {
    /* Main container */
    .page-container {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: hidden;
    }

    .content-wrapper {
      height: 100%;
      overflow: hidden;
    }

    .messages-layout {
      height: 100vh;
      padding: 0;
      margin: 0;
      overflow: hidden;
    }

    .messages-container {
      height: 100%;
      overflow: hidden;
    }

    /* Chat area */
    .chat-area-container {
      width: 100%;
      height: calc(100vh - 60px);
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;
      transform: translateX(100%);
      transition: transform 0.3s ease;
      background: white;
    }

    /* Header stays at top */
    .chat-header {
      flex-shrink: 0;
      padding: 10px;
      background: white;
      border-bottom: 1px solid #e1e4e8;
      z-index: 10;
    }

    /* Messages area scrolls */
    .messages-list {
      flex: 1;
      overflow-y: auto;
      padding: 10px;
      -webkit-overflow-scrolling: touch;
    }

    /* Input area stays at bottom */
    .message-input-container {
      flex-shrink: 0;
      background: white;
      border-top: 1px solid #e1e4e8;
      padding: 10px;
      z-index: 10;
    }

    /* Adjust ReactQuill container */
    .message-input-container .quill {
      max-height: 150px;
      overflow-y: auto;
    }

    .ql-container {
      max-height: 100px;
    }

    /* Hide sidebar and left panel by default */
    .left-panel, 
    .group-navigation {
      display: none;
    }

    /* Update left panel display */
    .left-panel {
      display: block;
      width: 100%;
      height: calc(100vh - 60px);
      position: fixed;
      top: 60px;
      left: 0;
      background: white;
      z-index: 20;
      overflow-y: auto;
      transition: transform 0.3s ease;
    }

    /* Add class for when conversation is selected */
    .left-panel.conversation-selected {
      transform: translateX(-100%);
    }

    /* Update chat area container */
    .chat-area-container {
      display: flex;
      height: calc(100vh - 60px);
      top: 60px;
      transform: translateX(100%);
      transition: transform 0.3s ease;
    }

    /* Show chat area when active */
    .chat-area-container.active {
      transform: translateX(0);
    }

    /* Keep group navigation hidden */
    .group-navigation {
      display: none;
    }

    /* Hide chat area by default */
    .chat-area-container {
      display: none;
    }

    /* Show chat area when conversation is selected */
    .chat-area-container.active {
      display: flex;
      height: calc(100vh - 60px);
      top: 60px;
    }

    /* Hide the original search input in mobile view */
    .search-filters {
      display: none;  /* This hides both search and department filter */
    }

    /* If you want to keep department filter visible but hide search */
    .search-filters .search-bar {
      display: none;
    }

    /* Ensure the mobile search input in header is properly styled */
    .mobile-search-input {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: white;
      padding: 0 15px;
      transform: translateY(-100%);
      transition: transform 0.3s ease;
      z-index: 30;  /* Ensure it appears above other elements */
    }

    .mobile-search-input.active {
      transform: translateY(0);
      display: flex;
      align-items: center;
    }

    /* Target all search-related elements specifically */
    .search-filters,
    .search-filters .search-bar,
    .search-filters input,
    .conversations-list .search-bar,
    .conversations-list input[type="text"],
    .left-panel .search-bar,
    .left-panel input[type="text"] {
      display: none !important;
    }

    /* Hide the department filter as well */
    .department-filter,
    select.department-filter {
      display: none !important;
    }

    /* Keep only the mobile search in header */
    .mobile-search-input {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background: white;
      padding: 0 15px;
      transform: translateY(-100%);
      transition: transform 0.3s ease;
      z-index: 30;
    }

    .mobile-search-input.active {
      transform: translateY(0);
      display: flex;
      align-items: center;
    }

    /* Hide the details search input */
    .details-search,
    .details-search .search-input,
    .details-tile .details-search {
      display: none !important;
    }

    /* Add these styles for the details tile */
    .details-tile {
      position: fixed;
      top: 60px;
      right: 0;
      width: 100%;
      height: calc(100vh - 60px);
      background: white;
      z-index: 40;
      transform: translateX(100%);
      transition: transform 0.3s ease;
      display: block !important; /* Ensure it's always in the DOM */
      visibility: visible !important;
    }

    /* When details should be shown */
    .details-tile.show {
      transform: translateX(0);
    }

    /* Update chat header to accommodate back button */
    .chat-header {
      display: flex;
      align-items: center;
    }

    .left-panel {
      transform: translateX(0);
    }

    .left-panel.hidden {
      transform: translateX(-100%);
    }

    .chat-area-container {
      transform: translateX(100%);
    }

    .chat-area-container.visible {
      transform: translateX(0);
    }
  }

  /* =========================
        TABLET STYLES (768px to 1023px)
   ========================= */
  @media (min-width: 768px) and (max-width: 1023px) {
    /* Makes the left panel and the group navigation hidden on tablet screens  */
    .left-panel, .group-navigation{
      display: none;
    }
  }

  /* Update existing mobile styles and add new ones */
  @media (max-width: 767px) {
    /* Existing styles */
    .messages-layout {
      flex-direction: column;
      height: auto;
      padding: 10px;
      gap: 5px; /* Reduce gap on mobile */
    }

    .messages-container {
      flex-direction: column;
      height: calc(100vh - 60px); /* Account for header */
    }

    /* Improve chat area layout */
    .chat-area-container {
      width: 100%;
      border-radius: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      height: 100%;
    }

    /* Adjust chat header */
    .chat-header {
      padding: 10px;
      border-radius: 0;
      position: sticky;
      top: 0;
      background: white;
      z-index: 10;
    }

    .chat-header-info h3 {
      font-size: 16px;
    }

    .chat-status {
      font-size: 12px;
    }

    /* Adjust messages list */
    .messages-list {
      flex: 1;
      padding: 10px;
      gap: 8px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
    }

    /* Adjust message items */
    .message-item {
      max-width: 85%; /* Make messages slightly wider on mobile */
      margin-bottom: 8px;
    }

    .message-content {
      padding: 8px 12px;
      max-width: 100%;
    }

    /* Adjust message input area */
    .message-input-container {
      padding: 10px;
      background: white;
      border-top: 1px solid #e1e4e8;
      position: sticky;
      bottom: 0;
      z-index: 10;
    }

    .message-actions {
      padding: 5px 0;
    }

    /* Adjust file attachments display */
    .selected-files {
      max-height: 100px;
      overflow-y: auto;
      padding: 5px;
    }

    .selected-file {
      padding: 5px;
      font-size: 12px;
    }

    /* Adjust ReactQuill editor for mobile */
    .ql-container {
      max-height: 100px;
      font-size: 14px;
    }

    .ql-toolbar {
      padding: 5px !important;
    }

    /* Improve button touch targets */
    .attach-button,
    .send-button {
      min-width: 40px;
      min-height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    /* Adjust message timestamps and sender info */
    .message-sender-info {
      font-size: 12px;
      margin-bottom: 2px;
    }

    .message-time {
      font-size: 10px;
      margin-top: 2px;
    }

    /* Handle image attachments better */
    .message-attachments {
      margin-top: 5px;
    }

    .image-attachment {
      max-width: 200px;
    }

    .file-attachment {
      padding: 6px 10px;
      font-size: 12px;
    }
  }

  /* Add styles for larger phones and small tablets */
  @media (min-width: 768px) and (max-width: 1023px) {
    .messages-container {
      gap: 10px;
    }

    .chat-area-container {
      flex: 2;
    }

    .message-item {
      max-width: 75%;
    }

    .ql-container {
      max-height: 120px;
    }
  }

  @media (max-width: 767px) {
    /* Update chat area container layout */
    .chat-area-container {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 60px);
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;
      bottom: 0;
    }

    /* Ensure messages list takes available space */
    .messages-list {
      flex: 1;
      overflow-y: auto;
      padding: 10px;
      margin-bottom: 60px; /* Make room for input container */
    }

    /* Fix message input container at bottom */
    .message-input-container {
      position: fixed;
      bottom: 10px; /* Move up by an additional 5px (total 10px from the bottom) */
      left: 0;
      right: 0;
      background: white;
      padding: 10px;
      border-top: 1px solid #e1e4e8;
      max-height: 200px;
      overflow-y: auto;
    }

    /* Adjust ReactQuill container */
    .message-input-container .quill {
      max-height: 120px;
    }

    .ql-container {
      max-height: 80px;
    }

    /* Ensure buttons are visible */
    .message-actions {
      display: flex;
      gap: 10px;
      padding: 5px 0;
      background: white;
    }
  }