.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 12px 24px;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  z-index: 1000;
  animation: fadeIn 0.5s, fadeOut 0.5s 2.5s;
}

/* Success toast style */
.toast.success {
  background-color: #2ecc71;
  color: white;
}

/* Error toast style */
.toast.error {
  background-color: #e74c3c;
  color: white;
}

.toast p {
  margin: 0;
  font-size: 16px;
  color: white;
}

@keyframes fadeIn {
  from {opacity: 0; transform: translate(-50%, 20px);}
  to {opacity: 1; transform: translate(-50%, 0);}
}

@keyframes fadeOut {
  from {opacity: 1; transform: translate(-50%, 0);}
  to {opacity: 0; transform: translate(-50%, 20px);}
}
