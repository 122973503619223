.run-requests {
  padding: 0;
  background-color: transparent;
}

.run-requests h2 {
  margin-bottom: 1.5rem;
  color: #2c3e50;
  font-size: 1.5rem;
}

.run-requests-container {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
}

.run-requests-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

.run-requests-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  min-width: 0;
}

.run-request-card {
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #e9ecef;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.run-request-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.run-request-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.header-content {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.select-checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 16px;
  cursor: default;
}

.select-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.select-checkbox-label {
  font-size: 14px;
  color: #666;
  cursor: pointer;
  user-select: none;
}

.run-request-date {
  color: #666;
  font-size: 0.9rem;
}

.run-request-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.run-request-details p {
  margin: 0;
  line-height: 1.5;
}

.run-request-address, .run-request-description {
  background-color: white;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid #eee;
}

.run-request-photos {
  margin-top: 1rem;
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.run-request-photo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.run-request-photo-grid img {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  cursor: pointer;
  transition: transform 0.2s;
}

.run-request-photo-grid img:hover {
  transform: scale(1.05);
}

.run-request-driver-selection {
  margin-top: 1rem;
}

.run-request-driver-selection select {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 1rem;
  color: #333;
}

.run-request-crew-selection {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #eee;
}

.run-request-crew-header {
  margin-bottom: 1rem;
}

.run-request-crew-search {
  margin: 1rem 0;
}

.run-request-crew-search input {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.run-request-crew-actions {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.run-request-crew-actions button {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  color: #333;
  cursor: pointer;
  transition: all 0.2s;
  font-size: 0.9rem;
}

.run-request-crew-actions button:hover {
  background-color: #f8f9fa;
  border-color: #ccc;
}

.run-request-crew-members-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  max-height: 300px;
  overflow-y: auto;
  padding: 1rem;
  background-color: white;
  border: 1px solid #eee;
  border-radius: 4px;
}

.run-request-crew-member-tile {
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid #e9ecef;
}

.run-request-crew-member-tile:hover {
  background-color: #f0f0f0;
  border-color: #007bff;
}

.run-request-crew-member-tile.selected {
  background-color: #e3f2fd;
  border-color: #007bff;
}

.run-request-crew-member-info {
  flex: 1;
}

.run-request-crew-member-info h4 {
  margin: 0 0 0.5rem 0;
  color: #2c3e50;
  font-size: 1rem;
}

.run-request-crew-member-info p {
  margin: 0.25rem 0;
  color: #666;
  font-size: 0.9rem;
}

.run-request-actions {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #eee;
}

.run-request-accept-button, .run-request-reject-button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  font-size: 1rem;
}

.run-request-accept-button {
  background-color: #28a745;
  color: white;
}

.run-request-accept-button:hover {
  background-color: #218838;
}

.run-request-accept-button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.run-request-reject-button {
  background-color: #dc3545;
  color: white;
}

.run-request-reject-button:hover {
  background-color: #c82333;
}

.run-requests-loading {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.run-requests-no-requests {
  text-align: center;
  padding: 2rem;
  color: #666;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.drivers-list {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  height: fit-content;
  max-height: calc(100vh - 100px);
  position: sticky;
  top: 2rem;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.drivers-header {
  margin-bottom: 1.5rem;
}

.drivers-header h3 {
  margin: 0 0 1rem 0;
  color: #2c3e50;
}

.driver-search {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 1rem;
}

.drivers-grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow-y: auto;
  flex: 1;
}

.driver-card {
  background-color: #f8f9fa;
  border-radius: 4px;
  padding: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.driver-card:hover {
  background-color: #e9ecef;
}

.driver-card h4 {
  margin: 0 0 0.5rem 0;
  color: #2c3e50;
}

.driver-card p {
  margin: 0.25rem 0;
  color: #666;
  font-size: 0.9rem;
}

.error {
  color: #dc3545;
  padding: 1rem;
  background-color: #fff3f3;
  border-radius: 8px;
  text-align: center;
  margin: 1rem 0;
}

.loading {
  text-align: center;
  padding: 2rem;
  color: #666;
}

/* Scrollbar styling */
.run-request-crew-members-grid::-webkit-scrollbar {
  width: 8px;
}

.run-request-crew-members-grid::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.run-request-crew-members-grid::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px;
}

.run-request-crew-members-grid::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}

.crew-members-section {
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.crew-members-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.header-actions {
  display: flex;
  gap: 16px;
  align-items: center;
}

.search-bar {
  display: flex;
  align-items: center;
}

.search-input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 200px;
}

.search-input:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0,123,255,0.25);
}

.assign-button {
  height: 36px;
  padding: 0 16px;
  white-space: nowrap;
}

.crew-members-header h2 {
  margin: 0;
  color: #2c3e50;
  font-size: 1.5rem;
}

.view-toggle {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.view-toggle button {
  padding: 0.75rem 1.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.view-toggle button.active {
  background-color: #007bff;
  color: #fff;
  border-color: #007bff;
}

.select-actions {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.select-actions button {
  padding: 0.5rem 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #fff;
  color: #666;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9rem;
}

.select-actions button:hover {
  background-color: #f8f9fa;
  border-color: #007bff;
  color: #007bff;
}

.members-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1rem;
}

.member-tile {
  background-color: #fff;
  border: 1px solid #e9ecef;
  border-radius: 4px;
  padding: 1rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.member-tile:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-color: #007bff;
}

.member-tile.selected {
  background-color: #e3f2fd;
  border-color: #007bff;
}

.checkbox {
  padding-top: 0.25rem;
}

.member-info {
  flex: 1;
}

.member-info h3 {
  margin: 0 0 0.5rem 0;
  color: #2c3e50;
  font-size: 1rem;
}

.member-info p {
  margin: 0.25rem 0;
  color: #666;
  font-size: 0.9rem;
}

.loading {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.error {
  color: #dc3545;
  padding: 1rem;
  background-color: #fff3f3;
  border-radius: 8px;
  text-align: center;
  margin: 1rem 0;
}

.run-requests {
  padding: 20px;
}

.run-requests h2 {
  margin: 0 0 20px 0;
  color: #2c3e50;
}

.run-requests-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.run-request-item {
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.run-request-item.expanded {
  background-color: #f8f9fa;
}

.run-request-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.run-request-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.run-request-header h3 {
  margin: 0;
  font-size: 1.1em;
  color: #2c3e50;
}

.status {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
  font-weight: 500;
}

.status.pending {
  background-color: #ffeeba;
  color: #856404;
}

.status.approved {
  background-color: #d4edda;
  color: #155724;
}

.status.rejected {
  background-color: #f8d7da;
  color: #721c24;
}

.run-request-info {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  margin-bottom: 10px;
}

.run-request-info p {
  margin: 0;
  font-size: 0.9em;
  color: #6c757d;
}

.run-request-description {
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-top: 10px;
}

.run-request-description p {
  margin: 0;
  font-size: 0.9em;
  color: #6c757d;
  line-height: 1.4;
}

.run-requests-no-requests {
  text-align: center;
  padding: 20px;
  color: #6c757d;
  font-style: italic;
}

.photo-count {
  font-size: 0.9em;
  color: #6c757d;
  padding: 4px 8px;
  background-color: #f8f9fa;
  border-radius: 4px;
  display: inline-block;
  margin-top: 10px;
}

.loading-state,
.error-state {
  text-align: center;
  padding: 40px;
  font-size: 1.1em;
}

.loading-state {
  color: #6c757d;
}

.error-state {
  color: #dc3545;
}

.photo-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  margin-top: 16px;
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
}

.photo-item {
  position: relative;
  padding-top: 100%; /* 1:1 Aspect Ratio */
  overflow: hidden;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.photo-item img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.photo-item:hover img {
  transform: scale(1.05);
}

.expanded-content {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;
}

.assignment-section {
  margin-top: 24px;
}

.assignment-section h4 {
  margin-bottom: 16px;
  color: #333;
}

.crew-assignment {
  background: #fff;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.crew-list {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 16px;
}

.crew-member {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 8px;
  background: #f8f9fa;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.crew-member:hover {
  background: #e9ecef;
}

.crew-member.selected {
  background: #e3f2fd;
}

.member-info {
  display: flex;
  flex-direction: column;
}

.member-name {
  font-weight: 500;
  color: #333;
}

.member-position {
  font-size: 0.9em;
  color: #666;
  margin-top: 4px;
}

.assignment-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #eee;
}

.assign-button {
  background: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
  min-width: 120px;
}

.assign-button:hover {
  background: #0056b3;
}

.assign-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.assign-button.processing {
  background: #ffc107;
  cursor: wait;
}

.assign-button.success {
  background: #28a745;
}

.assign-button.error {
  background: #dc3545;
}

.assign-button.success:hover {
  background: #218838;
}

.assign-button.error:hover {
  background: #c82333;
}

.select-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.select-button:hover {
  background-color: #45a049;
}

.select-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.select-button.selected {
  background-color: #2196F3;
}

.select-checkbox-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  gap: 8px;
}

.select-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.select-checkbox-label {
  font-size: 14px;
  color: #666;
  cursor: pointer;
}
