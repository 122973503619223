/* Ensure all elements fit nicely within the container */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.landing-container {
  font-family: 'Arial', sans-serif;
  color: #333;
  line-height: 1.6;
  width: 100%;
  overflow-x: hidden; /* This prevents horizontal scrolling */
  padding-bottom: 1px; /* Adjust this value as needed */
}

.landing-header {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
}

/* Ensure Navbar Stacks for Smaller Screens */
.top-navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0; /* Remove all padding */
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;
  width: 100%; /* Ensure full width */
}

/* If you still want some vertical padding, you can add it like this: */
.top-navbar {
  padding: 0.5rem 0; /* This adds 8px top and bottom padding */
}

.logo-title-container {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  padding-left: 5%; /* Keep left padding for logo */
}

.logo-image {
  width: 80px;
  height: 80px;
  margin-right: 0.5rem;
  border-radius: 50%;
  object-fit: cover;
}

.landing-header h1 {
  margin: 0;
  color: #131a22;
  font-size: 2.5rem;
  font-weight: bold;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  padding-right: 5%;
}

.nav-link {
  text-decoration: none;
  color: #2c3e50;
  font-weight: 500;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #3498db;
}

.nav-button {
  padding: 10px 24px;
  border-radius: 4px;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.3s ease;
  font-size: 1rem;
  min-width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.nav-button:hover {
  background-color: #2980b9;
  border-color: #2980b9;
}

.nav-button:not(.login) {
  background-color: #3498db;
  color: white;
  border: 2px solid #3498db;
}

.nav-button:not(.login):hover {
  background-color: #2980b9;
  border-color: #2980b9;
}

.nav-button.login {
  background-color: transparent;
  color: #3498db;
  border: 2px solid #3498db;
}

.nav-button.login:hover {
  background-color: #3498db;
  color: white;
}

@media (max-width: 768px) {
  .top-navbar {
    flex-direction: column;
    align-items: center;
    padding: 1rem 0; /* Remove horizontal padding for mobile */
  }

  .logo-title-container {
    margin-bottom: 1rem;
    margin-right: 0;
    padding-left: 0; /* Remove left padding on mobile */
  }

  .landing-header h1 {
    font-size: 1.8rem;
  }

  .logo-image {
    width: 60px;
    height: 60px;
  }

  .nav-links {
    justify-content: center;
    width: 100%;
    padding-right: 0; /* Remove right padding on mobile */
  }

  .nav-link, .nav-button {
    margin: 0.5rem;
  }

  .nav-links {
    gap: 1rem;
  }
  
  .nav-button {
    padding: 8px 20px;
    font-size: 0.9rem;
    min-width: 110px;
  }
}

.landing-main {
  max-width: 100%;
  margin: 0;
  padding: 180px 0 3rem;
  overflow-x: hidden; /* This prevents horizontal scrolling */
}

/* Optimize Hero Section for Mobile */
.hero {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  padding: 4rem 0;
  overflow: hidden;
  background: linear-gradient(135deg, #f5f5f5 0%, #e0e0e0 100%);
  margin-left: 0;
  margin-right: 0;
}

.hero-content {
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero-text {
  flex: 1;
  text-align: left;
  padding-right: 2rem;
}

.hero-image {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.hero h2 {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 1rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.hero p {
  font-size: 1.2rem;
  color: #34495e;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.hero-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  object-fit: contain;
  max-height: 500px;
  width: auto;
}

@media (max-width: 768px) {
  .hero {
    flex-direction: column;
    text-align: center;
    padding: 2rem 1rem;
  }

  .hero-content {
    flex-direction: column;
    text-align: center;
  }

  .hero-text {
    padding-right: 0;
    margin-bottom: 2rem;
  }

  .hero h2 {
    font-size: 2rem;
  }

  .hero p {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .hero-image {
    justify-content: center;
    width: 100%;
  }

  .hero-image img {
    max-height: 300px;
    width: auto;
    margin: 0 auto;
  }

  .landing-main {
    padding-top: 220px;
  }
}

/* Grid Adjustments for Feature Section */
.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding: 2rem 5%;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.feature-item {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.feature-item i {
  font-size: 2.5rem;
  color: #3498db;
  margin-bottom: 1rem;
}

.feature-item h4 {
  font-size: 1.2rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.feature-item p {
  font-size: 0.9rem;
  color: #34495e;
}

@media (max-width: 768px) {
  .feature-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 1.5rem 5%;
  }

  .feature-item {
    padding: 1.5rem;
  }
}

/* Footer Layout */
.landing-footer {
  background-color: #2c3e50;
  color: #ecf0f1;
  padding: 3rem 0 3rem;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 5px; /* Adjust this value as needed */
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5%;
  box-sizing: border-box;
}

.footer-columns {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.footer-column {
  display: flex;
  gap: 2rem;
}

.footer-section {
  flex: 1;
  margin-bottom: 2rem;
  min-width: 150px;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 0.5rem;
  position: relative;
  padding-left: 1.2em;
}

.footer-section ul li::before {
  content: '•';
  position: absolute;
  left: 0;
  color: #3498db; /* Adjust color as needed */
  font-size: 1.2em;
  line-height: 1;
}

.footer-section a {
  color: #ecf0f1;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-section a:hover {
  color: #3498db;
}

/* ... (rest of the footer styles remain the same) ... */

/* Call-to-Action Button Tweaks */
.cta-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  font-weight: bold;
  color: #ffffff;
  background-color: #3498db;
  text-decoration: none;
  border-radius: 30px;
  transition: all 0.3s ease;
  border: 2px solid #3498db;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.cta-button:hover {
  background-color: #2980b9;
  border-color: #2980b9;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

.cta-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 768px) {
  .cta-button {
    font-size: 1rem;
    padding: 0.6rem 1.2rem;
    width: 100%;
    text-align: center;
  }
}

/* Section Titles and Text Adjustments */
.section-title {
  font-size: 3rem;
  color: #2c3e50;
  margin-bottom: 2rem;
  text-align: center;
}

.centered-title {
  text-align: center;
}

@media (max-width: 768px) {
  .section-title {
    font-size: 2.5rem;
  }
}

.streamline-section .section-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.section-subtitle {
  text-align: center;
  font-size: 1.2rem;
  color: #34495e;
  max-width: 800px;
  margin: 0 auto 2rem;
  padding: 0 1rem;
}

@media (max-width: 768px) {
  .streamline-section .section-title {
    font-size: 2rem;
  }

  .streamline-section .section-subtitle {
    font-size: 1rem;
  }
}

/* Ensure the Images Don't Overflow */
img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* Margin Between Sections */
.section-background {
  margin: 2rem 0;
  padding: 4rem 5%; /* Add horizontal padding here instead of negative margins */
  background: linear-gradient(135deg, #f5f5f5 0%, #e0e0e0 100%);
}

/* Feature Tiles */
.feature-tiles {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 2rem;
  margin-top: 3rem;
}

.feature-tile {
  width: calc(33.333% - 2rem);
  min-width: 250px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-tile:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.feature-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.feature-tile h3 {
  font-size: 1.5rem;
  color: #2c3e50;
  margin: 1rem 0;
  padding: 0 1rem;
}

.feature-tile p {
  font-size: 1rem;
  color: #34495e;
  padding: 0 1rem 1rem;
}

@media (max-width: 768px) {
  .feature-tile {
    width: 100%;
    margin-bottom: 2rem;
  }
}

/* Remove these styles as they're no longer needed */
.menu-toggle,
.mobile-menu {
  display: none;
}

.footer-logo {
  width: 150px;  /* Increased from 100px */
  height: 150px; /* Increased from 100px */
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1rem;
}

.footer-logo-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 2rem; /* Add some space below the logo section */
}

.footer-title {
  font-size: 2rem; /* Increase the font size of the title */
  color: #ecf0f1;
  margin-top: 1rem; /* Add some space between the logo and the title */
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
  }

  .footer-logo-section {
    order: -1;
    margin-bottom: 2rem;
  }

  .footer-columns {
    width: 100%;
  }

  .footer-column {
    width: 100%;
    justify-content: space-between;
  }

  .footer-section {
    flex: 0 0 calc(50% - 1rem);
    margin-bottom: 1.5rem;
  }

  .footer-section h4 {
    margin-bottom: 0.5rem;
  }

  .footer-section ul {
    margin-bottom: 0;
  }

  .footer-section p {
    margin-bottom: 0.25rem;
  }
}

.footer-bottom {
  text-align: center;
  margin-top: 2rem;
  padding-top: 1rem;
  padding-bottom: 20px;
  border-top: 1px solid #34495e;
}

.footer-bottom p {
  color: #ecf0f1; /* This ensures the copyright text has the same color as other footer text */
}

.footer-legal {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.footer-legal span,
.footer-legal a {
  color: #ecf0f1;
  text-decoration: none;
}

.footer-legal a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .footer-legal {
    flex-direction: row;
    gap: 0.5rem;
  }
}

.about {
  margin-bottom: 4rem;
  position: relative;
  overflow: hidden;
}

.about .section-background {
  background: linear-gradient(135deg, #f5f5f5 0%, #e0e0e0 100%);
  padding: 4rem 0;
  margin: 0 -5%; /* Extend beyond the container */
}

.about p {
  text-align: center;
  font-size: 1.2rem;
  color: #34495e;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 5%;
  line-height: 1.6;
}

@media (max-width: 768px) {
  .about .section-background {
    padding: 3rem 0;
  }

  .about p {
    font-size: 1rem;
  }
}

.features {
  margin-bottom: 4rem;
  position: relative;
  overflow: hidden;
}

.features .section-background {
  background: linear-gradient(135deg, #f5f5f5 0%, #e0e0e0 100%);
  padding: 4rem 0;
  margin: 0 -5%; /* Extend beyond the container */
}

.feature-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  padding: 2rem 5%;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.feature-item {
  background-color: #ffffff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.feature-item i {
  font-size: 2.5rem;
  color: #3498db;
  margin-bottom: 1rem;
}

.feature-item h4 {
  font-size: 1.2rem;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.feature-item p {
  font-size: 0.9rem;
  color: #34495e;
}

@media (max-width: 768px) {
  .features .section-background {
    padding: 3rem 0;
  }

  .feature-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
    padding: 1.5rem 5%;
  }

  .feature-item {
    padding: 1.5rem;
  }
}

.streamline-section {
  margin-bottom: 4rem;
}

.streamline-section .section-background {
  background: linear-gradient(135deg, #f5f5f5 0%, #e0e0e0 100%);
  padding: 4rem 0;
  text-align: center;
}

/* ... (rest of the styles remain the same) ... */

.footer-section p {
  color: #ecf0f1; /* Ensure this matches the footer text color */
  margin-bottom: 0.25rem;
}

/* Add or modify these styles */

.landing-container {
  width: 100%;
  overflow-x: hidden; /* This prevents horizontal scrolling */
}

.landing-main {
  max-width: 100%;
  margin: 0;
  padding: 80px 0 3rem;
  overflow-x: hidden; /* This prevents horizontal scrolling */
}

/* Modify the section-background class */
.section-background {
  margin: 2rem 0;
  padding: 4rem 5%; /* Add horizontal padding here instead of negative margins */
  background: linear-gradient(135deg, #f5f5f5 0%, #e0e0e0 100%);
}

/* Remove any negative margins from these sections */
.hero, .features, .about, .streamline-section {
  margin-left: 0;
  margin-right: 0;
}

/* Ensure the footer doesn't cause overflow */
.landing-footer {
  width: 100%;
  box-sizing: border-box;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5%;
  box-sizing: border-box;
}

/* Adjust media queries if necessary */
@media (max-width: 768px) {
  .section-background {
    padding: 3rem 5%;
  }
}

.follow-us-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.follow-us-content {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.follow-us-content h4 {
  margin: 0;
  font-size: 1.2rem;
}

.social-icons {
  display: flex;
  gap: 1rem;
}

.social-icon {
  font-size: 1.5rem;
  color: #ecf0f1;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: #3498db;
}

@media (max-width: 768px) {
  .follow-us-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .follow-us-content h4 {
    margin-bottom: 0.5rem;
  }
}

