/* AD Department Styles */
.call-sheets-list,
.call-sheet-form {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.header-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.section-title {
  margin: 24px 0 12px;
  font-size: 1.2rem;
  color: #2c5282;
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 8px;
}

/* Form Styles */
.form-row {
  display: flex;
  gap: 16px;
  margin-bottom: 15px;
}

.wrap-row {
  flex-wrap: wrap;
}

.form-group {
  flex: 1;
  margin-bottom: 15px;
}

.form-group.small {
  flex: 0 0 100px;
}

.form-group.medium {
  flex: 0 0 200px;
}

.form-group.large {
  flex: 1;
  min-width: 250px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
  color: #4a5568;
}

.form-control {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 14px;
}

.form-control:focus {
  border-color: #4299e1;
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}

.time-input {
  width: 120px;
}

textarea.form-control {
  resize: vertical;
  min-height: 80px;
}

/* Schedule item styles */
.schedule-item {
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 12px;
  background-color: #f8fafc;
}

/* Button styles that match the minimalist blue theme */
.btn-primary {
  background-color: #4299e1;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.btn-primary:hover {
  background-color: #3182ce;
}

.btn-secondary {
  background-color: #edf2f7;
  color: #4a5568;
  border: 1px solid #e2e8f0;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.btn-secondary:hover {
  background-color: #e2e8f0;
}

.btn-small {
  font-size: 12px;
  padding: 4px 8px;
  margin-right: 5px;
}

.btn-icon {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  color: #718096;
  border-radius: 4px;
}

.btn-icon:hover {
  background-color: #f0f8ff;
  color: #4299e1;
}

.btn-icon.delete:hover {
  color: #e53e3e;
}

.delete {
  color: #e53e3e;
}

.actions {
  white-space: nowrap;
}

/* Table Styles */
.table-container {
  overflow-x: auto;
  margin-bottom: 20px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.data-table th {
  background-color: #f0f8ff; /* Light blue */
  border: 1px solid #4299e1; /* Blue border */
  padding: 10px;
  text-align: left;
  color: #2c5282;
  font-weight: 600;
}

.data-table td {
  border: 1px solid #e2e8f0;
  padding: 8px 10px;
}

.data-table tr:nth-child(even) {
  background-color: #f8fafc;
}

.data-table tr:hover {
  background-color: #f0f8ff;
}

/* Status Messages */
.error-message {
  color: #e53e3e;
  background-color: #fed7d7;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
}

.success-message {
  color: #38a169;
  background-color: #c6f6d5;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
}

/* Form Buttons Container */
.form-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

/* AD Department specific features */
.prep-row {
  background-color: #f0f8ff; /* Light blue */
  border: 1px solid #4299e1; /* Blue border */
}

.shoot-row {
  background-color: #f2f2f2; /* Light grey */
  border: 1px solid #718096; /* Grey border */
}

.wrap-row {
  background-color: #f0f8ff; /* Light blue */
  border: 1px solid #4299e1; /* Blue border */
}

/* Scene Scheduling specific styles */
.scenes-schedules-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (min-width: 1024px) {
  .scenes-schedules-container {
    flex-direction: row;
  }
  
  .scenes-list, .schedules-list {
    flex: 1;
  }
}

.scene-columns {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.scene-column {
  flex: 1;
  min-width: 300px;
  border-radius: 8px;
  padding: 15px;
  background-color: #f8fafc;
}

.column-title {
  font-size: 1.1rem;
  margin-bottom: 15px;
  color: #2c5282;
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 10px;
}

.droppable-area {
  min-height: 300px;
  border: 2px dashed #e2e8f0;
  border-radius: 6px;
  padding: 15px;
  background-color: #fff;
}

.unscheduled-area {
  background-color: #f0f8ff; /* Light blue background */
}

.scheduled-area {
  background-color: #f2f2f2; /* Light grey background */
}

.draggable-item {
  margin-bottom: 10px;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
}

.scene-card {
  display: flex;
  padding: 12px;
}

.scene-number {
  font-weight: bold;
  font-size: 1.2rem;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2c5282;
}

.scene-details {
  flex: 1;
  padding-left: 12px;
}

.scene-location {
  font-weight: 500;
  margin-bottom: 4px;
  color: #4a5568;
}

.scene-description {
  margin-bottom: 8px;
  font-size: 0.9rem;
}

.scene-meta {
  font-size: 0.8rem;
  color: #718096;
}

.empty-message {
  text-align: center;
  color: #a0aec0;
  padding: 20px;
}

.scene-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.scene-checkbox {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f0f8ff;
  border: 1px solid #4299e1;
  border-radius: 4px;
  padding: 6px 12px;
  margin-bottom: 6px;
}

.scene-checkbox:hover {
  background-color: #ebf8ff;
}

.form-control-checkbox {
  width: auto;
}

.checkbox-label {
  font-size: 0.9rem;
  color: #2d3748;
  cursor: pointer;
}

/* Styles for scene form */
.scene-form {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

/* Walkie Management Styles */
.walkie-management-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filters {
  display: flex;
  gap: 15px;
}

.filter-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.filter-group label {
  font-weight: 500;
  color: #4a5568;
}

.walkies-list,
.channel-assignments,
.walkie-form,
.checkout-form {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.2rem;
  color: #2d3748;
  margin-bottom: 15px;
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 10px;
}

.channels-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
  margin-bottom: 20px;
}

.channel-item {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #f0f8ff;
  border: 1px solid #4299e1;
  padding: 10px;
  border-radius: 6px;
}

.channel-number {
  background-color: #4299e1;
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-weight: bold;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
}

.form-group {
  flex: 1;
  min-width: 200px;
}

.form-buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

/* Battery status colors */
.battery-full {
  color: #48bb78; /* Green */
  font-weight: bold;
}

.battery-medium {
  color: #ecc94b; /* Yellow */
  font-weight: bold;
}

.battery-low {
  color: #ed8936; /* Orange */
  font-weight: bold;
}

.battery-empty {
  color: #f56565; /* Red */
  font-weight: bold;
}

/* Dropdown for battery */
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 120px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 10;
  border-radius: 4px;
}

.dropdown-content button {
  color: #4a5568;
  padding: 8px 12px;
  text-decoration: none;
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
}

.dropdown-content button:hover {
  background-color: #f0f8ff;
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* Button styles */
.btn-small {
  padding: 4px 8px;
  font-size: 0.8rem;
  margin-right: 5px;
}

.delete {
  color: #e53e3e;
  border-color: #e53e3e;
}

.delete:hover {
  background-color: #fff5f5;
}

/* Success and error messages */
.success-message {
  background-color: #c6f6d5;
  color: #276749;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.error-message {
  background-color: #fed7d7;
  color: #c53030;
  padding: 10px 15px;
  border-radius: 4px;
  margin-bottom: 20px;
}

/* Walkie table styles */
.table-container {
  overflow-x: auto;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th {
  text-align: left;
  padding: 12px;
  background-color: #f8fafc;
  border-bottom: 2px solid #e2e8f0;
  color: #4a5568;
  font-weight: 600;
}

.data-table td {
  padding: 12px;
  border-bottom: 1px solid #e2e8f0;
}

.data-table .actions {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

/* Apply color schemes based on user preferences */
select.form-control {
  background-color: #ebf8ff; /* bg-blue-50 */
  border-color: #bee3f8; /* border-blue-200 */
  color: #2b6cb0; /* text-blue-700 */
}

select.form-control:hover {
  background-color: #e1f3ff; /* hover:bg-blue-100 */
}

/* Crew Vehicles Page Styles */
.crew-vehicles-page .filters-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 20px;
  background-color: #f8fafc;
  padding: 15px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.crew-vehicles-page .filter-group {
  display: flex;
  align-items: center;
  gap: 8px;
}

.crew-vehicles-page .filter-group label {
  font-weight: 500;
  color: #4a5568;
  white-space: nowrap;
}

.crew-vehicles-page .department-select,
.crew-vehicles-page .search-input {
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 14px;
  background-color: #ebf8ff; /* Light blue background */
  border: 1px solid #bee3f8;
  color: #2b6cb0;
}

.crew-vehicles-page .department-select:focus,
.crew-vehicles-page .search-input:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}

.crew-vehicles-page .summary-stats {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.crew-vehicles-page .stat-card {
  background-color: #f0f8ff; /* Light blue */
  border: 1px solid #4299e1; /* Blue border */
  border-radius: 8px;
  padding: 15px;
  flex: 1;
  text-align: center;
}

.crew-vehicles-page .stat-card h4 {
  margin: 0 0 10px 0;
  color: #2c5282;
  font-size: 16px;
}

.crew-vehicles-page .stat-value {
  font-size: 24px;
  font-weight: bold;
  color: #2b6cb0;
}

.crew-vehicles-page .crew-members-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.crew-vehicles-page .crew-member-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.crew-vehicles-page .crew-member-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 10px;
}

.crew-vehicles-page .crew-member-header h3 {
  margin: 0;
  color: #2d3748;
  font-size: 18px;
}

.crew-vehicles-page .department-badge {
  background-color: #ebf8ff;
  color: #2b6cb0;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 600;
}

.crew-vehicles-page .crew-member-contact {
  font-size: 14px;
  color: #4a5568;
}

.crew-vehicles-page .crew-member-contact p {
  margin: 5px 0;
}

.crew-vehicles-page .vehicles-list {
  background-color: #f8fafc;
  border-radius: 6px;
  padding: 15px;
}

.crew-vehicles-page .vehicles-list h4 {
  margin: 0 0 10px 0;
  color: #2c5282;
  font-size: 16px;
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 5px;
}

.crew-vehicles-page .vehicle-item {
  background-color: #f0f8ff; /* Light blue */
  border: 1px solid #4299e1; /* Blue border */
  border-radius: 4px;
  padding: 12px;
  margin-bottom: 10px;
}

.crew-vehicles-page .vehicle-item:last-child {
  margin-bottom: 0;
}

.crew-vehicles-page .vehicle-number {
  font-weight: 600;
  color: #2c5282;
  font-size: 15px;
  margin: 0 0 8px 0;
  border-bottom: 1px dashed #bee3f8;
  padding-bottom: 5px;
}

.crew-vehicles-page .vehicle-make-model,
.crew-vehicles-page .vehicle-color,
.crew-vehicles-page .license-plate,
.crew-vehicles-page .plate-state-row {
  margin: 4px 0;
  font-size: 14px;
  color: #4a5568;
}

.crew-vehicles-page .plate-number {
  background-color: #edf2f7;
  color: #4a5568;
  padding: 3px 8px;
  border-radius: 3px;
  font-family: monospace;
  letter-spacing: 1px;
  font-weight: 600;
  margin-left: 5px;
}

.crew-vehicles-page .plate-state {
  font-weight: 500;
  color: #2c5282;
  margin-left: 5px;
}

.crew-vehicles-page .no-data {
  background-color: #f8fafc;
  padding: 20px;
  text-align: center;
  border-radius: 8px;
  border: 1px dashed #e2e8f0;
  color: #718096;
  font-style: italic;
}
