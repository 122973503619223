.walkie-3d-model-section {
  margin-bottom: 30px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.walkie-3d-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (min-width: 768px) {
  .walkie-3d-container {
    flex-direction: row;
  }
  
  .walkie-model-container {
    flex: 3;
  }
  
  .walkie-3d-info {
    flex: 2;
  }
}

.walkie-model-container {
  height: 400px;
  width: 100%;
  background: #f0f8ff;
  border-radius: 8px;
  border: 1px solid #4299e1;
  overflow: hidden;
  position: relative;
}

.walkie-3d-info {
  background: #f2f2f2;
  border: 1px solid #718096;
  padding: 15px;
  border-radius: 8px;
}

.walkie-3d-info p {
  font-weight: 600;
  margin-bottom: 10px;
  color: #2d3748;
}

.walkie-3d-info ul {
  margin-top: 0;
  padding-left: 20px;
}

.walkie-3d-info li {
  margin-bottom: 5px;
}

/* Battery indicator styling */
.battery-indicator {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: rgba(255, 255, 255, 0.8);
  padding: 8px 12px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-size: 14px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.battery-indicator span {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  margin-right: 8px;
}

.battery-full {
  background-color: #48bb78; /* Green */
}

.battery-medium {
  background-color: #ecc94b; /* Yellow */
}

.battery-low {
  background-color: #ed8936; /* Orange */
}

.battery-empty {
  background-color: #e53e3e; /* Red */
}

.battery-indicator p {
  margin: 0;
  font-weight: 500;
}

/* Controls styling */
.walkie-model-controls {
  position: absolute;
  top: 10px;
  left: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 10;
}

.walkie-model-controls button,
.walkie-model-controls select {
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 120px;
}

.walkie-model-controls button:hover,
.walkie-model-controls select:hover {
  background-color: #bee3f8 !important;
}

.form-control {
  border: 1px solid #4299e1;
  background-color: #ebf8ff;
  color: #2b6cb0;
}

/* Checked out walkie style */
.walkie-checked-out {
  background-color: #f2f2f2;
  border-color: #718096;
}

/* Available walkie style */
.walkie-available {
  background-color: #f0f8ff;
  border-color: #4299e1;
}
