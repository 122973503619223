.users-content {
  margin-left: 0px; /* Width of the sidebar */
  margin-right: 0px;
  padding: 20px;
  width: calc(100% - 0px); /* Adjust width to account for sidebar */
}

.page-header {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
}

.page-content {
  padding: 20px;
}