.admin-revenue-dashboard {
  padding: 2rem;
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.stat-card {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.stat-card h3 {
  margin: 0;
  color: #666;
  font-size: 1rem;
  font-weight: 500;
}

.stat-value {
  margin: 0.5rem 0 0;
  font-size: 2rem;
  font-weight: 600;
  color: #2c3e50;
}

.revenue-chart {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 2rem;
}

.revenue-chart h3 {
  margin: 0 0 1.5rem 0;
  color: #2c3e50;
}

/* Custom colors for the chart */
.recharts-default-tooltip {
  background-color: rgba(255, 255, 255, 0.95) !important;
  border: 1px solid #ddd !important;
  border-radius: 4px;
  padding: 0.5rem !important;
}

.recharts-tooltip-label {
  color: #666;
  margin-bottom: 0.25rem;
}
