.budget-container {
  display: flex;
  min-height: 100vh;
}

.budget-content {
  flex: 1;
  padding: 2rem;
  background-color: #f9fafb;
  overflow-y: auto;
}

/* New container for centered content */
.budget-content-inner {
  max-width: 960px;
  margin: 0 auto;
  width: 100%;
}

input, select, .date-picker {
  width: 100%;
  max-width: 205px;
  padding: 0.6rem;
  border: 1px solid #cbd5e1;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  color: #1e293b;
}

.project-info {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(235px, 1fr));
  gap: 1.25rem;
  margin-bottom: 2rem;
}

.project-info .input-group input,
.project-info .input-group select,
.project-info .input-group .date-picker {
  max-width: 185px;
  width: 100%;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.input-group label {
  font-weight: 500;
  color: #475569;
  margin-bottom: 0.5rem;
}

.input-group input,
.input-group select {
  width: 100%; 
}

.production-type-group {
  grid-column: 1 / -1;
  margin-top: 1rem;
}

.radio-group {
  display: flex;
  gap: 1.5rem;
  margin-top: 0.5rem;
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.radio-label input[type="radio"] {
  margin: 0;
  width: auto;
}

.save-button {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  background-color: #4299e1;
  color: white;
  border: none;
  border-radius: 6px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-bottom: 16px;
}

.save-button:hover {
  background-color: #3182ce;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.save-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.save-button.saving {
  background-color: #63b3ed;
}

.save-button.saved {
  background-color: #48bb78;
}

.save-error {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #e53e3e;
  margin-bottom: 16px;
  padding: 8px 12px;
  background-color: #fff5f5;
  border-radius: 4px;
  border-left: 3px solid #e53e3e;
}

/* Animation for loading spinner */
.icon-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.budget-summary {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin: 2rem 0;
}

.summary-card {
  background-color: white;
  border-radius: 0.75rem;
  padding: 1.25rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border-left: 4px solid #4299e1;
  transition: all 0.2s ease;
}

.summary-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.summary-title {
  font-size: 0.875rem;
  color: #4a5568;
  margin-bottom: 0.5rem;
}

.summary-value {
  font-size: 1.5rem;
  font-weight: 600;
  color: #2b6cb0;
}

.episode-blocks-section {
  background-color: #f8fafc;
  border-radius: 0.75rem;
  padding: 1.5rem;
  margin: 1.5rem 0;
  border: 1px solid #e2e8f0;
}

.episode-block {
  background-color: white;
  border-radius: 0.75rem;
  padding: 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid #e2e8f0;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.episode-block:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.block-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.block-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.add-block-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #e0f2fe;
  color: #0ea5e9;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  margin-top: 0.5rem;
}

.add-block-button:hover {
  background-color: #bae6fd;
}

.block-selector {
  margin: 1.5rem 0;
  max-width: 1200px;
}

.block-tabs {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.block-tab {
  padding: 0.6rem 1rem;
  background-color: #ebf8ff;
  border: 1px solid #bee3f8;
  border-radius: 0.375rem;
  font-weight: 500;
  color: #3182ce;
  cursor: pointer;
  transition: all 0.2s ease;
}

.block-tab:hover {
  background-color: #bee3f8;
  transform: translateY(-1px);
}

.block-tab.active {
  background-color: #3182ce;
  color: white;
  border-color: #2b6cb0;
}

.date-range-selector {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.date-picker-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.date-picker-group label {
  font-weight: 500;
  color: #2c3e50;
}

.budget-details {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.budget-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.budget-table th,
.budget-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e1e1e1;
}

.budget-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #2c3e50;
}

.budget-table tbody tr:hover {
  background-color: #f8f9fa;
}

.budget-table tfoot {
  font-weight: bold;
}

.budget-table .total-label {
  text-align: right;
}

.budget-table .total-amount {
  color: #3498db;
}

.production-type-group {
  grid-column: 1 / -1;
  margin-top: 1rem;
}

.radio-group {
  display: flex;
  gap: 2rem;
  margin-top: 0.5rem;
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  background-color: #f0f9ff;
  border: 1px solid #bae6fd;
  transition: all 0.2s ease;
}

.radio-label:hover {
  background-color: #e0f2fe;
}

.radio-label input[type="radio"] {
  margin: 0;
  cursor: pointer;
}

.episode-blocks-section {
  margin-top: 1.5rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e1e1e1;
}

.subsection-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2c3e50;
  margin-bottom: 0.5rem;
}

.section-description {
  color: #64748b;
  margin-bottom: 1.5rem;
  font-size: 0.9rem;
}

.episode-block {
  background-color: #f8fafc;
  border-radius: 0.75rem;
  border: 1px solid #e2e8f0;
  padding: 1rem;
  margin-bottom: 1rem;
}

.episode-block-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.episode-block-header h4 {
  margin: 0;
  color: #0c4a6e;
  font-weight: 600;
}

.episode-block-inputs {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.add-block-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #f0f9ff;
  color: #0369a1;
  border: 1px dashed #bae6fd;
  border-radius: 0.5rem;
  margin-top: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.add-block-button:hover {
  background-color: #e0f2fe;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.block-selector {
  margin-bottom: 2rem;
  border-bottom: 1px solid #e2e8f0;
  padding-bottom: 1rem;
}

.block-selector h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #475569;
  margin-bottom: 1rem;
}

.block-tabs {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.block-tab {
  padding: 0.6rem 1.2rem;
  background-color: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-weight: 500;
  color: #475569;
  cursor: pointer;
  transition: all 0.2s ease;
}

.block-tab:hover {
  background-color: #f1f5f9;
  border-color: #cbd5e1;
}

.block-tab.active {
  background-color: #0ea5e9;
  color: white;
  border-color: #0ea5e9;
}

.budget-details {
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  overflow: hidden;
}

.budget-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem;
  background-color: #f0f9ff;
  border-bottom: 2px solid #e0f2fe;
}

.budget-header h2 {
  margin: 0;
  color: #0c4a6e;
  font-size: 1.25rem;
  font-weight: 600;
}

.add-section-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #0ea5e9;
  color: white;
  border: none;
  border-radius: 0.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.add-section-button:hover {
  background-color: #0284c7;
}

.empty-state {
  padding: 3rem 1.5rem;
  text-align: center;
  color: #64748b;
}

.section-item {
  padding: 1.25rem;
  border-bottom: 1px solid #e0f2fe;
}

.section-item:last-child {
  border-bottom: none;
}

.category-group {
  background-color: white;
  border-radius: 0.75rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
  overflow: hidden;
}

.category-group h2 {
  color: #0c4a6e;
  padding: 1.25rem;
  margin: 0;
  background-color: #f0f9ff;
  border-bottom: 2px solid #e0f2fe;
  font-size: 1.25rem;
  font-weight: 600;
}

.phase-inputs {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input-group label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #475569;
}

.input-group input,
.input-group select {
  padding: 0.5rem;
  border: 1px solid #cbd5e1;
  border-radius: 0.375rem;
  background-color: #f8fafc;
  color: #0f172a;
  transition: all 0.2s;
}

.input-group input:focus,
.input-group select:focus {
  border-color: #0ea5e9;
  outline: none;
  box-shadow: 0 0 0 2px rgba(14, 165, 233, 0.2);
}

.validation-tooltip {
  margin-top: 0.25rem;
  font-size: 0.75rem;
  border-radius: 0.25rem;
  padding: 0.25rem 0.5rem;
}

.validation-tooltip.invalid {
  background-color: #fee2e2;
  color: #dc2626;
}

.validation-tooltip.valid {
  background-color: #dcfce7;
  color: #16a34a;
}

/* Enhanced validation tooltip styling */
.validation-tooltip {
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
  margin-top: 4px;
  padding: 4px 8px;
  border-radius: 4px;
  animation: fadeIn 0.3s ease;
}

.validation-tooltip.valid {
  color: #2f855a;
  background-color: #f0fff4;
  border: 1px solid #c6f6d5;
}

.validation-tooltip.invalid {
  color: #c53030;
  background-color: #fff5f5;
  border: 1px solid #fed7d7;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 1.75rem;
  border-radius: 0.75rem;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.15);
  max-width: 500px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  z-index: 1001;
}

.modal-content h3 {
  margin-bottom: 1.25rem;
  color: #0c4a6e;
  font-size: 1.25rem;
  font-weight: 600;
  border-bottom: 2px solid #f0f9ff;
  padding-bottom: 0.75rem;
}

.menu-options {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.menu-item {
  width: 100%;
  text-align: left;
  padding: 0.75rem 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  transition: all 0.2s;
  background-color: #f0f9ff;
  border: 1px solid #bae6fd;
  color: #0369a1;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.menu-item:hover {
  background-color: #e0f2fe;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.submenu {
  padding-left: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.submenu .menu-item {
  background-color: #f8fafc;
  border: 1px solid #bae6fd;
  color: #0369a1;
  padding: 0.6rem 1rem;
}

.menu-item svg {
  transition: transform 0.2s ease;
}

.menu-item svg.rotate-90 {
  transform: rotate(90deg);
}

.position-selector .menu-item,
.equipment-selector .menu-item {
  justify-content: flex-start;
  margin-bottom: 0.5rem;
  padding: 0.6rem 1rem;
}

.modal-content.position-selector,
.modal-content.equipment-selector {
  max-height: 70vh;
  overflow-y: auto;
  border-radius: 0.75rem;
  padding: 1.5rem;
}

.modal-content::-webkit-scrollbar {
  width: 8px;
}

.modal-content::-webkit-scrollbar-track {
  background: #f1f5f9;
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb {
  background: #cbd5e1;
  border-radius: 4px;
}

.modal-content::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}

.action-button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border-radius: 0.375rem;
  transition: all 0.2s;
}

.add-button {
  background-color: #0ea5e9;
  color: white;
}

.add-button:hover {
  background-color: #0284c7;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(2, 132, 199, 0.2);
}

.delete-button {
  background-color: #fee2e2;
  color: #ef4444;
}

.delete-button:hover {
  background-color: #fecaca;
  color: #dc2626;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(220, 38, 38, 0.1);
}

/* Phase Input Component Styling */
.phase-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  background: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.phase-row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: flex-end;
}

/* Phase type rows (prep, shoot, wrap) */
.phase-type-row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  padding: 10px;
  border-radius: 6px;
  border-left-width: 3px;
  border-left-style: solid;
}

.phase-type-label {
  font-weight: 600;
  width: 60px;
  text-align: right;
}

.prep-row {
  background-color: #f0f8ff;
  border-left-color: #4299e1;
}

.shoot-row {
  background-color: #f2f2f2;
  border-left-color: #718096;
}

.wrap-row {
  background-color: #f0f8ff;
  border-left-color: #4299e1;
}

.phase-type-row .input-group {
  flex: 1;
  margin-bottom: 0;
}

/* Special styling for the days row - can remove this since we're not using it anymore */
.days-row {
  background-color: #f0f7ff;
  padding: 10px;
  border-radius: 6px;
  border-left: 3px solid #007bff;
}

.days-row .input-group {
  flex: 1;
}

@media (max-width: 768px) {
  .phase-row {
    flex-direction: column;
    align-items: stretch;
  }
  
  .phase-type-row {
    position: relative;
    padding-left: 70px;
  }
  
  .phase-type-label {
    position: absolute;
    left: 10px;
    top: 10px;
    text-align: left;
  }
  
  .days-row {
    padding: 8px;
  }
  
  .days-row .input-group {
    margin-bottom: 8px;
  }
}

.position-group {
  flex: 2;
}

.position-selector-wrapper {
  position: relative;
  width: 100%;
}

.position-input {
  width: 100%;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 8px 12px;
  border-radius: 4px;
}

.position-input:hover {
  border-color: #007bff;
}

.grand-total-row {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px dashed #ddd;
}

.days-total, .amount-total {
  flex: 1;
}

/* Position Selector Modal */
.position-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
}

.position-item {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #fff;
  text-align: left;
  cursor: pointer;
  transition: all 0.2s ease;
}

.position-item:hover {
  background: #f0f7ff;
  border-color: #007bff;
}

.close-button {
  margin-top: 15px;
  padding: 8px 15px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.close-button:hover {
  background: #0069d9;
}

/* Make input fields larger on mobile */
@media (max-width: 768px) {
  .input-group input,
  .input-group select {
    height: 40px;
    font-size: 16px; /* Prevent zoom on mobile */
  }
}

@media (max-width: 768px) {
  .budget-content {
    padding: 1rem;
  }
  
  .project-info {
    grid-template-columns: 1fr;
  }
  
  .block-inputs {
    grid-template-columns: 1fr;
  }
  
  .input-group input,
  .input-group select,
  .input-group .date-picker {
    max-width: 100%;
  }
  
  .radio-group {
    flex-direction: column;
    gap: 0.75rem;
  }
}

/* Custom Fields component styles */
.custom-fields-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 10px;
  border-radius: 6px;
  background-color: #f9fafc;
}

.custom-field-row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
  padding: 10px;
  border-radius: 6px;
  background-color: #f2f2f2;
  border-left: 3px solid #718096;
}

.field-name {
  flex: 2;
  min-width: 150px;
}

.field-name input {
  width: 100%;
  padding: 6px 10px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
}

.add-field-form {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  padding: 10px;
  background-color: #f0f8ff;
  border-radius: 6px;
  border-left: 3px solid #4299e1;
}

.add-field-form input {
  flex: 1;
  padding: 6px 10px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
}

.add-button, .cancel-button {
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.add-button {
  background-color: #4299e1;
  color: white;
  border: none;
}

.cancel-button {
  background-color: #e2e8f0;
  color: #4a5568;
  border: none;
}

.add-field-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  border: 1px dashed #a0aec0;
  border-radius: 4px;
  background-color: transparent;
  color: #4a5568;
  cursor: pointer;
  margin-top: 10px;
  width: 100%;
}

.add-field-button:hover {
  background-color: #f0f8ff;
  border-color: #4299e1;
  color: #4299e1;
}

.section-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
  padding: 10px 15px;
  border-top: 1px solid #e2e8f0;
}

.total-display {
  display: flex;
  gap: 0;
  align-items: center;
  font-size: 16px;
}

.total-display span:first-child {
  font-weight: 600;
  color: #1a202c; /* Darker text for better readability */
  margin-right: 2px;
}

.total-amount {
  color: #4299e1; /* Blue color matching the app's color scheme */
  font-weight: 700;
}

/* Section total styling - matching grand total */
.category-total {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 12px 16px;
  margin: 10px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  width: max-content;
  margin-right: auto;
}

.category-total span:first-child {
  font-size: 16px;
  font-weight: 600;
  color: #1a202c;
  margin-bottom: 4px;
}

.category-total span:last-child {
  font-size: 18px;
  font-weight: 700;
  color: #4299e1;
}

/* Set Rates button styling */
.set-rates-button {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 500;
  color: #4299e1;
  background-color: #ebf8ff;
  border: 1px solid #bee3f8;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.set-rates-button:hover {
  background-color: #bee3f8;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.set-rates-button:active {
  transform: translateY(0);
}

/* Rate Settings Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.rate-settings-modal {
  background-color: white;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #f0f8ff;
  border-bottom: 2px solid #4299e1;
}

.modal-header h2 {
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  color: #2d3748;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #4a5568;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
}

.close-button:hover {
  color: #2d3748;
}

.rate-form-content {
  padding: 20px;
}

.rates-description {
  margin-top: 0;
  margin-bottom: 15px;
  color: #4a5568;
  font-size: 14px;
}

.rates-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
  margin-bottom: 20px;
}

.rate-header {
  font-weight: 600;
  color: #2d3748;
  padding: 8px 0;
  border-bottom: 1px solid #e2e8f0;
}

.rate-label {
  display: flex;
  align-items: center;
  color: #4a5568;
}

.rate-input, .hours-input {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 14px;
}

.rate-input:focus, .hours-input:focus {
  border-color: #4299e1;
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.2);
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  padding: 15px 20px;
  background-color: #f8fafc;
  border-top: 1px solid #e2e8f0;
  gap: 10px;
}

.cancel-button, .save-button {
  padding: 8px 16px;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancel-button {
  background-color: white;
  color: #4a5568;
  border: 1px solid #e2e8f0;
}

.cancel-button:hover {
  background-color: #f8fafc;
  border-color: #cbd5e0;
}

.save-button {
  background-color: #4299e1;
  color: white;
  border: 1px solid #4299e1;
}

.save-button:hover {
  background-color: #3182ce;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Custom position styling */
.add-custom-button {
  background-color: #ebf8ff;
  color: #4299e1;
  border: 1px dashed #bee3f8;
  border-radius: 4px;
  padding: 8px 12px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100%;
  margin-top: 15px;
  transition: all 0.2s ease;
}

.add-custom-button:hover {
  background-color: #bee3f8;
  transform: translateY(-1px);
}

.custom-position-row {
  margin-top: 15px;
  padding: 12px;
  background-color: #f0f8ff;
  border-radius: 6px;
  border: 1px solid #bee3f8;
}

.custom-position-grid {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr 40px;
  gap: 10px;
  align-items: center;
}

.custom-name-input {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  font-size: 14px;
}

.custom-name-input:focus {
  border-color: #4299e1;
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.2);
}

.remove-custom-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #718096;
  border: 1px solid #e2e8f0;
  font-size: 18px;
  cursor: pointer;
  padding: 0;
  transition: all 0.2s ease;
}

.remove-custom-button:hover {
  color: #e53e3e;
  border-color: #fed7d7;
  background-color: #fff5f5;
}

/* Floating add button */
.floating-add-button {
  position: fixed;
  bottom: 20px; /* Same as grand total */
  left: calc(180px + 30px); /* Account for sidebar width (180px) plus desired padding */
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 15px;
  background-color: rgba(66, 153, 225, 0.5);
  color: white;
  border: 2px solid #4299e1;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  z-index: 100;
  cursor: pointer;
  transition: all 0.2s ease;
}

.floating-add-button:hover {
  background-color: rgba(66, 153, 225, 0.8);
  transform: translateY(-2px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

/* Adjust for collapsed sidebar */
@media (max-width: 1024px) {
  .floating-add-button {
    left: calc(60px + 30px); /* Collapsed sidebar width (60px) plus padding */
  }
}

/* Fully responsive for mobile */
@media (max-width: 768px) {
  .floating-add-button {
    left: 20px; /* No sidebar on mobile */
    bottom: 20px;
    padding: 10px 14px;
  }
  
  .floating-grand-total {
    padding: 8px 12px;
    right: 20px;
  }
  
  .floating-grand-total h3 {
    font-size: 12px;
  }
  
  .floating-grand-total .total-amount {
    font-size: 14px;
  }
}

/* Floating grand total */
.floating-grand-total {
  position: fixed;
  bottom: 20px;
  right: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px 15px;
  background-color: rgba(240, 248, 255, 0.5);
  border: 2px solid #4299e1;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  z-index: 99;
  transition: all 0.2s ease;
}

.floating-grand-total:hover {
  background-color: rgba(240, 248, 255, 0.8);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.floating-grand-total h3 {
  font-size: 14px;
  font-weight: 600;
  color: #2d3748;
  margin: 0;
  margin-bottom: 3px;
}

.floating-grand-total .total-amount {
  font-size: 16px;
  font-weight: 700;
  color: #4299e1;
}

/* Section total styling - matching grand total */
.category-total {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  padding: 12px 16px;
  margin: 10px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  width: max-content;
  margin-right: auto;
}

.category-total span:first-child {
  font-size: 16px;
  font-weight: 600;
  color: #1a202c;
  margin-bottom: 4px;
}

.category-total span:last-child {
  font-size: 18px;
  font-weight: 700;
  color: #4299e1;
}

.hours-description {
  margin: 10px 0 16px;
  padding: 10px;
  background-color: #f0f8ff;
  border-left: 3px solid #4299e1;
  border-radius: 4px;
  color: #2d3748;
  font-size: 14px;
  line-height: 1.5;
}

.hours-description strong {
  color: #4299e1;
}

/* Preset rate dropdown styling */
.rate-input-wrapper {
  width: 100%;
  position: relative;
}

.rate-input-with-dropdown {
  display: flex;
  align-items: stretch;
  width: 100%;
  border: 2px solid #4299e1;
  border-radius: 4px;
  overflow: hidden;
}

.rate-input-with-dropdown input {
  flex: 1;
  min-width: 80px;
  border: none;
  padding: 8px 12px;
  border-radius: 0;
}

.preset-rate-dropdown {
  width: 180px;
  background-color: #ebf8ff;
  color: #4299e1;
  border: none;
  padding: 0 12px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  border-left: 1px solid #bee3f8;
}

.preset-rate-dropdown:hover {
  background-color: #bee3f8;
}

.preset-rate-dropdown:focus {
  outline: none;
  background-color: #bee3f8;
}

.preset-rate-dropdown option {
  background-color: white;
  color: #4a5568;
  padding: 8px;
}

.rate-group {
  flex: 1;
}

.position-group, .equipment-group {
  flex: 2;
}

/* Preset rate selector styling */
.preset-rate-row {
  margin-top: -10px;
  margin-bottom: 8px;
}

.preset-rate-group {
  flex: 1;
}

.preset-rate-selector {
  width: 100%;
  padding: 8px 12px;
  background-color: #ebf8ff;
  color: #4299e1;
  border: 1px solid #bee3f8;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 38px;
}

.preset-rate-selector:hover {
  background-color: #bee3f8;
}

.preset-rate-selector:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.3);
}

.preset-rate-selector option {
  background-color: white;
  color: #4a5568;
  padding: 8px;
}

/* Rate input with dropdown styling - improved visibility */
.rate-input-with-dropdown {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.rate-input-with-dropdown input {
  flex: 1;
  min-width: 80px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none;
}

.preset-rate-dropdown {
  height: 100%;
  min-height: 38px;
  min-width: 120px;
  max-width: 160px;
  padding: 0 10px;
  background-color: #ebf8ff;
  color: #4299e1;
  border: 1px solid #bee3f8;
  border-left: none;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block !important; /* Ensure visibility */
  z-index: 5;
}

.preset-rate-dropdown:hover {
  background-color: #bee3f8;
}

.preset-rate-dropdown:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}

.preset-rate-dropdown option {
  background-color: white;
  color: #4a5568;
  padding: 8px;
}

/* Rate controls - new styling */
.rate-controls {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.rate-input {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
}

.rate-preset-container {
  width: 100%;
}

.rate-preset-selector {
  width: 100%;
  padding: 8px 12px;
  background-color: #ebf8ff;
  color: #4299e1;
  border: 1px solid #bee3f8;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.rate-preset-selector:hover {
  background-color: #bee3f8;
}

.rate-preset-selector:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(66, 153, 225, 0.3);
}

.rate-preset-selector option {
  background-color: white;
  color: #4a5568;
  padding: 8px;
}

@media (max-width: 768px) {
  .budget-content-inner {
    max-width: 100%;
    padding: 0;
  }
}

@media (min-width: 1600px) {
  .budget-content-inner {
    max-width: 1120px;
  }
}

/* Set standard size for all phase row inputs */
.phase-type-row .input-group input {
  max-width: 160px;
  width: 160px;
  margin: 0 auto; /* Center the input */
}

/* Make the Total input readonly background nicer */
.phase-type-row .input-group input[readonly] {
  background-color: #f8fafc;
  border-color: #e2e8f0;
}

/* Override previous equipment-specific sizing since all inputs should now be 160px */
.phase-container .input-group.equipment-group ~ .phase-type-row .input-group input {
  max-width: 160px;
  width: 160px;
}

/* Hide preset rates when equipment is selected */
.phase-container .input-group.equipment-group ~ .preset-rate-row {
  display: none;
}

/* Mobile responsiveness for phase inputs */
@media (max-width: 768px) {
  .phase-type-row .input-group input,
  .phase-container .input-group.equipment-group ~ .phase-type-row .input-group input {
    max-width: 100%; /* Full width on mobile */
    width: 100%;
  }
}

/* Floating save button */
.floating-save-button {
  position: fixed;
  bottom: 80px; /* Position above the floating add button */
  left: calc(180px + 30px); /* Account for sidebar width (180px) plus desired padding */
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 15px;
  background-color: rgba(66, 153, 225, 0.9);
  color: white;
  border: 2px solid #4299e1;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 100;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 500;
}

.floating-save-button:hover {
  background-color: #3182ce;
  transform: translateY(-2px);
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

.floating-save-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
  transform: none;
}

.floating-save-button.saving {
  background-color: #63b3ed;
}

.floating-save-button.saved {
  background-color: #68d391;
}

/* Adjust for collapsed sidebar */
@media (max-width: 1024px) {
  .floating-save-button {
    left: calc(60px + 30px); /* Collapsed sidebar width (60px) plus padding */
  }
}

/* Fully responsive for mobile */
@media (max-width: 768px) {
  .floating-save-button {
    left: 20px; /* No sidebar on mobile */
    bottom: 80px; /* Stack above the add button */
    padding: 10px 14px;
  }
}

/* Position and Equipment Inputs - set to 160px for consistency */
.position-input, 
.equipment-input,
.rate-input {
  max-width: 160px !important;
  width: 160px !important;
}

/* Rate controls container */
.rate-controls {
  display: flex;
  justify-content: center;
  width: 160px;
}

/* Preset rate selector */
.preset-rate-selector {
  max-width: 160px;
  width: 160px;
}

/* Mobile responsiveness for position and rate inputs */
@media (max-width: 768px) {
  .position-input, 
  .equipment-input,
  .rate-input,
  .preset-rate-selector,
  .rate-controls {
    max-width: 100% !important;
    width: 100% !important;
  }
}

/* Rate note for equipment weekly rates */
.rate-note {
  font-size: 0.75rem;
  color: #4299e1;
  margin-top: 2px;
  text-align: center;
  font-style: italic;
}

/* Updated styles for the rate-controls to accommodate the note */
.rate-controls {
  display: flex;
  justify-content: center;
  width: 160px;
  margin-bottom: 2px;
}

/* Styling for straight daily rate text */
.straight-daily-rate {
  color: #3182ce;
  font-size: 0.8rem;
  margin-top: 6px;
  font-style: italic;
}

/* Adjust grand total row for equipment items */
.phase-container .grand-total-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 16px;
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px dashed #e2e8f0;
}

.amount-total input {
  font-weight: 600;
}

/* Phase rows styling with preferred color scheme */
.phase-type-row {
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 12px;
  transition: all 0.2s ease;
}

.prep-row {
  background-color: #f0f8ff;
  border: 1px solid #4299e1;
}

.shoot-row {
  background-color: #f2f2f2;
  border: 1px solid #718096;
}

.wrap-row {
  background-color: #f0f8ff;
  border: 1px solid #4299e1;
}

/* Improved position and equipment selectors with blue color scheme */
.position-input, .equipment-input {
  background-color: #ebf8ff;
  border-color: #bee3f8;
  color: #2b6cb0;
  cursor: pointer;
  transition: all 0.2s ease;
}

.position-input:hover, .equipment-input:hover {
  background-color: #bee3f8;
  border-color: #90cdf4;
}

.position-selector-container, .equipment-selector-container {
  background-color: #ebf8ff;
  border: 1px solid #90cdf4;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.position-item, .equipment-item {
  padding: 10px 12px;
  border: 1px solid #bee3f8;
  border-radius: 6px;
  background-color: #ebf8ff;
  color: #2b6cb0;
  margin-bottom: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.position-item:hover, .equipment-item:hover {
  background-color: #bee3f8;
  transform: translateY(-1px);
}

/* Refined styling for phase container */
.phase-container {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  padding: 16px;
  margin-bottom: 20px;
  transition: all 0.2s ease;
}

.phase-container:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.phase-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 16px;
  align-items: center;
}

.phase-type-label {
  font-weight: 600;
  color: #4a5568;
  min-width: 60px;
}

/* Styling for straight daily rate text */
.straight-daily-rate {
  color: #3182ce;
  font-size: 0.8rem;
  margin-top: 6px;
  font-style: italic;
}

/* Adjust grand total row for equipment items */
.phase-container .grand-total-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 16px;
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px dashed #e2e8f0;
}

.amount-total input {
  font-weight: 600;
}

/* Enhanced preset rate selectors */
.preset-rate-selector {
  background-color: #ebf8ff;
  border: 1px solid #bee3f8;
  color: #2b6cb0;
  border-radius: 6px;
  padding: 8px 12px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.preset-rate-selector:hover {
  background-color: #bee3f8;
}

.preset-rate-selector option {
  background-color: white;
  color: #2d3748;
}

/* Improved budget section styling */
.budget-section {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 24px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e2e8f0;
}

.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2d3748;
}

/* Better visibility for totals */
.section-total, .category-total {
  background-color: #f7fafc;
  border-radius: 8px;
  padding: 12px 16px;
  font-weight: 600;
  margin-top: 16px;
  border-left: 4px solid #4299e1;
}

.total-value {
  color: #2b6cb0;
  font-size: 1.1rem;
}

/* Enhanced buttons */
.add-section-button {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background-color: #ebf8ff;
  color: #3182ce;
  border: 1px solid #bee3f8;
  border-radius: 6px;
  padding: 8px 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.add-section-button:hover {
  background-color: #bee3f8;
  transform: translateY(-1px);
}

.remove-button {
  color: #e53e3e;
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.remove-button:hover {
  background-color: #FED7D7;
}

/* Better input focus states */
input:focus, select:focus, .position-input:focus, .preset-rate-selector:focus {
  outline: none;
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.15);
}