.receipt-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.receipt-popup {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  border: 2px solid #3498db; /* Added border */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Added shadow for depth */
}

.receipt-popup h2 {
  margin-top: 0;
  color: #3498db; /* Changed color to match border */
  border-bottom: 1px solid #e0e0e0; /* Added underline to title */
  padding-bottom: 10px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.form-group input[type="file"],
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.button-group {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.button-group button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.button-group button[type="submit"] {
  background-color: #4CAF50;
  color: white;
}

.button-group button[type="submit"]:hover {
  background-color: #45a049;
}

.button-group button[type="button"] {
  background-color: #f44336;
  color: white;
}

.button-group button[type="button"]:hover {
  background-color: #d32f2f;
}
