.reports-container {
  display: flex;
}

.reports-content {
  margin-left: 0px; /* Width of the sidebar */
  margin-right: 0px;
  padding: 20px;
  width: calc(100% - 0px); /* Adjust width to account for sidebar */
}

.page-header {
  background-color: #f0f0f0;
  padding: 20px;
  text-align: center;
}

.page-content {
  padding: 20px;
}

.reports-list {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.report-item {
  border-bottom: 1px solid #e9ecef;
  padding: 15px 0;
}

.report-item:last-child {
  border-bottom: none;
}

.report-item h3 {
  margin-top: 0;
  color: #2c3e50;
}

.report-item p {
  color: #34495e;
  margin-bottom: 10px;
}

.report-item button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.report-item button:hover {
  background-color: #2980b9;
}

.error-message {
  color: #e74c3c;
  font-weight: bold;
}

.date-filter {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  align-items: center;
}

.date-filter input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.date-filter button {
  padding: 8px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.date-filter button:hover {
  background-color: #2980b9;
}

.time-report-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px;
}

.time-report-table th,
.time-report-table td {
  border: 1px solid #e9ecef;
  padding: 12px;
  text-align: left;
}

.time-report-table th {
  background-color: #f8f9fa;
  font-weight: bold;
  color: #2c3e50;
}

.time-report-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.time-report-table tr:hover {
  background-color: #e9ecef;
}

@media (max-width: 768px) {
  .reports-content {
    margin-left: 0;
    width: 100%;
  }

  .date-filter {
    flex-direction: column;
    align-items: stretch;
  }
}

.reports-stack {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.report-tile {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.report-tile h2 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #2c3e50;
}

.report-tile p {
  margin-bottom: 15px;
  color: #7f8c8d;
}

.generate-report-btn {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.generate-report-btn:hover {
  background-color: #2980b9;
}

.date-navigation {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 15px 0;
}

.date-navigation button {
  padding: 8px 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.date-navigation button:hover {
  background-color: #2980b9;
}

.date-navigation input[type="date"] {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.previous-day-report-btn {
  padding: 5px 10px;
  margin: 10px 0;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  display: inline-block;
}

.previous-day-report-btn:hover {
  background-color: #2980b9;
}

.report-header {
  background-color: #f8f9fa;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #e9ecef;
  border-radius: 4px;
}

.report-header p {
  margin: 5px 0;
}

.report-header p strong {
  font-weight: bold;
  margin-right: 5px;
}

.export-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 15px;
  margin-bottom: 25px;
}

.export-button {
  padding: 15px 25px;  /* Increased padding for larger buttons */
  font-size: 18px;     /* Increased font size */
  background-color: #2980b9;  /* Blue color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  text-align: center;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;  /* Added for better visibility */
}

.export-button:hover {
  background-color: #2980b9;  /* Darker blue on hover */
  transform: translateY(-2px);
}

.export-button:active {
  transform: translateY(0);
}

/* Remove specific styles for different export types */
.export-pdf,
.export-excel,
.export-sheets {
  background-color: #2980b9;
}

.export-pdf:hover,
.export-excel:hover,
.export-sheets:hover {
  background-color: #0056b3;
}

/* Remove the icon-related styles */

.vehicle-table-container {
  overflow-x: auto;
}

.vehicle-table {
  width: 100%;
  border-collapse: collapse;
}

.vehicle-table th,
.vehicle-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.vehicle-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.vehicle-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.vehicle-table tr:hover {
  background-color: #f5f5f5;
}

.hot-sheet-table-container {
  overflow-x: auto;
}

.hot-sheet-table {
  width: 100%;
  border-collapse: collapse;
}

.hot-sheet-table th,
.hot-sheet-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.hot-sheet-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.hot-sheet-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.hot-sheet-table tr:hover {
  background-color: #f5f5f5;
}

.page-header p {
  margin: 5px 0;
}

.page-header p strong {
  font-weight: bold;
  margin-right: 5px;
}

.no-entries-message {
  text-align: center;
  padding: 20px;
  color: #666;
  font-style: italic;
}

.column-selector {
  margin: 20px 0;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #dee2e6;
}

.column-selector h3 {
  margin: 0 0 15px 0;
  color: #2c3e50;
  font-size: 16px;
}

.column-options {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.column-option {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.column-option:hover {
  background-color: #e9ecef;
}

.column-option input[type="checkbox"] {
  margin: 0;
  cursor: pointer;
}

.tabs-container {
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  gap: 10px;
  border-bottom: 2px solid #e9ecef;
  padding-bottom: 10px;
}

.tab-button {
  padding: 10px 20px;
  border: none;
  background: none;
  font-size: 16px;
  color: #3498db;  /* Keep text blue */
  cursor: pointer;
  transition: all 0.3s ease;
  border-bottom: 2px solid transparent;
  margin-bottom: -10px;
  border-radius: 4px;  /* Added for rounded corners on hover background */
}

.tab-button:hover {
  background-color: #3498db !important;
  color: white !important;
  border-bottom: 2px solid transparent !important;  /* Remove border on hover */
}

.tab-button.active {
  background-color: transparent !important;
  color: #3498db !important;
  border-bottom: 2px solid #3498db !important;  /* Keep the underline for active state */
}

.tab-content {
  padding: 20px 0;
}

.rental-date-picker {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 120px;
  font-size: 14px;
}

.rental-date-picker:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.vehicle-table td {
  vertical-align: middle;
  padding: 8px;
}

.daily-rate-calculator {
  margin-top: 5px;
}

.days-input {
  width: 60px;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.total-label {
  text-align: right;
  font-weight: bold;
}

.total-amount {
  font-weight: bold;
  color: #3498db;
}

.status-indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 0 auto;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  display: inline-block;
  position: relative;
}

.status-indicator::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.status-indicator.good {
  background: #2ecc71;  /* Green */
  border: 1px solid #27ae60;
}

.status-indicator.warning {
  background: #f1c40f;  /* Yellow */
  border: 1px solid #f39c12;
}

.status-indicator.ending {
  background: #e74c3c;  /* Red */
  border: 1px solid #c0392b;
}

.status-indicator.overdue {
  background: #c0392b;  /* Dark Red */
  border: 1px solid #962d22;
}

.status-indicator.not-set {
  background: #95a5a6;  /* Gray */
  border: 1px solid #7f8c8d;
}

/* Add a tooltip for status indicators */
.status-cell {
  position: relative;
  text-align: center;
}

.status-cell:hover::after {
  content: attr(data-status);
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  padding: 4px 8px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
}

.week-display {
  padding: 8px 16px;
  background-color: #f8f9fa;
  border-radius: 4px;
  font-weight: 500;
  color: #2c3e50;
  min-width: 300px;
  text-align: center;
  display: inline-block;
}
