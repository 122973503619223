.settings-container {
  display: flex;
  min-height: 100vh;
}

.settings-content {
  flex: 1;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.settings-content h1 {
  margin-bottom: 30px;
  color: #2c3e50;
}

.settings-section {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.settings-section h2 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #2c3e50;
  font-size: 1.5em;
}

.setting-group {
  margin-bottom: 20px;
}

.setting-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #2c3e50;
}

.setting-group input[type="text"],
.setting-group input[type="email"],
.setting-group input[type="tel"],
.setting-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.setting-group input[type="checkbox"] {
  margin-right: 8px;
}

.session-duration-select {
  width: 200px;
}

.settings-actions {
  margin-top: 30px;
  text-align: right;
}

.settings-actions button {
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.settings-actions button:hover {
  background-color: #2980b9;
}

.settings-actions button:disabled {
  background-color: #95a5a6;
  cursor: not-allowed;
}

.save-status {
  margin-top: 10px;
  padding: 8px;
  border-radius: 4px;
  font-size: 14px;
}

.save-status.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}

.save-status.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}