.offline-status {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 8px 16px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  z-index: 1000;
  animation: fadeIn 0.3s ease-in-out;
  opacity: 0.9;
}

.offline-dot {
  width: 8px;
  height: 8px;
  background-color: #ff4444;
  border-radius: 50%;
  display: inline-block;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 0.9;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .offline-status {
    top: 10px;
    right: 10px;
    padding: 6px 12px;
    font-size: 12px;
  }
} 