.notifications-container {
  display: flex;
}

.notifications-content {
  flex: 1;
  padding: 20px;
}

.table-container {
  overflow-x: auto;
  margin: 20px 0;
}

.notifications-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.notifications-table th {
  background-color: #f8f9fa;
  padding: 12px;
  text-align: left;
  font-weight: 600;
  color: #2c3e50;
  border-bottom: 2px solid #dee2e6;
}

.notification-row {
  border-bottom: 1px solid #dee2e6;
  transition: background-color 0.3s ease;
}

.notification-row:hover {
  background-color: #f5f5f5;
}

.notification-row td {
  padding: 12px;
  vertical-align: top;
}

/* Color coding for different notification types */
.notification-row.dispatchedTime {
  background-color: #e7f5fe;
}

.notification-row.sentDispatchedTime {
  background-color: #e7f5fe;
}

.notification-row.dispatchedRun {
  background-color: #fff3cd;
}

.notification-row.sentDispatchedRun {
  background-color: #fff3cd;
}

.notification-row.sent {
  background-color: #e8f5e9;
}

.notification-row.received {
  background-color: #e3f2fd;
}

.notification-type {
  font-weight: 500;
  width: 150px;
}

.notification-content {
  min-width: 300px;
}

.notification-content div {
  margin-bottom: 4px;
}

.notification-recipient {
  width: 150px;
}

.notification-date {
  width: 150px;
  color: #666;
  font-size: 0.9em;
}

.refresh-button,
.load-more-button {
  padding: 8px 16px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
}

.refresh-button:hover,
.load-more-button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .notifications-table {
    font-size: 14px;
  }

  .notification-type,
  .notification-recipient,
  .notification-date {
    width: auto;
  }
}

.date-filter {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.date-input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  height: 36px;
  background-color: white;
  cursor: pointer;
  position: relative;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='15' viewBox='0 0 24 24' fill='none' stroke='%23666' stroke-width='2'><rect x='3' y='4' width='18' height='18' rx='2' ry='2'></rect><line x1='16' y1='2' x2='16' y2='6'></line><line x1='8' y1='2' x2='8' y2='6'></line><line x1='3' y1='10' x2='21' y2='10'></line></svg>");
  background-repeat: no-repeat;
  background-position: right 8px center;
  padding-right: 32px;
}

.date-input:hover {
  border-color: #3498db;
}

.date-input:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.date-input::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
  cursor: pointer;
}

.filter-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.filter-button,
.show-all-button {
  padding: 8px 16px;
  height: 36px;
  font-size: 14px;
  min-width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.filter-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.show-all-button {
  background-color: #6c757d;
}

.filter-button:hover:not(:disabled),
.show-all-button:hover {
  opacity: 0.9;
}

@media (max-width: 768px) {
  .date-filter {
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .date-input {
    width: 100%;
  }

  .filter-button,
  .show-all-button,
  .refresh-button {
    width: 100%;
    margin-top: 0.5rem;
  }
}

.filter-controls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.notification-type-filter {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
}

.radio-label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.radio-label input[type="radio"] {
  margin: 0;
  cursor: pointer;
}

@media (max-width: 768px) {
  .filter-controls {
    gap: 0.5rem;
  }

  .notification-type-filter {
    justify-content: space-between;
    gap: 1rem;
  }
}

.notification-actions {
  width: 100px;
  text-align: center;
}

.acknowledge-button {
  padding: 4px 12px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.3s ease;
  z-index: 1;
}

.acknowledge-button:hover:not(:disabled) {
  background-color: #2980b9;
}

.acknowledge-button.acknowledged {
  background-color: #2ecc71;
  cursor: default;
}

.acknowledge-button:disabled {
  opacity: 0.7;
  cursor: default;
}

.notification-row.acknowledged {
  opacity: 0.8;
}

@media (max-width: 768px) {
  .notification-actions {
    width: auto;
  }
  
  .acknowledge-button {
    padding: 4px 8px;
    font-size: 11px;
  }
}

.acknowledgment-status {
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  color: #666;
}

.acknowledgment-status.acknowledged {
  color: #2ecc71;
  font-weight: 500;
}

.error-row {
  background-color: #fff3f3;
}

.error-message {
  color: #d32f2f;
  padding: 10px;
  margin: 10px 0;
  background-color: #ffebee;
  border-radius: 4px;
}

.acknowledge-button.processing {
  opacity: 0.7;
  cursor: wait;
}

.acknowledge-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.expanded-notification {
  background-color: #f8f9fa;
}

.notification-details {
  padding: 15px;
  border-top: 1px solid #dee2e6;
}

.notification-details p {
  margin: 8px 0;
}

.notification-details strong {
  color: #2c3e50;
  margin-right: 8px;
}

.notification-image {
  margin-top: 15px;
  max-width: 300px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notification-image img {
  width: 100%;
  height: auto;
  display: block;
}

.status-select {
  padding: 4px 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  font-size: 12px;
  cursor: pointer;
}

.status-select:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.acknowledge-button {
  padding: 4px 12px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: all 0.3s ease;
}

.acknowledge-button:hover:not(:disabled) {
  background-color: #2980b9;
}

.acknowledge-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.search-container {
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-start;
}

.search-input {
  width: 400px;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.search-input:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.search-input::placeholder {
  color: #999;
}

@media (max-width: 768px) {
  .search-input {
    width: 100%;
  }
}

.filter-button,
.show-all-button,
.refresh-button,
.load-more-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.filter-button:hover,
.show-all-button:hover,
.refresh-button:hover,
.load-more-button:hover {
  background-color: #0056b3;
}

.filter-button:disabled,
.show-all-button:disabled,
.refresh-button:disabled,
.load-more-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.notifications-section {
  margin-bottom: 30px;
}

.notifications-section h2 {
  margin-bottom: 15px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f5f5f5;
  font-weight: bold;
}

tr:hover {
  background-color: #f9f9f9;
}

.no-notifications {
  padding: 20px;
  text-align: center;
  background-color: #f8f9fa;
  border-radius: 8px;
  margin: 10px 0;
}

.no-notifications p {
  color: #6c757d;
  margin: 0;
  font-size: 0.95rem;
}

@media (max-width: 767px) {
  /* Container adjustments */
  .notifications-container {
    max-width: 430px;
    margin: 0 auto;
    padding: 10px;
    overflow-x: hidden;
  }

  .notifications-content {
    width: 100%;
    padding: 10px;
  }

  /* Table adjustments */
  .table-container {
    width: 100%;
    max-width: 410px; /* 430px - 20px padding */
    margin: 10px auto;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  .notifications-table {
    min-width: 100%;
    font-size: 14px;
  }

  /* Adjust column widths */
  .notification-type {
    width: auto;
    min-width: 80px;
  }

  .notification-content {
    min-width: 150px;
  }

  .notification-recipient {
    width: auto;
    min-width: 80px;
  }

  .notification-date {
    width: auto;
    min-width: 80px;
  }

  /* Make sure header text doesn't wrap */
  .notifications-table th {
    white-space: nowrap;
    padding: 8px;
  }

  /* Allow content cells to wrap */
  .notifications-table td {
    white-space: normal;
    padding: 8px;
  }
}