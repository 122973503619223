.sidebar.dropdown {
  position: absolute;
  top: 60px; /* Adjust based on your navbar height */
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  border-radius: 4px;
  overflow-y: auto;
  max-height: calc(100vh - 60px);
  z-index: 1000;
}

/* Sidebar Dropdown Menu Styles */
.sidebar-dropdown {
  position: relative;
}

.sidebar-dropdown .dropdown-title {
  color: white;
  display: flex;
  align-items: center;
  padding: 8px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-weight: 500;
}

.sidebar-dropdown .dropdown-title:hover {
  background-color: #34495e;
}

.sidebar-dropdown .dropdown-menu {
  list-style-type: none;
  padding-left: 20px;
  margin: 0;
  background-color: #263546;
  overflow: hidden;
  transition: max-height 0.3s ease;
}

.sidebar-dropdown .dropdown-menu li a {
  padding: 6px 20px;
  font-size: 0.9em;
  color: #f0f8ff;
}

.sidebar-dropdown .dropdown-menu li a:hover {
  background-color: #1f2c38;
  color: #4299e1;
}

.sidebar-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow-y: auto;
}

.sidebar-menu li {
  padding: 0;
  margin: 0;
}

.sidebar-menu li a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 8px 20px;
  transition: background-color 0.3s ease;
  position: relative;
}

.sidebar-menu li a:hover {
  background-color: #34495e;
}

/* Remove any border or line styles if they exist */
.sidebar-menu li,
.sidebar-menu li:last-child {
  border: none;
}

.notification-count {
  background-color: #e74c3c;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.8em;
  margin-left: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 18px;
  height: 18px;
  z-index: 2;
  position: relative;
}

.sidebar {
  width: 200px;
  height: 100vh;
  background-color: #2c3e50;
  color: white;
  padding: 2px;
  position: fixed;
  top: 0;
  left: 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.sidebar-header {
  display: flex;
  align-items: center;
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.sidebar-logo {
  height: 80px;
  margin-right: 0.5rem;
  border-radius: 50%;
}

.sidebar-title {
  font-size: 1.5em;
  margin: 0;
  color: white;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  flex-grow: 1; 
}

.sidebar ul li {
  padding: 10px 20px;
}

.sidebar ul li a {
  color: white;
  text-decoration: none;
  display: block;
  padding: 10px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.sidebar ul li a:hover {
  background-color: #34495e;
}

.sidebar-footer {
  padding: 2px 6px 6px 6px;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
  box-sizing: border-box;
  background-color: #34495e;
}

.user-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px;
  background-color: white;
  border-radius: 12px;
  margin-bottom: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-info-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 0;
}

.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: -8px;
}

.user-details {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.user-role {
  color: #2c3e50;
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: -8px;
}

.user-role::before {
  content: "Role: ";
  color: #666;
  font-weight: normal;
}

.user-email {
  color: #7f8c8d;
  font-size: 0.8em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.logout-button {
  width: 100%;
  padding: 6px;
  background-color: #e74c3c;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 0.9em;
  font-weight: 500;
}

.logout-button:hover {
  background-color: #c0392b;
}

.sidebar-bottom {
  margin-top: auto;
  border-top: 1px solid #34495e;
  padding-top: 5px; /* Reduced from 10px */
  padding-bottom: 5px; /* Added to reduce space at the bottom */
}

/* Responsive settings */
@media screen and (max-width: 767px) {
  .sidebar {
    display: none;
  }
}

.nav-link-badge {
  background-color: #e74c3c;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  position: absolute;
  top: -5px;
  right: -5px;
  min-width: 18px;
  text-align: center;
}

.notification-badge {
  background-color: #ff4444;
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

/* Add this specific style */
.sidebar-menu li a[href="/notifications"] {
  padding: 4px 10px;
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
}

/* Update the notification count style */
.sidebar-menu li a[href="/notifications"] .notification-count {
  padding: 1px 5px;
  font-size: 0.7em;
  min-width: auto;
  height: auto;
  margin-left: 2px;
}