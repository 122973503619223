.profile-container {
  display: flex;
}

.profile-content {
  margin-left: 0px; /* Width of the sidebar */
  margin-right: 0px;
  padding: 20px;
  width: calc(100% - 0px); /* Adjust width to account for sidebar */
}

.profile-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
  border: 4px solid #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.profile-card h2 {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 10px;
}

.profile-card p {
  margin: 10px 0;
  color: #34495e;
  font-size: 1.1rem;
}

.profile-card strong {
  font-weight: 600;
  color: #2c3e50;
}

.edit-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.edit-button:hover {
  background-color: #2980b9;
}

.save-button {
  background-color: #2ecc71;
  color: white;
  margin-right: 10px;
}

.save-button:hover {
  background-color: #27ae60;
}

.cancel-button {
  background-color: #e74c3c;
  color: white;
}

.cancel-button:hover {
  background-color: #c0392b;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  color: #2c3e50;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.button-group {
  margin-top: 20px;
}

.profile-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.profile-header h2 {
  margin: 0;
}

.production-history-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 20px auto 0;
}

.production-history-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.production-history-table th,
.production-history-table td {
  border: 1px solid #e9ecef;
  padding: 12px;
  text-align: left;
}

.production-history-table th {
  background-color: #f8f9fa;
  font-weight: bold;
  color: #2c3e50;
}

.production-history-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.production-history-table tr:hover {
  background-color: #e9ecef;
}

.show-logo {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 4px;
}

.production-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.production-item {
  display: flex;
  align-items: flex-start;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 15px;
}

.production-logo {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  margin-right: 15px;
}

.production-details {
  flex-grow: 1;
}

.production-details h3 {
  margin-top: 0;
  margin-bottom: 10px;
  color: #2c3e50;
}

.production-details p {
  margin: 5px 0;
  color: #34495e;
}

.emergency-contact-info,
.vehicle-info {
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  padding: 15px;
  margin-top: 20px;
}

.emergency-contact-info h3,
.vehicle-info h3 {
  color: #2c3e50;
  margin-bottom: 10px;
  font-size: 1.3rem;
}

.emergency-contact-info p,
.vehicle-info p {
  margin: 5px 0;
  font-size: 1rem;
}

.payrate-section {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.payrate-section label {
  font-weight: bold;
}

.payrate-section input[type="number"] {
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  width: 100px;
}

@media (max-width: 768px) {
  .profile-content {
    margin-left: 0;
    width: 100%;
  }

  .profile-card,
  .production-history-card {
    padding: 20px;
  }

  .profile-picture {
    width: 100px;
    height: 100px;
  }

  .profile-card h2 {
    font-size: 1.5rem;
  }

  .profile-card p,
  .emergency-contact-info p,
  .vehicle-info p {
    font-size: 0.9rem;
  }
}

