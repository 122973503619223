.page-header {
  margin-bottom: 2rem;
  text-align: center;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: flex-start;
  position: relative;
}

.page-header h1 {
  margin: 0;
  color: #2c3e50;
  font-size: 1.8rem;
  font-weight: 600;
}

.page-header .production-name {
  margin: 0.5rem 0;
  color: #2196F3;
  font-weight: 500;
  font-size: 1.2rem;
}

.page-header .department-name {
  margin: 0.25rem 0 0;
  color: #6c757d;
  font-size: 0.95rem;
}

.header-left {
  position: absolute;
  left: 1rem;
  top: 1rem;
  text-align: left;
}

.header-center {
  flex: 1;
  margin: 0 auto;
}

.trial-status {
  text-align: left;
  color: #3498db;
}

.trial-status .status {
  font-weight: 600;
  font-size: 1.1rem;
}

.trial-status .trial-days {
  font-size: 0.9rem;
  opacity: 0.9;
}

.dashboard-container {
  display: flex;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  margin-right: 0;
}

.dashboard-content {
  width: 100%;
  padding: 2rem;
  background: #f8f9fa;
  min-height: 100vh;
}

.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 1rem;
  margin-bottom: 2rem;
}

.dashboard-card {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.dashboard-card.large {
  grid-column: span 2;
}

.dashboard-card h3 {
  color: #37474F;
  margin: 0 0 1rem 0;
  font-size: 1.1rem;
  text-align: center;
}

.dashboard-number {
  font-size: 2.5rem;
  font-weight: bold;
  color: #2196F3;
  margin: 0;
  text-align: center;
}

.dashboard-bottom {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.dashboard-recent, .dashboard-actions {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex: 1 1 300px;
}

.dashboard-recent h3, .dashboard-actions h3 {
  margin-top: 0;
  color: #34495e;
  border-bottom: 1px solid #ecf0f1;
  padding-bottom: 10px;
}

.dashboard-recent ul {
  list-style-type: none;
  padding: 0;
}

.dashboard-recent li {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 4px;
}

.dashboard-action-btn {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dashboard-action-btn:hover {
  background-color: #2980b9;
}

.production-counts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.production-counts > div {
  flex: 1 1 auto;
  text-align: center;
  margin: 10px;
}

.production-counts p {
  margin: 0;
  font-weight: bold;
}

.production-counts .dashboard-number {
  font-size: 1.5em;
  color: #3498db;
}

.quick-links-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 1rem;
}

.quick-link-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  text-decoration: none;
  color: #37474F;
  background: #f8f9fa;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.quick-link-card:hover {
  background: #e9ecef;
  transform: translateY(-2px);
}

.quick-link-card i {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #2196F3;
}

.icon-group {
  position: relative;
  margin-bottom: 0.5rem;
}

.icon-group i:first-child {
  margin-right: -0.5rem;
}

.icon-group i:last-child {
  font-size: 1rem;
  position: absolute;
  bottom: -0.25rem;
  right: -0.25rem;
}

.recent-runs {
  max-height: 400px;
  overflow-y: auto;
  padding-right: 10px;
}

.run-item {
  background: white;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.run-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.run-title {
  font-weight: bold;
  color: #2c3e50;
}

.run-date {
  color: #666;
  font-size: 0.9em;
}

.run-assigned {
  color: #34495e;
  font-size: 0.9em;
  margin: 4px 0;
}

.run-description {
  color: #666;
  font-size: 0.9em;
  margin: 8px 0;
}

.run-status {
  color: #3498db;
  font-size: 0.9em;
  font-weight: 500;
}

.dashboard-crew-list {
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.crew-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Show 4 crew members per row */
  gap: 20px;
  margin-top: 15px;
}

.crew-item {
  display: flex;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
  position: relative;
}

.status-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 1rem;
  flex-shrink: 0;
  margin-top: 22px;
}

.member-info {
  flex: 1;
  padding-left: 20px;
}

.member-info h4 {
  margin: 0;
  color: #2c3e50;
  font-size: 1rem;
}

.member-info p {
  margin: 0.25rem 0 0;
  color: #6c757d;
  font-size: 0.9rem;
}

/* Override any hover states */
.crew-item:hover .status-indicator {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
}

.vehicle-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.vehicle-item {
  background: #ffffff;
  border-radius: 8px;
  padding: 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.vehicle-item p {
  margin: 0.5rem 0;
}

.vehicle-item strong {
  color: #2c3e50;
}

.details-section {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 1.5rem;
  margin: 1rem 0;
}

.details-section h3 {
  margin-top: 0;
  color: #2c3e50;
  font-size: 1.2rem;
}

.stat-number {
  font-size: 2rem;
  font-weight: bold;
  margin: 0;
  color: #2c3e50;
}

.stat-label {
  margin: 0;
  color: #6c757d;
}

.card-content {
  text-align: center;
  padding: 1rem;
}

@media screen and (max-width: 768px) {
  .dashboard-card.large {
    flex-direction: column;
    align-items: stretch;
  }

  .production-counts {
    flex-direction: column;
  }

  .production-counts > div {
    margin: 5px 0;
  }
}

@media screen and (max-width: 480px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
  }

  .dashboard-bottom {
    flex-direction: column;
  }
}

@media screen and (max-width: 1200px) {
  .quick-links-grid {
    grid-template-columns: repeat(auto-fit, minmax(350px, 350px));
    justify-content: center;
  }

  .dashboard-action-btn {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .quick-links-grid {
    grid-template-columns: 1fr;
  }

  .quick-link-card,
  .dashboard-action-btn {
    width: 100%;
  }
}

.stats-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.stat-card {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  text-align: center;
}

.stat-card h3 {
  margin: 0 0 1rem;
  color: #37474F;
  font-size: 1.1rem;
  font-weight: 500;
}

.stat-number {
  margin: 0;
  font-size: 2.5rem;
  font-weight: bold;
  color: #2196F3;
}

.quick-actions-section {
  margin-bottom: 2rem;
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.quick-actions-section h3 {
  color: #37474F;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  text-align: center;
}

.quick-actions-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1rem;
}

.action-card {
  background: white;
  border-radius: 8px;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: #37474F;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.action-card:hover {
  transform: translateY(-2px);
  background: #f5f5f5;
}

.action-card i {
  font-size: 1.5rem;
  color: #2196F3;
  margin-bottom: 0.75rem;
}

.action-card span {
  font-size: 0.9rem;
  text-align: center;
}

.action-icon {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.action-icon i:first-child {
  margin-right: 4px;
}

.action-icon i:last-child {
  font-size: 1.25rem;
}

.recent-runs-section {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.recent-runs-section h3 {
  margin: 0 0 1.5rem;
  color: #37474F;
  font-size: 1.1rem;
}

.no-runs {
  text-align: center;
  color: #6c757d;
  margin: 0;
}

.no-data {
  text-align: center;
  color: #64748b;
  padding: 1rem;
  background: #f8fafc;
  border-radius: 8px;
  margin: 0;
  font-size: 0.9rem;
}

@media (max-width: 1200px) {
  .quick-actions-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 768px) {
  .stats-grid {
    grid-template-columns: 1fr;
  }
  
  .quick-actions-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .quick-actions-grid {
    grid-template-columns: 1fr;
  }
}

.crew-members-section {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-top: 2rem;
}

.crew-members-section h3 {
  margin: 0 0 1.5rem;
  color: #37474F;
  font-size: 1.1rem;
}

.crew-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.crew-item {
  display: flex;
  align-items: flex-start;
  padding: 1.25rem;
  background: #f8f9fa;
  border-radius: 8px;
  position: relative;
}

.status-indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 1.5rem;
  margin-top: 0.5rem;
}

.member-info {
  flex: 1;
}

.member-info h4 {
  margin: 0;
  color: #2c3e50;
  font-size: 1rem;
  font-weight: 500;
}

.member-info p {
  margin: 0.35rem 0 0;
  color: #6c757d;
  font-size: 0.9rem;
}

.supervisors-section {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-top: 2rem;
}

.supervisors-section h3 {
  margin: 0 0 1.5rem;
  color: #37474F;
  font-size: 1.1rem;
}

.supervisors-section .crew-item {
  background: #f1f5f9;
  border: 1px solid #e2e8f0;
}

.supervisors-section .status-indicator.good {
  background-color: #3b82f6;
}

.state-selector {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.state-selector label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.state-select {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  cursor: pointer;
  outline: none;
  transition: border-color 0.2s ease;
}

.state-select:hover {
  border-color: #999;
}

.state-select:focus {
  border-color: #0066cc;
  box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.1);
}

.state-select option {
  padding: 8px;
}

/* Supervisor Dashboard Sections */
.supervisor-dashboard .stats-section,
.supervisor-dashboard .run-requests-section,
.supervisor-dashboard .recent-runs-section,
.supervisor-dashboard .quick-actions-section,
.supervisor-dashboard .crew-members-section,
.supervisor-dashboard .supervisors-section {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  margin-bottom: 2rem;
}

.supervisor-dashboard h3 {
  color: #37474F;
  margin: 0 0 1.5rem 0;
  font-size: 1.2rem;
  font-weight: 600;
}

.run-requests-section .card,
.recent-runs-section .card {
  background: #f8f9fa;
  border-radius: 6px;
  padding: 1.5rem;
}

/* Run Requests specific styling */
.run-requests-section {
  margin-bottom: 2rem;
}

.recent-runs-section {
  margin-bottom: 2rem;
}

/* Run item styling */
.run-item {
  background: white;
  padding: 1rem;
  border-radius: 6px;
  margin-bottom: 1rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
}

.run-item:last-child {
  margin-bottom: 0;
}

.subscription-status-section {
  background: white;
  padding: 1.5rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  margin-bottom: 2rem;
}

.subscription-status-section .subscription-manager.compact {
  padding: 0;
  box-shadow: none;
}