.page-container {
  display: flex;
  height: 100vh;
}

.page-content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}

.page-header {
  margin-bottom: 20px;
}

.page-header h1 {
  font-size: 24px;
  margin-bottom: 5px;
}

.home-main {
  padding: 20px;
}

.home-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.card {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.card h3 {
  font-size: 18px;
  margin-bottom: 15px;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.card ul {
  list-style-type: none;
  padding: 0;
}

.card li {
  margin-bottom: 10px;
}

.card a {
  text-decoration: none;
  color: #007bff;
}

.card a:hover {
  text-decoration: underline;
}

.bulletin-board li {
  margin-bottom: 15px;
}

.bulletin-board h4 {
  font-size: 16px;
  margin-bottom: 5px;
}

.bulletin-board small {
  display: block;
  color: #666;
  font-size: 12px;
}

@media (max-width: 768px) {
  .home-grid {
    grid-template-columns: 1fr;
  }
}

.weather {
  text-align: center;
}

.weather p {
  margin: 5px 0;
}

.calendar {
  text-align: center;
}

.calendar-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.current-date {
  font-size: 1.2em;
  font-weight: bold;
  color: #333;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  flex-grow: 1;
  margin: 0 10px;
}

.calendar-nav-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.calendar-nav-button:hover {
  background-color: #0056b3;
}

.calendar-nav-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
}

.calendar-info {
  text-align: left;
  padding-top: 10px;
  border-top: 1px solid #e0e0e0;
}

.calendar-info p {
  margin-bottom: 10px;
}

.calendar-info ul {
  list-style-type: none;
  padding-left: 0;
}

.calendar-info li {
  margin-bottom: 5px;
}

/* Remove these styles as they're no longer needed */
.calendar-grid,
.calendar-day,
.calendar-date {
  display: none;
}

.shooting-days {
  text-align: center;
}

.shooting-days-info {
  font-size: 1.5em;
  font-weight: bold;
  margin: 10px 0;
}

.shooting-days-remaining {
  font-size: 1.2em;
  color: #666;
}

.locations {
  display: flex;
  flex-direction: column;
}

.location-section {
  margin-bottom: 15px;
}

.location-section h4 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
}

.location-section ul {
  list-style-type: none;
  padding-left: 0;
}

.location-section li {
  margin-bottom: 5px;
  padding: 5px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.quick-links {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 600px; /* Adjust this value as needed */
  margin: 0 auto;
}

.quick-links ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
}

.quick-links li {
  flex: 0 0 calc(33.333% - 10px); /* Adjust for desired number of columns */
  max-width: calc(33.333% - 10px);
}

.quick-link-btn {
  display: block;
  width: 100%;
  padding: 10px 15px;
  font-size: 1em;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  text-decoration: none;
}

.quick-link-btn:hover {
  background-color: #2980b9;
}

@media (max-width: 600px) {
  .quick-links li {
    flex: 0 0 calc(50% - 10px);
    max-width: calc(50% - 10px);
  }
}

@media (max-width: 400px) {
  .quick-links li {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/* Specific styles for Times, Messages, and Notifications links */
.quick-link-btn[href="/times"],
.quick-link-btn[href="/messages"],
.quick-link-btn[href="/notifications"] {
  color: white;
}

.quick-link-btn:active {
  background-color: #3e8e41; /* Even darker green when clicked */
}

/* Remove specific colors for different buttons */
.quick-link-btn.times,
.quick-link-btn.messages,
.quick-link-btn.notifications,
.quick-link-btn.add-vehicle {
  background-color: #4CAF50;
}

.quick-link-btn.times:hover,
.quick-link-btn.messages:hover,
.quick-link-btn.notifications:hover,
.quick-link-btn.add-vehicle:hover {
  background-color: #45a049;
}

.status-selector {
  margin: 1rem 0;
}

.status-dropdown {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
  cursor: pointer;
  min-width: 200px;
}

.status-dropdown:hover {
  border-color: #3498db;
}

.status-dropdown:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

.status-dropdown option {
  padding: 8px;
}

.current-production-tile,
.quick-link-tile,
.status-tile {
  background-color: white !important;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.current-production-tile:hover,
.quick-link-tile:hover,
.status-tile:hover {
  background-color: white !important;
}

.current-production-tile h2,
.quick-link-tile h2,
.status-tile h2 {
  color: #2c3e50;
}

.current-production-tile p,
.quick-link-tile p,
.status-tile p {
  color: #666;
}

.production-info {
  background-color: white !important;
  border-left: none !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px;
}

.production-info:hover {
  border-left: none !important;
}

.card.production-info {
  border: none !important;
}